import React from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import { ChannelList } from 'scenes/Channels/ChannelList/ChannelList'
import { SalesList } from 'scenes/Sales/SalesList/SalesList'
import { SaleShow } from 'scenes/Sales/SaleShow/SaleShow'
import { SaleCreate } from 'scenes/Sales/SaleCreate/SaleCreate'
import { Login } from 'scenes/Login/Login'
import { ProductList } from 'scenes/Products/ProductList/ProductList'
import { ProductShow } from 'scenes/Products/ProductShow/ProductShow'
import { ParcelsList } from 'scenes/Parcels/ParcelsList/ParcelsList'
import { ParcelShow } from 'scenes/Parcels/ParcelShow/ParcelShow'
import { SaleInvoicesList } from 'scenes/SaleInvoices/SaleInvoicesList/SaleInvoicesList'
import { SaleInvoiceCreate } from 'scenes/SaleInvoices/SaleInvoiceCreate/SaleInvoiceCreate'
import { CustomerList } from 'scenes/Customers/CustomerList/CustomerList'
import { ProductCreate } from 'scenes/Products/ProductCreate/ProductCreate'
import { ManufacturerList } from 'scenes/Manufacturers/ManufacturerList/ManufacturerList'
import { StockLevelList } from 'scenes/StockLevels/StockLevelList/StockLevelList'
import { ReceiveStock } from 'scenes/StockLevels/ReceiveStock/ReceiveStock'
import { WarehouseList } from 'scenes/Warehouses/WarehouseList/WarehouseList'
import { PurchaseOrderList } from 'scenes/PurchaseOrders/PurchaseOrderList/PurchaseOrderList'
import { PurchaseOrderCreate } from 'scenes/PurchaseOrders/PurchaseOrderCreate/PurchaseOrderCreate'
import { PurchaseOrderShow } from 'scenes/PurchaseOrders/PurchaseOrderShow/PurchaseOrderShow'
import { JobsList } from 'scenes/Jobs/JobsList/JobsList'
import { ExportInvoiceCreate } from 'scenes/Jobs/ExportInvoiceCreate/ExportInvoiceCreate'
import { GenerateWarehouseStickers } from 'scenes/Jobs/GenerateWarehouseStickers/GenerateWarehouseStickers'
import { ErrorPage } from 'scenes/ErrorPage/ErrorPage'
import { history } from './history'
import { SaleInvoiceShow } from 'scenes/SaleInvoices/SaleInvoiceShow/SaleInvoiceShow'
import { StockReservationList } from 'scenes/StockReservations/StockReservationList/StockReservationList'
import { Replenishments } from 'scenes/StockReservations/Replenishments/Replenishments'
import { TransportsList } from 'scenes/Transports/TransportsList/TransportsList'
import { TransportShow } from 'scenes/Transports/TransportShow/TransportShow'

export class AppRouter extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={SalesList} />
          <PrivateRoute exact path="/channels" component={ChannelList} />
          <PrivateRoute exact path="/sales" component={SalesList} />
          <PrivateRoute exact path="/sales/create" component={SaleCreate} />
          <PrivateRoute exact path="/sales/:id" component={SaleShow} />
          <PrivateRoute exact path="/parcels" component={ParcelsList} />
          <PrivateRoute
            exact
            path="/parcels/create/:saleIds"
            component={SaleShow}
          />
          <PrivateRoute exact path="/parcels/:id" component={ParcelShow} />
          <PrivateRoute
            exact
            path="/sale-invoices"
            component={SaleInvoicesList}
          />
          <PrivateRoute
            exact
            path="/sale-invoices/create"
            component={SaleInvoiceCreate}
          />
          <PrivateRoute
            exact
            path="/sale-invoices/:id"
            component={SaleInvoiceShow}
          />
          <PrivateRoute
            exact
            path="/stock-reservations"
            component={StockReservationList}
          />
          <PrivateRoute
            exact
            path="/stock-reservations/replenishments"
            component={Replenishments}
          />
          <PrivateRoute exact path="/products" component={ProductList} />
          <PrivateRoute
            exact
            path="/products/create"
            component={ProductCreate}
          />
          <PrivateRoute exact path="/products/:sku" component={ProductShow} />
          <PrivateRoute exact path="/customers" component={CustomerList} />
          <PrivateRoute
            exact
            path="/manufacturers"
            component={ManufacturerList}
          />
          <PrivateRoute exact path="/stock" component={StockLevelList} />
          <PrivateRoute exact path="/stock/receive" component={ReceiveStock} />
          <PrivateRoute exact path="/warehouses" component={WarehouseList} />
          <PrivateRoute exact path="/purchase-orders" component={PurchaseOrderList} />
          <PrivateRoute exact path="/purchase-orders/create" component={PurchaseOrderCreate} />
          <PrivateRoute exact path="/purchase-orders/:id" component={PurchaseOrderShow} />
          <PrivateRoute exact path="/transports" component={TransportsList} />
          <PrivateRoute exact path="/transports/:id" component={TransportShow} />
          <PrivateRoute exact path="/jobs" component={JobsList} />
          <PrivateRoute
            exact
            path="/jobs/export-invoice"
            component={ExportInvoiceCreate}
          />
          <PrivateRoute
            exact
            path="/jobs/warehouse-stickers"
            component={GenerateWarehouseStickers}
          />
          <PrivateRoute exact path="/error" component={ErrorPage} />
          <Route exact path="/login" component={Login} />
          <Redirect
            to={{
              pathname: '/error',
              state: {
                status: 404,
                statusText: 'Nie znaleziono strony',
              },
            }}
          />
        </Switch>
      </Router>
    )
  }
}
