import React from 'react'
import { Paper } from 'components'
import { RequestButton } from 'components/RequestButton/RequestButton'

export const MarkAsSent = ({ parcel, refresh, disabled }) => (
  <Paper title="Oznacz jako wysłaną">
    <RequestButton
      onSuccess={refresh}
      url={`/parcels/${parcel.id}/mark-as-sent`}
      method="post"
      type="danger"
      content="Potwierdzam że paczka została nadana do klienta"
      disabled={disabled}
      title="Czy na pewno chcesz oznaczyć to paczkę jako wysłaną?"
    />
  </Paper>
)
