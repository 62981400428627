import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col, Button, Table } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { formatDateTime, formatCurrency } from 'utils/formatters'
import { RequestButton } from 'components'

const columns = [
  {
    title: 'Numer',
    dataIndex: 'number',
    key: 'number',
    render: (number, record) => (
      <Link to={`/transports/${record.id}`}>{number.toString().padStart(4, '0')}</Link>
    ),
  },
  {
    title: 'Kierowca',
    dataIndex: 'driver',
    key: 'driver',
  },
  {
    title: 'Agencja',
    dataIndex: 'customsAgency',
    key: 'customsAgency',
  },
  {
    title: 'Koszt',
    dataIndex: 'cost',
    key: 'cost',
    render: cost => formatCurrency(cost, 'PLN'),
  },
  {
    title: 'Samochód',
    dataIndex: 'vehicle',
    key: 'vehicle',
  },
  {
    title: 'Faktura',
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    render: invoiceNumber => invoiceNumber || '-',
  },
  {
    title: 'Odjazd',
    render: record => formatDateTime(record.departuredAt),
  },
  {
    title: 'Aktualizowano',
    render: record => formatDateTime(record.arrivedAt),
  },
]

const TransportsListUnwrapped = ({ history }) => (
  <MainLayout header="Transporty" minWidth>
    <Row style={{ marginBottom: '3rem' }}>
      <Col md={24} style={{ textAlign: 'right' }}>
        <Button.Group>
          <RequestButton
            v2
            url='/transports'
            onSuccess={(id) => {
              history.push(`/transports/${id}`)
            }}
            method="post"
            type="primary"
            content={<>
              <PlusOutlined />{' '}
              Dodaj
            </>}
          />
        </Button.Group>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <ResourceProvider
          v2
          url="/transports"
          render={({ data, paginationParams, loading, onChange }) => (
            <Table
              columns={columns}
              dataSource={data}
              rowKey="id"
              pagination={{
                ...paginationParams,
                showSizeChanger: true,
              }}
              loading={loading}
              onChange={onChange}
            />
          )}
        />
      </Col>
    </Row>
  </MainLayout>
)

export const TransportsList = withRouter(TransportsListUnwrapped)
