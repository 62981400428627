import React from 'react'
import PropTypes from 'prop-types'
import * as api from 'api'
import { Popover } from 'antd'
import { Table } from 'components'

const getContent = stockLevels => (
  <Table>
    <Table.Body>
      {stockLevels.map((stockLevel, idx) => (
        <Table.Tr key={idx}>
          <Table.Td>{stockLevel.zone.warehouse.name}</Table.Td>
          <Table.Td>{stockLevel.zone.name}</Table.Td>
          <Table.Td> {stockLevel.quantity}</Table.Td>
        </Table.Tr>
      ))}
    </Table.Body>
  </Table>
)

export class SkuStockLevel extends React.Component {
  state = {
    sum: null,
    stockLevels: [],
    loading: true,
  }

  componentDidMount = async () => {
    const response = await api.fetchStockLevels(this.props.sku)
    if (response && response.data) {
      // Filter because backend is a left wildcard search
      const stockLevels = response.data.data.filter(item => item.sku === this.props.sku)
      this.setState({
        stockLevels,
        sum: stockLevels.reduce((sum, item) => sum + item.quantity, 0),
        loading: false,
      })
    } else {
      this.setState({
        loading: false,
        sum: -9999,
        stockLevels: [],
      })
    }
  }

  renderWidget = () => {
    const { sum, stockLevels } = this.state
    return (
      <Popover content={getContent(stockLevels)}>
        <strong style={{ cursor: 'pointer' }}>{sum}</strong>
      </Popover>
    )
  }

  render() {
    const { sum, stockLevels, loading } = this.state
    return this.props.render({
      loading,
      sum,
      stockLevels,
      renderWidget: this.renderWidget,
    })
  }
}

SkuStockLevel.propTypes = {
  sku: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
}
