import React from 'react'
import { request } from 'api/request'
import { Button, Modal, Row, Col, Typography } from 'antd'
import { SLabel, Paper, AsyncSelect } from 'components'
import { withRouter } from 'react-router-dom'

class DeleteParcelUnwrapped extends React.Component {
  state = {
    warehouseId: 2,
    zoneId: 2,
    showModal: false,
  }

  onChange = key => value => {
    this.setState({ [key]: value })
    if (key === 'warehouseId') {
      this.setState({ zoneId: 2 })
    }
  }

  isDisabled = () => !this.state.warehouseId || !this.state.zoneId

  toggleModal = () => {
    this.setState(state => ({
      showModal: !state.showModal,
    }))
  }

  handleSubmit = async () => {
    const { zoneId } = this.state
    const { parcel } = this.props
    if (!zoneId) {
      return
    }
    await request.delete(`/parcels/${parcel.id}/${zoneId}`)
    this.props.history.push('/parcels')
  }

  render() {
    const { showModal, warehouseId, zoneId } = this.state
    return (
      <Paper title="Usuń paczkę">
        <Button
          type="danger"
          onClick={this.toggleModal}
          id="parcel-destroy_submit"
        >
          Usuń paczkę
        </Button>
        <Modal
          title="Wyładuj towar"
          visible={showModal}
          onOk={this.handleSubmit}
          onCancel={this.toggleModal}
        >
          <Row gutter={16}>
            <Col sm={24}>
              <Typography.Text>Gdzie wypakujesz towar?</Typography.Text>
            </Col>
            <Col md={6} sm={12}>
              <SLabel>Magazyn</SLabel>
              <AsyncSelect
                id="parcel-destroy_warehouseId"
                value={warehouseId}
                url="/warehouses/"
                fullList
                responseNormalizer={item => ({
                  text: item.name,
                  value: item.id,
                })}
                onChange={this.onChange('warehouseId')}
              />
            </Col>
            <Col md={6} sm={12}>
              <SLabel>Strefa</SLabel>
              <AsyncSelect
                value={zoneId}
                id="parcel-destroy_zoneId"
                url={`/warehouses/${warehouseId}/zones`}
                fullList
                disabled={!warehouseId}
                responseNormalizer={item => ({
                  text: item.name,
                  value: item.id,
                })}
                onChange={this.onChange('zoneId')}
              />
            </Col>
          </Row>
        </Modal>
      </Paper>
    )
  }
}

export const DeleteParcel = withRouter(DeleteParcelUnwrapped)
