import React from 'react'
import { SaleItemsTable } from '../../SaleItemsTable/SaleItemsTable'

export const CreateCartForm = ({ sale, handleSubmit, posting }) => (
  <SaleItemsTable
    sale={sale}
    items={[]}
    handleSubmit={handleSubmit}
    posting={posting}
  />
)
