import React from 'react'
import { Paper, Right } from 'components'
import { Typography } from 'antd'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { Spacing } from 'components/Spacing/Spacing'

export const CancelOrder = ({ sale, refresh }) => (
  <Paper title="Anuluj zamówienie">
    <Spacing mb="1rem">
      <Typography.Text type="warning">
        Anuluj zamówienie w przypadku rezygnacji klienta lub braku możliwości
        zrealizowania zamówienia. Pamiętaj aby dokonać zwrotu płatności po
        anulowaniu zamówienia.
      </Typography.Text>
    </Spacing>
    <Right>
      <RequestButton
        onSuccess={refresh}
        url={`/orders/${sale.id}/cancel`}
        title="Czy na pewno chcesz anulować to zamówienie? Tej akcji nie da się cofnąć."
        method="post"
        type="danger"
        content="Anuluj zamówienie"
      />
    </Right>
  </Paper>
)
