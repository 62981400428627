const percentToFloat = value => {
  return parseFloat((value / 100).toFixed(2))
}

const getVatMult = vatRate => {
  return percentToFloat(vatRate)
}

export const getVat = (netto, vatRate) => {
  return Math.round(netto * getVatMult(vatRate))
}

export const getBrutto = (netto, vatRate) => {
  return netto + getVat(netto, vatRate)
}

const sum = (array, lensFn) =>
  array.reduce((acc, curr) => acc + lensFn(curr), 0)

export const getTotalNetto = (netto, quantity) => netto * quantity

export const getTotalBrutto = (netto, vatRate, quantity) =>
  getBrutto(netto, vatRate) * quantity

export const getTotalVat = (netto, vatRate, quantity) =>
  getVat(netto, vatRate) * quantity

const isValidList = items =>
  items.every(
    item =>
      typeof item.netto === 'number' &&
      typeof item.vatRate === 'number' &&
      typeof item.quantity === 'number' &&
      !!item.sku
  )

export const toPriceList = items => {
  if (!isValidList(items)) {
    return {
      items: items,
      total: {
        netto: null,
        brutto: null,
        vat: null,
      },
    }
  }
  const priceItems = items.map(item => ({
    ...item,
    brutto: getBrutto(item.netto, item.vatRate),
    vat: getVat(item.netto, item.vatRate),
    totalNetto: getTotalNetto(item.netto, item.quantity),
    totalBrutto: getTotalBrutto(item.netto, item.vatRate, item.quantity),
    totalVat: getTotalVat(item.netto, item.vatRate, item.quantity),
  }))
  return {
    items: priceItems,
    total: {
      netto: sum(priceItems, item => item.totalNetto),
      vat: sum(priceItems, item => item.totalVat),
      brutto: sum(priceItems, item => item.totalBrutto),
    },
  }
}

export const displayPrice = int =>
  (int / 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
