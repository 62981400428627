import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { AddManufacturerForm } from './AddManufacturerForm/AddManufacturerForm'
import { Row, Col, Table } from 'antd'

const getColumns = refresh => [
  {
    title: 'Kod',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
  },
  {
    title: 'SKU Prefix',
    dataIndex: 'skuPrefix',
  },
  {
    title: '',
    key: 'delete',
    render: (_text, record) => (
      <RequestButton
        content="Usuń"
        method="delete"
        url={`/manufacturers/${record.code}`}
        type="link"
        onSuccessMessage="Pomyślnie usunięto!"
        onSuccess={() => {
          refresh()
        }}
      />
    ),
  },
]

const normalizeManufacturers = (manufacturers = []) =>
  manufacturers.map(manufacturer => ({
    key: manufacturer.code,
    code: manufacturer.code,
    name: manufacturer.name,
    logoUri: manufacturer.logoUri,
    skuPrefix: manufacturer.skuPrefix,
  }))

export class ManufacturerList extends React.Component {
  state = {
    manufacturers: null,
    loading: false,
  }

  render() {
    return (
      <MainLayout header="Producenci" minWidth>
        <ResourceProvider
          url="/manufacturers"
          responseNormalizer={normalizeManufacturers}
          encodePageNumber
          pagination
          render={({ data, loading, refresh, paginationParams, onChange }) => (
            <>
              <Row style={{ paddingBottom: '2rem' }}>
                <Col span={24}>
                  <AddManufacturerForm onSuccess={refresh} />
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Table
                    loading={loading}
                    columns={getColumns(refresh)}
                    dataSource={data || []}
                    pagination={{
                      ...paginationParams,
                      showSizeChanger: true,
                    }}
                    onChange={onChange}
                  />
                </Col>
              </Row>
            </>
          )}
        />
      </MainLayout>
    )
  }
}
