import styled from 'styled-components'

export const SHeader = styled.h4`
  font-size: 1.6rem;
  display: inline-block;
  margin: 0;
  margin-left: 1rem;
  margin-bottom: 2px;
  flex-grow: 1;
`
