import React from 'react'
import moment from 'moment'
import { compose } from 'recompose'
import { Col, Button, Input, Row, DatePicker, InputNumber } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { TextField, Paper } from 'components'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { formatCurrency, formatDateTime } from 'utils/formatters'

export class PurchaseOrderDeliveryUnwrapped extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(
      state => ({ editMode: !state.editMode }),
      () => {
        if (this.state.editMode) {
          this.props.form.setFieldsValue({
            deliveryExpectedAt: this.props.data.deliveryExpectedAt
              ? moment(this.props.data.deliveryExpectedAt)
              : null,
            trackingId: this.props.data.trackingId || '',
            deliveryCost: this.props.data.deliveryCost || '',
          })
        }
      }
    )
  }

  onSuccess = () => {
    this.toggleMode()
    this.props.refresh()
  }

  render() {
    const { editMode } = this.state
    const { form, data } = this.props
    return (
      <Paper
        title="Dostawa"
        extra={
          <Button
            icon={<EditOutlined />}
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        {editMode && (
          <FormProvider
            v2
            url={`/purchase-orders/${data.id}`}
            method="put"
            form={form}
            onSuccessMessage="Pomyślnie edytowano zamówienie!"
            onSuccess={this.onSuccess}
            mapFormDataToPayload={data => ({
              ...data,
              deliveryCost: data.deliveryCost
                ? Number(data.deliveryCost)
                : null,
            })}
            render={({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row gutter={{ md: 16 }}>
                  <Col md={24}>
                    <Form.Item label="Spodziewany czas dostawy">
                      {form.getFieldDecorator('deliveryExpectedAt')(
                        <DatePicker
                          showTime
                          placeholder="Spodziewany czas dostawy"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Śledzenie przesyłki">
                      {form.getFieldDecorator('trackingId')(
                        <Input placeholder="Śledzenie przesyłki" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Koszt transportu">
                      {form.getFieldDecorator('deliveryCost')(
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder="Brutto"
                          min={0}
                          formatter={value =>
                            value.toString().replace(/\B(?=(\d{2}$))/g, ',')
                          }
                          parser={value =>
                            value.toString().replace(/\$\s?|(,*)/g, '')
                          }
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Zapisz
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          />
        )}
        {!editMode && (
          <>
            <Row gutter={{ md: 16 }}>
              <Col md={24}>
                <TextField
                  label="Spodziewany czas dostawy"
                  value={formatDateTime(data.deliveryExpectedAt)}
                />
              </Col>
              <Col md={24}>
                <TextField
                  label="Śledzenie przesyłki"
                  value={
                    data.trackingId ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={data.trackingId}
                      >
                        {data.trackingId}
                      </a>
                    ) : (
                      '-'
                    )
                  }
                />
              </Col>
              <Col md={24}>
                <TextField
                  label="Koszt wysyłki"
                  value={formatCurrency(data.deliveryCost, 'PLN')}
                />
              </Col>
            </Row>
          </>
        )}
      </Paper>
    )
  }
}

export const PurchaseOrderDelivery = compose(
  Form.create({ name: 'purchase-order-delivery-edit-form' })
)(PurchaseOrderDeliveryUnwrapped)
