import React from 'react'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { pathOr } from 'ramda'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Paper, Table, TextField, Spacing, Right } from 'components'
import { Row, Col, Button, Input, InputNumber, Select, DatePicker } from 'antd'
import { displayPrice } from 'utils/prices'
import { createIntArray, pad } from 'utils'
import { fetchExportInvoice } from 'api'
import { PlusOutlined } from '@ant-design/icons'

const newItem = {
  country: 'PL',
  cnCode: '',
  skuCount: 1,
  netto: 0,
  weight: 1000,
}

const numbers = createIntArray(100)
  .map(item => item.toString())
  .map(num => pad(num, 2))

const months = createIntArray(12)
  .map(item => item.toString())
  .map(num => pad(num, 2, '0'))

const years = new Array(5).fill(0).map((_, idx) => (new Date().getFullYear() + 1 - idx).toString())

const defaultState = {
  number: numbers[0],
  year: years[1],
  month: pad((new Date().getMonth() + 1).toString(), 2),
  createdAt: moment(),
  transportFee: 100000,
  items: [],
  parcelsCount: 1,
  markup: 0,
  cnDescriptions: [],
  posting: false,
}

export class ExportInvoiceCreate extends React.Component {
  state = defaultState

  componentDidMount = () => {
    this.setState({
      cnDescriptions: this.loadCnDescriptions(),
      items: this.loadItems(),
    })
  }

  addItem = () => {
    this.setState(
      state => ({
        items: [...state.items, newItem],
      }),
      this.saveItems
    )
  }

  handleValueChange = (attr, value) => {
    this.setState({ [attr]: value })
  }

  handleChange = (field, index, value) => {
    this.setState(
      state => ({
        items: state.items.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              [field]: value,
            }
          }
          return item
        }),
      }),
      this.saveItems
    )
  }

  removeItem = index => () => {
    this.setState(
      state => ({
        items: [
          ...state.items.slice(0, index),
          ...state.items.slice(index + 1),
        ],
      }),
      this.saveItems
    )
  }

  onDateChange = createdAt => {
    this.setState({ createdAt })
  }

  getLineItems = () => {
    const lineItems = Object.entries(
      this.state.items.reduce(
        (acc, item) => ({
          ...acc,
          [item.cnCode + item.country]: [...(acc[item.cnCode + item.country] || []), item],
        }),
        {}
      )
    ).map(entry => {
      const [, items] = entry
      const cnCode = items[0].cnCode
      const country = items[0].country
      const netWeightValue = items.reduce((sum, item) => sum + item.weight, 0)
      const grossWeightValue = netWeightValue * 1.05
      const price =
        items.reduce((sum, item) => sum + item.netto, 0) *
        (1 + this.state.markup / 100.0)
      const priceString = `${displayPrice(price)} zł`
      const cnDescriptions = Object.fromEntries(this.state.cnDescriptions)
      return {
        cnCode,
        country,
        cnDescription: cnDescriptions[cnCode] || '',
        skuCount: items
          .reduce((sum, item) => sum + item.skuCount, 0)
          .toString(),
        vatRate: '0%',
        quantity: '1',
        netWeightValue,
        grossWeightValue,
        netWeight: `${(netWeightValue / 1000).toFixed(3)} kg`,
        grossWeight: `${(grossWeightValue / 1000).toFixed(3)} kg`,
        price,
        netto: priceString,
        brutto: priceString,
      }
    })
    return lineItems
  }

  getSummary = lineItems => {
    const { items, parcelsCount } = this.state
    const total =
      this.state.transportFee +
      lineItems.reduce((sum, item) => sum + item.price, 0)
    const totalString = `${displayPrice(total)} zł`
    const totalNetWeight = lineItems.reduce(
      (sum, item) => sum + item.netWeightValue,
      0
    )
    const totalGrossWeight = lineItems.reduce(
      (sum, item) => sum + item.grossWeightValue,
      0
    )

    return {
      netto: totalString,
      brutto: totalString,
      vat: '0,00 zł',
      parcelsCount: `${parcelsCount} szt`,
      skuCount: `${items.reduce((sum, item) => sum + item.skuCount, 0)} szt`,
      netWeight: `${(totalNetWeight / 1000).toFixed(3)} kg`,
      grossWeight: `${(totalGrossWeight / 1000).toFixed(3)} kg`,
    }
  }

  handleCnDescriptionChange = (index, entryIdx, value) => {
    this.setState(
      state => ({
        cnDescriptions: [
          ...state.cnDescriptions.map((entry, idx) => {
            if (index === idx) {
              entry[entryIdx] = value
            }
            return entry
          }),
        ],
      }),
      this.saveCnDescriptions
    )
  }

  addCnDescription = () => {
    this.setState(
      state => ({
        cnDescriptions: [...state.cnDescriptions, ['', '']],
      }),
      this.saveCnDescriptions
    )
  }

  removeCnDescription = index => () => {
    this.setState(
      state => ({
        cnDescriptions: [
          ...state.cnDescriptions.slice(0, index),
          ...state.cnDescriptions.slice(index + 1),
        ],
      }),
      this.saveCnDescriptions
    )
  }

  saveCnDescriptions = debounce(() => {
    window.localStorage.setItem(
      'cn-descriptions',
      JSON.stringify(this.state.cnDescriptions)
    )
  }, 1000)

  saveItems = debounce(() => {
    window.localStorage.setItem(
      'export-items',
      JSON.stringify(this.state.items)
    )
  }, 1000)

  loadCnDescriptions = () => {
    try {
      const json = window.localStorage.getItem('cn-descriptions')
      if (!json) return []
      const object = JSON.parse(json)
      if (!Array.isArray(object)) return []
      return object
    } catch {
      return []
    }
  }

  loadItems = () => {
    try {
      const json = window.localStorage.getItem('export-items')
      if (!json) return []
      const object = JSON.parse(json)
      if (!Array.isArray(object)) return []
      return object
    } catch {
      return []
    }
  }

  handleSubmit = async () => {
    const { number, month, year, transportFee, createdAt } = this.state
    const lineItems = this.getLineItems()
    const summary = this.getSummary(lineItems)

    const header = {
      number: `${pad(number.toString(), 2)}/${month}/${year}`,
      createdAt: createdAt.format('DD-MM-YYYY'),
      dueDate: createdAt.clone().add(13, 'days').format('DD-MM-YYYY'),
      transportFee: `${displayPrice(transportFee)} zł`,
    }

    this.setState({ posting: true })

    const payload = {
      ...header,
      summary,
      items: lineItems,
    }

    const response = await fetchExportInvoice(payload)

    console.log(response.data.data)

    this.setState({ posting: false })

    const win = window.open(response.data.data, '_blank')
    if (win) {
      win.focus()
    }
  }

  handleReset = () => {
    this.setState(
      state => ({
        ...defaultState,
        cnDescriptions: state.cnDescriptions,
      }),
      this.saveItems
    )
  }

  isDisabled = () => {
    const { createdAt, items, posting } = this.state
    if (posting) return true
    if (!createdAt) return true
    if (items.length === 0) return true
    return items.some(item => !item.cnCode || !item.netto)
  }

  render() {
    const {
      items,
      month,
      year,
      number,
      createdAt,
      transportFee,
      markup,
      parcelsCount,
      cnDescriptions,
      posting,
    } = this.state
    const lineItems = this.getLineItems()
    const summary = this.getSummary(lineItems)

    return (
      <MainLayout
        header="Generator faktury eksportowej"
        backgroundColor="none"
        marginTop="0"
      >
        <Paper title="Informacje">
          <Row>
            <Col md={8}>
              <TextField
                label="Numer"
                value={
                  <Input.Group compact>
                    <InputNumber
                      value={number}
                      formatter={value => pad(value.toString(), 2)}
                      min={1}
                      parser={value =>
                        value.toString().replace(/\$\s?|(,*)/g, '')
                      }
                      onChange={value =>
                        this.handleValueChange('number', value)
                      }
                    />
                    <Select
                      onChange={value => this.handleValueChange('month', value)}
                      value={month}
                    >
                      {months.map(m => (
                        <Select.Option key={m} value={m}>
                          {m}
                        </Select.Option>
                      ))}
                    </Select>
                    <Select
                      onChange={value => this.handleValueChange('year', value)}
                      value={year}
                    >
                      {years.map(y => (
                        <Select.Option key={y} value={y}>
                          {y}
                        </Select.Option>
                      ))}
                    </Select>
                  </Input.Group>
                }
              />
              <TextField
                label="Data wystawienia"
                value={
                  <DatePicker
                    onChange={this.onDateChange}
                    value={createdAt}
                    format="DD/MM/YYYY"
                  />
                }
              />
              <TextField
                label="Narzut (%)"
                value={
                  <InputNumber
                    value={markup}
                    min={0}
                    step={5}
                    onChange={value => this.handleValueChange('markup', value)}
                  />
                }
              />
            </Col>
            <Col md={8}>
              <TextField
                label="Koszt transportu (zł)"
                value={
                  <InputNumber
                    value={transportFee}
                    min={0}
                    formatter={value =>
                      value.toString().replace(/\B(?=(\d{2}$))/g, ',')
                    }
                    parser={value =>
                      value.toString().replace(/\$\s?|(,*)/g, '')
                    }
                    step={100}
                    onChange={value =>
                      this.handleValueChange('transportFee', value)
                    }
                  />
                }
              />
              <TextField
                label="Ilość kartonów"
                value={
                  <InputNumber
                    value={parcelsCount}
                    min={1}
                    step={1}
                    onChange={value =>
                      this.handleValueChange('parcelsCount', value)
                    }
                  />
                }
              />
            </Col>
            <Col md={8}>
              <TextField label="Wartość faktury" value={summary.netto} />
              <TextField label="Waga netto" value={summary.netWeight} />
              <TextField label="Waga brutto" value={summary.grossWeight} />
              <TextField label="Ilość produktów" value={summary.skuCount} />
            </Col>
          </Row>
        </Paper>
        <Paper title="Lista produktów">
          <Table>
            <Table.Body>
              <Table.Tr>
                <Table.Th>Kraj</Table.Th>
                <Table.Th>Kod CN</Table.Th>
                <Table.Th>Opis</Table.Th>
                <Table.Th>Różnych SKU w kategorii</Table.Th>
                <Table.Th>Waga (g)</Table.Th>
                <Table.Th>Cena na fakturze</Table.Th>
                <Table.Th />
              </Table.Tr>
              {items.map((item, index) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    <Input
                      value={item.country}
                      onChange={e =>
                        this.handleChange(
                          'country',
                          index,
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <Input
                      value={item.cnCode}
                      onChange={e =>
                        this.handleChange(
                          'cnCode',
                          index,
                          e.target.value.toUpperCase()
                        )
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <small>
                      {pathOr(
                        'Brak',
                        [1],
                        this.state.cnDescriptions.find(
                          cn => cn[0] === item.cnCode
                        )
                      )}
                    </small>
                  </Table.Td>
                  <Table.Td>
                    <InputNumber
                      value={item.skuCount}
                      min={1}
                      step={1}
                      onChange={value =>
                        this.handleChange('skuCount', index, value)
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <InputNumber
                      value={item.weight}
                      min={1}
                      step={100}
                      onChange={value =>
                        this.handleChange('weight', index, value)
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <InputNumber
                      value={item.netto}
                      min={0}
                      formatter={value =>
                        value.toString().replace(/\B(?=(\d{2}$))/g, ',')
                      }
                      parser={value =>
                        value.toString().replace(/\$\s?|(,*)/g, '')
                      }
                      step={100}
                      onChange={value =>
                        this.handleChange('netto', index, value)
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <Button size="small" onClick={this.removeItem(index)}>
                      Usuń
                    </Button>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Body>
          </Table>
          <Spacing mt="2rem">
            <Button type="primary" onClick={this.addItem}>
              <PlusOutlined />
              Dodaj
            </Button>
          </Spacing>
        </Paper>
        <Paper title="Pozycje na fakturze">
          <Table>
            <Table.Body>
              <Table.Tr>
                <Table.Th>Kraj</Table.Th>
                <Table.Th>Kod CN</Table.Th>
                <Table.Th>Opis</Table.Th>
                <Table.Th>Szt.</Table.Th>
                <Table.Th>Produktów w kategorii</Table.Th>
                <Table.Th>Waga netto</Table.Th>
                <Table.Th>Waga brutto</Table.Th>
                <Table.Th>Cena</Table.Th>
              </Table.Tr>
              {lineItems.map(item => (
                <Table.Tr key={item.country + item.cnCode}>
                  <Table.Td>{item.country}</Table.Td>
                  <Table.Td>{item.cnCode}</Table.Td>
                  <Table.Td>{item.cnDescription}</Table.Td>
                  <Table.Td>1</Table.Td>
                  <Table.Td>{item.skuCount}</Table.Td>
                  <Table.Td>{item.netWeight}</Table.Td>
                  <Table.Td>{item.grossWeight}</Table.Td>
                  <Table.Td>{item.netto}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Body>
          </Table>
        </Paper>
        <Paper title="Opisy kodów CN">
          <Table>
            <Table.Body>
              <Table.Tr>
                <Table.Th>Kod CN</Table.Th>
                <Table.Th>Opis kategorii</Table.Th>
              </Table.Tr>
              {cnDescriptions.map((entry, index) => (
                <Table.Tr key={index}>
                  <Table.Td>
                    <Input
                      value={entry[0]}
                      onChange={e =>
                        this.handleCnDescriptionChange(index, 0, e.target.value)
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <Input
                      value={entry[1]}
                      onChange={e =>
                        this.handleCnDescriptionChange(index, 1, e.target.value)
                      }
                    />
                  </Table.Td>
                  <Table.Td>
                    <Button
                      size="small"
                      onClick={this.removeCnDescription(index)}
                    >
                      Usuń
                    </Button>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Body>
          </Table>
          <Spacing mt="2rem">
            <Button type="primary" onClick={this.addCnDescription}>
              <PlusOutlined />
              Dodaj
            </Button>
          </Spacing>
        </Paper>
        <Paper title="Generuj">
          <Button
            loading={posting}
            type="danger"
            disabled={this.isDisabled()}
            onClick={this.handleSubmit}
          >
            Generuj PDF
          </Button>
          <Right>
            <Button onClick={this.handleReset}>Wyczyść</Button>
          </Right>
        </Paper>
      </MainLayout>
    )
  }
}
