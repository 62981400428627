import React from 'react'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'

class WithManufacturersHOC extends React.Component {
  state = {
    data: [],
  }

  setManufacturers = data => {
    this.setState({
      data,
    })
  }

  normalizeManufacturers = (manufacturers = []) =>
    manufacturers.map(manufacturer => ({
      key: manufacturer.code,
      code: manufacturer.code,
      name: manufacturer.name,
      skuPrefix: manufacturer.skuPrefix,
    }))

  render() {
    const { Component, ...rest } = this.props
    return (
      <ResourceProvider
        url="/manufacturers"
        pagination
        paginationParams={{
          current: 1,
          pageSize: 10000,
          total: null,
        }}
        responseNormalizer={this.normalizeManufacturers}
        onSuccess={this.setManufacturers}
        render={() => <Component {...rest} manufacturers={this.state.data} />}
      />
    )
  }
}

export const withManufacturers = Component => props => (
  <WithManufacturersHOC Component={Component} {...props} />
)
