import styled from 'styled-components'

export const SGridCol = styled.div`
  flex-grow: 1;
  margin-right: 4px;
  width: ${({ span }) => (span ? `${(span * 100) / 24}%` : '100%')};
  &:last-child {
    margin-right: 0;
  }
  & > div:last-child {
    margin-bottom: 0;
  }
`
