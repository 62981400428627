import React from 'react'
import { Table, Avatar } from 'antd'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { truncate } from 'utils/formatters'

const getColumns = (refresh, sku) => [
  {
    title: 'Zdjęcie',
    dataIndex: 'publicUrl',
    render: url => <Avatar shape="square" size={100} src={url} />,
  },
  {
    title: 'URI',
    dataIndex: 'uri',
    render: uri => uri.slice(-8),
  },
  {
    title: 'Oryginalna nazwa pliku',
    dataIndex: 'originalFilename',
    render: fileName => truncate(fileName, 40),
  },
  {
    title: '',
    render: (_text, image) => (
      <RequestButton
        content="Odlinkuj"
        method="delete"
        url={`/products/${encodeURIComponent(sku)}/images/${image.uri}`}
        type="link"
        onSuccessMessage={'Pomyślnie usunięto zdjęcie!'}
        onSuccess={() => {
          refresh()
        }}
      />
    ),
  },
]

export const SKUImagesTable = ({ productImages, refresh, sku }) => (
  <Table
    showHeader={false}
    rowKey="uri"
    columns={getColumns(refresh, sku)}
    dataSource={productImages}
  />
)

SKUImagesTable.defaultProps = {
  productImages: [],
}
