import styled from 'styled-components'
import { WHITE, GREY_DARK } from 'layouts/colors'

export const SGrid = styled.div`
  width: 100%;
  border-radius: 2px;
  background: ${({ color }) => color || WHITE};
  border: ${({ dashed }) => (dashed ? `1px dashed ${GREY_DARK}` : 'none')};
  padding: ${({ padding }) => padding || '1.5rem 2rem'};
`
