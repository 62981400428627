import React from 'react'
import { connect } from 'react-redux'
import { API_BASE_URL_V2 } from 'config'
import { Upload, message } from 'antd'
import { Paper } from 'components'
import { InboxOutlined } from '@ant-design/icons'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { AttachmentList } from './AttachmentList/AttachmentList'

const getProps = (token, refresh, targetId) => ({
  name: 'file',
  multiple: true,
  action: `${API_BASE_URL_V2}/v2/attachments/${targetId}`,
  headers: {
    Authorization: token,
  },
  onChange(info) {
    const { status } = info.file
    if (status === 'done') {
      message.success(`${info.file.name} uploadowano pomyślnie.`)
    } else if (status === 'error') {
      message.error(`${info.file.name} nie udało się uploadować.`)
    }
    refresh()
  },
})

const AttachmentsUnwrapped = ({ token, targetId }) => (
  <Paper title="Załączniki">
    <ResourceProvider
      v2
      url={`/attachments/${targetId}`}
      paginationParams={{
        current: 1,
        pageSize: 10000,
        total: null,
      }}
      render={({ refresh, data }) => (
        <>
          {data && (
            <AttachmentList
              data={data}
              refresh={refresh}
              token={token}
            />
          )}
          <Upload.Dragger {...getProps(token, refresh, targetId)}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Dodaj załączniki</p>
            <p className="ant-upload-hint">
              Dodaj dokumenty zamówienia, wz dostawcy, faktury i rachunki zakupu
            </p>
          </Upload.Dragger>
        </>
      )}
    />
  </Paper>
)

export const Attachments = connect(
  state => ({ token: state.authReducer.token }),
  null
)(AttachmentsUnwrapped)
