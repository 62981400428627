import React from 'react'
import {
  Paper,
  Table,
  Align,
  ProductNumber,
  Spacing,
  RequestButton,
  Copy,
} from 'components'
import { AddItemForm } from './AddItemForm/AddItemForm'
import { formatCurrency } from 'utils/formatters'
import { CloseOutlined } from '@ant-design/icons'

export class ProductSelector extends React.Component {
  state = {
    items: this.props.data.items || [],
  }

  addItem = item => {
    this.setState(state => ({
      items: [...state.items.filter(i => i.sku !== item.sku), item],
    }))
  }

  removeItem = sku => () => {
    this.setState(state => ({
      items: state.items.filter(item => item.sku !== sku),
    }))
  }

  handleSuppliersCodeChange = sku => suppliersCode => {
    this.setState(state => ({
      items: state.items.map(item => {
        if (item.sku === sku) {
          return {
            ...item,
            suppliersCode,
          }
        }
        return item
      }),
    }))
  }

  isChanged = () => {
    const { items: stateItems } = this.state
    const propsItems = this.props.data.items || []
    if (propsItems.length !== stateItems.length) {
      return true
    }
    for (let i = 0; i < stateItems.length; i++) {
      if (
        stateItems[i].sku !== propsItems[i].sku ||
        stateItems[i].quantity !== propsItems[i].quantity ||
        stateItems[i].suppliersCode !== propsItems[i].suppliersCode
      ) {
        return true
      }
    }
    return false
  }

  render() {
    const { data, refresh } = this.props
    const { items } = this.state

    return (
      <Paper title="Lista zamówienia" id="data_product-selector">
        <Table>
          <Table.Body>
            <Table.Tr>
              <Table.Th style={{ width: '30%' }}>SKU</Table.Th>
              <Table.Th style={{ width: '30%' }}>Kod dostawcy</Table.Th>
              <Table.Th style={{ width: '15%' }}>Ilość</Table.Th>
              <Table.Th style={{ width: '20%' }}>Brutto</Table.Th>
              <Table.Th style={{ width: '5%' }} />
            </Table.Tr>
            {items.length === 0 && (
              <Table.Tr>
                <Table.Td colSpan={4} width="100%">
                  <Align value="center">
                    <small>
                      <i>Brak</i>
                    </small>
                  </Align>
                </Table.Td>
              </Table.Tr>
            )}
            {items.map((item, index) => (
              <Table.Tr key={item.sku}>
                <Table.Td width="30%">
                  {index + 1}. <ProductNumber sku={item.sku} />
                </Table.Td>
                <Table.Td width="30%">
                  {item.supplierCode} {item.supplierCode && <Copy value={item.supplierCode} />}
                </Table.Td>
                <Table.Td width="15%">{item.quantity}</Table.Td>
                <Table.Td width="20%">{formatCurrency(item.price.brutto, 'PLN')}</Table.Td>
                <Table.Td width="5%">
                  <RequestButton
                    content={<CloseOutlined style={{ color: 'red' }} />}
                    method="delete"
                    v2
                    payload={{
                      isFlagged: true,
                    }}
                    url={`/purchase-order-items/${item.id}`}
                    type="link"
                    onSuccessMessage={`Pomyślnie usunięto ${item.sku}`}
                    onSuccess={() => {
                      refresh()
                    }}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Body>
        </Table>
        <Spacing mt="1.4rem">
          <AddItemForm
            supplierCode={data.supplierId}
            addItem={this.addItem}
            refresh={refresh}
            purchaseOrderId={data.id}
          />
        </Spacing>
      </Paper>
    )
  }
}
