import React from 'react'
import { TextField } from 'components'
import { Input, Switch, Typography } from 'antd'

export const Recipient = ({ recipient, handleRecipientChange, errors }) => (
  <>
    <TextField
      label="Firma"
      value={
        <Switch
          checked={recipient.isCorporate}
          onChange={handleRecipientChange('isCorporate')}
        />
      }
    />
    <TextField
      label={recipient.isCorporate ? 'Nazwa firmy' : 'Imię i nazwisko'}
      value={
        <>
          <Input
            value={recipient.name}
            onChange={e => handleRecipientChange('name')(e.target.value)}
          />
          {errors.name && (
            <Typography.Text type="danger">{errors.name}</Typography.Text>
          )}
        </>
      }
    />
    <TextField
      label="E-mail"
      value={
        <>
          <Input
            value={recipient.emailAddress}
            onChange={e =>
              handleRecipientChange('emailAddress')(e.target.value)
            }
          />
          {errors.emailAddress && (
            <Typography.Text type="danger">
              {errors.emailAddress}
            </Typography.Text>
          )}
        </>
      }
    />
    <TextField
      label="NIP"
      value={
        <>
          <Input
            value={recipient.taxId}
            onChange={e => handleRecipientChange('taxId')(e.target.value)}
          />
          {errors.taxId && (
            <Typography.Text type="danger">{errors.taxId}</Typography.Text>
          )}
        </>
      }
    />
    <TextField
      label="Numer użytkownika"
      value={
        <>
          <Input
            value={recipient.userId}
            onChange={e => handleRecipientChange('userId')(e.target.value)}
          />
          {errors.userId && (
            <Typography.Text type="danger">{errors.userId}</Typography.Text>
          )}
        </>
      }
    />
  </>
)
