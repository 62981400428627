import React from 'react'
import styled from 'styled-components'
import { pathOr } from 'ramda'
import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { SHeader } from './SHeader/SHeader'
import { SLogo } from './SLogo/SLogo'
import { DrawersManager } from './DrawersManager/DrawersManager'
import { LoadingScreen } from './LoadingScreen/LoadingScreen'
import {
  DollarOutlined,
  GiftOutlined,
  ContainerOutlined,
  TagsOutlined,
  DatabaseOutlined,
  FireOutlined,
  BankOutlined,
  TrademarkOutlined,
  PlayCircleOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ImportOutlined,
  CarOutlined,
} from '@ant-design/icons'

const ScrollContainer = styled.div`
  max-width: 100%;
  overflow-x: scroll;
`

const styles = {
  layout: ({ minWidth }) => ({
    minHeight: '100vh',
    minWidth: minWidth ? '1000px' : 'none',
  }),
  header: {
    background: '#fff',
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    merginLeft: 10,
  },
  content: ({
    backgroundColor,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    transparent,
  }) => ({
    marginTop: marginTop || '24px',
    marginBottom: marginBottom || '24px',
    marginLeft: marginLeft || '16px',
    marginRight: marginRight || '16px',
    padding: 24,
    background: backgroundColor || '#fff',
    minHeight: 280,
    ...(transparent
      ? {
        background: 'transparent',
        padding: 0,
      }
      : {}),
  }),
}

const ROUTES = [
  {
    key: '/sales',
    icon: <DollarOutlined />,
    label: 'Sprzedaż',
    default: true,
  },
  {
    key: '/parcels',
    icon: <GiftOutlined />,
    label: 'Paczki',
  },
  {
    key: '/sale-invoices',
    icon: <ContainerOutlined />,
    label: 'Faktury sprzedaży',
  },
  {
    key: '/products',
    icon: <TagsOutlined />,
    label: 'Produkty',
  },
  {
    key: '/stock',
    icon: <DatabaseOutlined />,
    label: 'Stany Magazynowe',
  },
  {
    key: '/stock-reservations',
    icon: <FireOutlined />,
    label: 'Tablica',
  },
  {
    key: '/warehouses',
    icon: <BankOutlined />,
    label: 'Magazyny',
  },
  {
    key: '/manufacturers',
    icon: <TrademarkOutlined />,
    label: 'Producenci',
  },
  {
    key: '/jobs',
    icon: <PlayCircleOutlined />,
    label: 'Operacje',
  },
  {
    key: '/purchase-orders',
    icon: <ImportOutlined />,
    label: 'Zaopatrzenie',
  },
  {
    key: '/transports',
    icon: <CarOutlined />,
    label: 'Transporty',
  },
]

class MainLayoutUwrapped extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsed: this.getCollapsed(),
    }
  }

  getCollapsed = () => {
    return !!Number(window.localStorage.getItem('collapsed'))
  }

  setCollapsed = bool => {
    window.localStorage.setItem('collapsed', Number(bool))
  }

  toggle = () => {
    this.setState(
      {
        collapsed: !this.state.collapsed,
      },
      () => {
        this.setCollapsed(this.state.collapsed)
      }
    )
  }

  getSelectedButtons = () => {
    const path = this.props.location.pathname
    if (path === '/') {
      return ROUTES.filter(route => !!route.default).map(r => r.key)
    }
    // eslint-disable-next-line no-unused-vars
    for (const route of ROUTES) {
      if (path.match(/^\/([^/]*)/)[0] === route.key) {
        return [route.key]
      }
    }
    return []
  }

  getContent = () => {
    if (this.props.isLoading) {
      return <LoadingScreen />
    }
    return this.props.children
  }

  render() {
    return (
      <>
        <Layout style={styles.layout(this.props)}>
          <Layout.Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
          >
            <SLogo
              onClick={() => {
                this.props.history.push('/')
              }}
            >
              Altbra
            </SLogo>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={this.getSelectedButtons()}
              defaultSelectedKeys={['/channels']}
            >
              {ROUTES.map(route => (
                <Menu.Item key={route.key}>
                  <Link
                    to={route.key}
                    id={`main-menu${route.key.replace(/\//g, '_')}`}
                  >
                    {route.icon}
                    <span>{route.label}</span>
                  </Link>
                </Menu.Item>
              ))}
            </Menu>
          </Layout.Sider>
          <Layout>
            <Layout.Header style={styles.header}>
              {this.state.collapsed ? (
                <MenuUnfoldOutlined
                  className="trigger"
                  style={{ fontSize: 20 }}
                  onClick={this.toggle}
                />
              ) : (
                <MenuFoldOutlined
                  className="trigger"
                  style={{ fontSize: 20 }}
                  onClick={this.toggle}
                />
              )}
              <SHeader>{this.props.header}</SHeader>
              <div>
                <strong>
                  {pathOr('-', ['user', 'firstName'], this.props)}{' '}
                  {pathOr('-', ['user', 'lastName'], this.props)}
                </strong>{' '}
                <Link to="/login">Wyloguj</Link>
              </div>
            </Layout.Header>
            <Layout.Content style={styles.content(this.props)}>
              <ScrollContainer>{this.getContent()}</ScrollContainer>
            </Layout.Content>
          </Layout>
        </Layout>
        <DrawersManager />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...state.authReducer,
})

export const MainLayout = compose(
  connect(mapStateToProps, null),
  withRouter
)(MainLayoutUwrapped)
