import styled from 'styled-components'
import { BLUE, GREY, WHITE } from 'layouts/colors'
import { darken } from 'polished'

export const SGridItem = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ background }) => background || GREY};
  color: ${({ dark }) => (dark ? WHITE : 'inherit')};
  border: none;
  border-radius: 1px;
  ${({ active }) => (active ? `border: 1px dashed ${BLUE};` : '')}
  ${({ flex, justifyContent, alignItems }) =>
    flex &&
    `
      display: flex;
      flex-direction: row;
      justify-content: ${justifyContent || 'center'};
      align-items: ${alignItems || 'center'};
    `}
  padding: ${({ padding }) => padding || '1rem 1.2rem'};

  &:hover {
    ${({ onClick, background }) => {
      if (!onClick) return ''
      return `
        background: ${darken(0.02, background || GREY)};
        cursor: pointer;
      `
    }}
  }
`
