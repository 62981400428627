import React from 'react'
import { request } from 'api/request'
import { Typography, Button } from 'antd'
import { Table, Spacing, RequestButton } from 'components'
import { formatCurrency, formatDateTime } from 'utils/formatters'

export class AddressPicker extends React.Component {
  state = {
    loadingCount: 0,
    addresses: [],
    klarnaPayments: [],
  }

  componentDidMount = async () => {
    await this.fetchAddresses()
    if (this.props.parcel.deliveryAddress) {
      this.props.setAddress(this.props.parcel.deliveryAddress)
    }
  }

  fetchAddresses = async () => {
    const { saleIds } = this.props.parcel
    this.setState({ loadingCount: saleIds.length })
    // eslint-disable-next-line no-unused-vars
    for (const saleId of saleIds) {
      try {
        const response = await request.get(`/orders/${saleId}`)
        this.setState(state => ({
          loadingCount: state.loadingCount - 1,
          addresses: [...state.addresses, response.data.data.deliveryAddress],
          klarnaPayments: [...state.klarnaPayments, ...response.data.data.payments.filter(p => p.type === 'klarna').map(p => ({
            ...p,
            saleId,
          }))],
        }))
      } catch {
        this.setState(state => ({
          loadingCount: state.loadingCount - 1,
        }))
      }
    }
  }

  render() {
    const { loadingCount, addresses, klarnaPayments } = this.state
    if (loadingCount > 0) return 'Loading...'
    return (
      <Spacing mb="2rem">
        <Spacing mb="1rem">
          <Typography>Wybierz z zamówień</Typography>
        </Spacing>
        <Table>
          <Table.Body>
            <Table.Tr>
              <Table.Th>Nazwa</Table.Th>
              <Table.Th>Tel</Table.Th>
              <Table.Th>NIP</Table.Th>
              <Table.Th>Adres 2</Table.Th>
              <Table.Th>Miasto</Table.Th>
              <Table.Th>Kod pocztowy</Table.Th>
              <Table.Th>Kod kraju</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
            {addresses.map((address, index) => (
              <Table.Tr key={index}>
                <Table.Td>{address.name || '-'}</Table.Td>
                <Table.Td>{address.telephone || '-'}</Table.Td>
                <Table.Td>{address.taxId || '-'}</Table.Td>
                <Table.Td>{address.addressLine1 || '-'}</Table.Td>
                <Table.Td>{address.addressLine2 || '-'}</Table.Td>
                <Table.Td>{address.city || '-'}</Table.Td>
                <Table.Td>{address.postalCode || '-'}</Table.Td>
                <Table.Td>{address.countryCode || '-'}</Table.Td>
                <Table.Td>
                  <Button
                    type="link"
                    id={`parcel_address_address-picker_addresses_${index}_submit`}
                    onClick={() => {
                      this.props.setAddress(address)
                    }}
                  >
                    Wybierz
                  </Button>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Body>
        </Table>
        {klarnaPayments.length > 0 && (
          <>
            <Spacing mt="2rem" mb="1rem">
              <Typography>Pobierz z Klarny</Typography>
              <small>Nie wszystkie zamówienia są opłacane przez osobę do której wysyłane jest zamówienie. Adres wysyłki z Klarny może być nieaktualny. W razie wątpliwości skontaktuj się z klientem w celu ustalenia prawidłowego adresu wysyłki.</small>
            </Spacing>
            <Table>
              <Table.Body>
                <Table.Tr>
                  <Table.Th>ID</Table.Th>
                  <Table.Th>Wartość</Table.Th>
                  <Table.Th>Typ</Table.Th>
                  <Table.Th>Status</Table.Th>
                  <Table.Th>Data</Table.Th>
                  <Table.Th></Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
                {klarnaPayments.map((payment, index) => (
                  <Table.Tr key={index}>
                    <Table.Td>
                      {payment.paymentId.slice(0, 6)}
                    </Table.Td>
                    <Table.Td>
                      {formatCurrency(payment.value, 'NOK')}
                    </Table.Td>
                    <Table.Td>{payment.type}</Table.Td>
                    <Table.Td>{payment.status || '-'}</Table.Td>
                    <Table.Td>
                      {formatDateTime(payment.timestamp)}
                    </Table.Td>
                    <Table.Td>
                      <RequestButton
                        onSuccess={address => {
                          this.props.setAddress(address)
                        }}
                        onSuccessMessage="Pobrano adres faktury z Klarny"
                        url={`/klarna/sales/${payment.saleId}/payments/${payment.paymentId}/get-billing-address`}
                        method="get"
                        type="link"
                        content="Adresy faktury"
                      />
                    </Table.Td>
                    <Table.Td>
                      <RequestButton
                        onSuccess={address => {
                          this.props.setAddress(address)
                        }}
                        onSuccessMessage="Pobrano adres wysyłki z Klarny"
                        url={`/klarna/sales/${payment.saleId}/payments/${payment.paymentId}/get-delivery-address`}
                        method="get"
                        type="link"
                        content="Adresy dostawy"
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Body>
            </Table>
          </>
        )}
      </Spacing>
    )
  }
}
