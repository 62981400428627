import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { ProductNumber, Spacing, Nowrap, Align } from 'components'
import { Table, Typography, Row, Col, Button, Tooltip } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { RED } from 'layouts/colors'
import { CreateReplenishmentModal } from './CreateReplenishmentModal/CreateReplenishmentModal'

const getColumns = refresh => [
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    render: (value, _, index) => (
      <Nowrap>
        {index + 1}. <ProductNumber sku={value} />
      </Nowrap>
    ),
  },
  {
    title: '',
    dataIndex: 'sku',
    key: 'actions',
    render: sku => <CreateReplenishmentModal sku={sku} refresh={refresh} />,
  },
  {
    title: '',
    key: 'nameNO',
    render: (_, record) => (
      <div>
        <div>
          <small>{record.namePL}</small>
        </div>
        <div>
          <small>{record.nameNO}</small>
        </div>
        <div>
          <small>{record.nameEN}</small>
        </div>
        <div>
          <small>
            <i>{record.note}</i>
          </small>
        </div>
      </div>
    ),
  },
  {
    title: 'WD',
    dataIndex: 'virtualAvailability',
    key: 'virtualAvailability',
    render: value => (
      <Tooltip title="Wirtualna dostępność">
        <span>{value || '0'}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Łącznie sprzedanych',
    dataIndex: 'ordered',
    key: 'ordered',
    render: value => (
      <Tooltip title="Łącznie sprzedanych">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Zamówionych u dostawców',
    dataIndex: 'replenished',
    key: 'replenished',
    render: value => (
      <Tooltip title="Zamówionych u dostawców">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Na magazynie',
    dataIndex: 'inStock',
    key: 'inStock',
    render: value => (
      <Tooltip title="Na magazynie">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Łącznie zapakowano',
    dataIndex: 'packed',
    key: 'packed',
    render: value => (
      <Tooltip title="Łącznie zapakowano">
        <span>{value}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Do zapakowania',
    dataIndex: 'remainingToPack',
    key: 'remainingToPack',
    render: (_, item) => (
      <Tooltip title="Do zapakowania">
        <span>{item.ordered - item.packed}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Do domówienia',
    dataIndex: 'toReplenish',
    key: 'toReplenish',
    render: (_, item) => {
      const quantity = Math.abs(Math.min(item.replenished - item.ordered, 0))
      if (quantity === 0) return 0
      return (
        <Tooltip title="Do domówienia">
          <span style={{ color: RED }}>
            {quantity} <WarningOutlined />
          </span>
        </Tooltip>
      )
    },
  },
]

export const StockReservationList = ({ history }) => (
  <MainLayout header="Tablica" minWidth>
    <Row justify="end" type="flex" style={{ marginBottom: '3rem' }}>
      <Col span={24}>
        <Align value="right">
          <Button
            type="primary"
            id="sales-create-link"
            onClick={() => {
              history.push('/stock-reservations/replenishments')
            }}
          >
            Historia zamówień
          </Button>
        </Align>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <ResourceProvider
          url="/stock/stock-reservations"
          render={({ data, loading, refresh }) => (
            <Table
              rowKey="sku"
              columns={getColumns(refresh)}
              dataSource={data}
              pagination={false}
              loading={loading}
            />
          )}
        />
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <Spacing mt="2rem">
          <Typography.Text>
            <small>
              Lista zmienia się automatycznie. Pozycje pojawiają się na liście
              po zaakceptowaniu zamówień oraz znikają gdy produkty
              zostają zapakowane.
            </small>
          </Typography.Text>
        </Spacing>
      </Col>
    </Row>
  </MainLayout>
)
