import React from 'react'
import { withRouter } from 'react-router-dom'
import { pathOr } from 'ramda'

export const withOnErrorRedirect = Component =>
  withRouter(props => (
    <Component
      {...props}
      onErrorRedirect={error => {
        props.history.push('/error', {
          statusText: pathOr('XXX', ['response', 'data', 'statusText'], error),
          status: pathOr('Dziwny błąd.', ['response', 'data', 'status'], error),
        })
      }}
    />
  ))
