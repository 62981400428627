import styled from 'styled-components'
import { Body } from './Body'
import { Td } from './Td'
import { Th } from './Th'
import { Tr } from './Tr'

const Table = styled.table`
  font-size: 12px;
  width: 100%;
  tr {
    border-bottom: 1px dashed #e8e8e8;
  }
  tr:last-child, tr:first-child {
    border: none;
  }
`

Table.Body = Body
Table.Td = Td
Table.Th = Th
Table.Tr = Tr

export {
  Table,
}
