import React from 'react'
import { pathOr } from 'ramda'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Paper, PrintQrLabel } from 'components'
import { Link } from 'react-router-dom'
import { Row, Col, Table, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { TableSearch } from 'components/TableSearch/TableSearch'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { StockTransaction } from './StockTransaction/StockTransaction'

const getColumns = refresh => [
  {
    title: 'SKU',
    dataIndex: 'sku',
    sorter: true,
    key: 'sku',
    render: value => <><Link to={`/products/${value}`}>{value}</Link> <PrintQrLabel value={value} /></>,
  },
  {
    title: '',
    key: 'nameNO',
    render: (_, record) => (
      <div>
        <div>
          <small>{record.namePL}</small>
        </div>
        <div>
          <small>{record.nameNO}</small>
        </div>
        <div>
          <small>{record.nameEN}</small>
        </div>
        <div>
          <small>
            <i>{record.note}</i>
          </small>
        </div>
      </div>
    ),
  },
  {
    title: 'Strefa',
    dataIndex: 'zones.name',
    sorter: true,
    key: 'zone',
    render: (_, item) => item.zone.name || '-',
  },
  {
    title: 'Magazyn',
    dataIndex: 'warehouses.name',
    sorter: true,
    key: 'warehouse',
    render: (_, item) => pathOr('-', ['zone', 'warehouse', 'name'], item),
  },
  {
    title: 'Ilość',
    dataIndex: 'quantity',
    key: 'quantity',
    sorter: true,
  },
  {
    title: 'Transakcja',
    dataIndex: 'transaction',
    render: (_, item) => (
      <StockTransaction
        key={`${item.sku}-${item.zoneId}`}
        sku={item.sku}
        zoneId={item.zone.id}
        refresh={refresh}
      />
    ),
  },
]

export const StockLevelList = ({ history }) => (
  <MainLayout header="Stany magazynowe" minWidth>
    <ResourceProvider
      url="/stock"
      pagination
      encodePageNumber
      responseNormalizer={items =>
        items.map((item, index) => ({ ...item, index }))
      }
      sorting
      render={({
        data,
        paginationParams,
        loading,
        onChange,
        refresh,
        onFiltersChange,
        filterParams,
      }) => (
        <>
          <Row style={{ marginBottom: '3rem' }}>
            <Col md={12}>
              <TableSearch
                onFiltersChange={onFiltersChange}
                filterParams={filterParams}
                options={{
                  sku: 'Kod Towaru',
                }}
              />
            </Col>
            <Col md={12} style={{ textAlign: 'right' }}>
              <Button.Group>
                <Button
                  id="stock-receive-link"
                  type="primary"
                  onClick={() => {
                    history.push('/stock/receive')
                  }}
                >
                  <PlusOutlined />
                  Przyjmij
                </Button>
              </Button.Group>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Paper title="Aktualne stany">
                <Row>
                  <Col md={24}>
                    <Table
                      rowKey="index"
                      columns={getColumns(refresh)}
                      dataSource={data}
                      pagination={{
                        ...paginationParams,
                        showSizeChanger: true,
                      }}
                      loading={loading}
                      onChange={onChange}
                    />
                  </Col>
                </Row>
              </Paper>
            </Col>
          </Row>
        </>
      )}
    />
  </MainLayout>
)
