import React from 'react'
import { Card } from 'antd'

export const Paper = ({
  children,
  title,
  extra,
  padding,
  maxHeight,
  overflow,
  id,
}) => (
  <Card
    id={id}
    title={title}
    extra={extra}
    size="small"
    bordered={false}
    style={{ marginBottom: '1.2rem' }}
    bodyStyle={{ padding, maxHeight, overflow: overflow || 'auto' }}
  >
    {children}
  </Card>
)
