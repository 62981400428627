import { combineReducers } from 'redux'
import { drawerReducer } from './drawerReducer'
import { authReducer } from './authReducer'
import { productReducer } from './productReducer'
import { priceReducer } from './priceReducer'

export default combineReducers({
  drawerReducer,
  authReducer,
  products: productReducer,
  prices: priceReducer,
})
