import React, { useEffect, useState } from 'react'
import { request } from 'api/request'
import { Spacing } from 'components'

export const FulfillmentsWidget = ({ saleIds }) => {
  const [fulfillments, setFulfillments] = useState([])
  useEffect(() => {
    const fetch = async () => {
      const responses = await Promise.all(
        saleIds.map(async saleId => {
          try {
            const response = await request.get(`/fulfillments/${saleId}`)
            return response.data.data
          } catch {
            return saleId
          }
        })
      )
      setFulfillments(responses.filter(Boolean))
    }
    if (Array.isArray(saleIds) && saleIds.length > 0) {
      fetch()
    }
  }, [saleIds])

  if (!Array.isArray(saleIds) || saleIds.length <= 0) return null

  return (
    <div>
      {fulfillments.map((fulfillment) => {
        if (typeof fulfillment === 'string') {
          return <a rel="noopener noreferrer" target="_blank" key={fulfillment} href={`/sales/${fulfillment}`}>{fulfillment}</a>
        }
        return (
          <Spacing mb={fulfillments.length > 0 ? "0.4rem" : "0"} key={fulfillment.id}>
            <div><a rel="noopener noreferrer" target="_blank" href={`/sales/${fulfillment.id}`}>{fulfillment.number}</a></div>
            <small>{fulfillment.emailAddress}</small>
          </Spacing>
        )
      })}
    </div>
  )
}
