import React, { useState } from 'react'
import { InputNumber, Select } from 'antd'
import { TextField, Table, ProductNumber, RequestButton } from 'components'
import { Link } from 'react-router-dom'

export const PackItem = ({
  sku,
  saleId,
  saleNumber,
  zones,
  remainingToPack,
  parcelId,
  refresh,
  disabled,
}) => {
  const [quantity, onQuantityChange] = useState(remainingToPack)
  const [zoneId, onZoneChange] = useState(2)
  return (
    <Table.Tr>
      <Table.Td>
        <TextField
          mb="0"
          value={<Link to={`/sales/${saleId}`}>{saleNumber}</Link>}
        />
      </Table.Td>
      <Table.Td>
        <TextField mb="0" value={<ProductNumber sku={sku} />} />
      </Table.Td>
      <Table.Td>
        <TextField mb="0" value={remainingToPack || '0'} />
      </Table.Td>
      <Table.Td>
        <Select
          id="pack-item_zoneId"
          style={{ minWidth: '80px' }}
          disabled={disabled}
          value={zoneId}
          onChange={onZoneChange}
        >
          {zones.map(zone => (
            <Select.Option key={zone.id} value={zone.id}>
              {zone.name}
            </Select.Option>
          ))}
        </Select>
      </Table.Td>
      <Table.Td>
        <InputNumber
          id="pack-item_quantity"
          min={0}
          max={remainingToPack}
          value={quantity}
          onChange={onQuantityChange}
          disabled={disabled}
        />
      </Table.Td>
      <Table.Td>
        <RequestButton
          id="pack-item_submit"
          onSuccess={() => {
            onQuantityChange(0)
            onZoneChange(null)
            refresh()
          }}
          url={`/parcels/${parcelId}/items`}
          method="post"
          type="primary"
          content="Pakuj"
          disabled={!quantity || !zoneId}
          payload={{
            saleId,
            sku,
            zoneId,
            quantity,
          }}
          title="Na pewno?"
        />
      </Table.Td>
    </Table.Tr>
  )
}
