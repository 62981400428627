import React from 'react'
import { Paper } from 'components'
import { Input, InputNumber, Typography, Select } from 'antd'
import { Spacing } from 'components/Spacing/Spacing'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { formatCurrency } from 'utils/formatters'

export class ReceivePayment extends React.Component {
  state = {
    value: '',
    paymentId: '',
    type: 'cash',
  }

  handleValueChange = value => {
    this.setState({
      value,
    })
  }

  handlePaymentIdChange = e => {
    this.setState({
      paymentId: e.target.value,
    })
  }

  handleTypeChange = type => {
    this.setState({
      type,
    })
  }

  render() {
    const { sale, refresh } = this.props
    const { value, paymentId, type } = this.state
    return (
      <Paper
        title={`Przyjmij płatność w ${sale.currency}`}
        id="sale-receive-payment"
      >
        <InputNumber
          style={{ width: '30%' }}
          value={value}
          placeholder={sale.currency}
          id="sale-receive-payment_value"
          min={0}
          onChange={this.handleValueChange}
          formatter={value => value.replace(/\B(?=(\d{2}$))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
        />
        <Spacing ml="0.6rem" display="inline-block">
          <Input
            id="sale-receive-payment_paymentId"
            placeholder="Unikalne ID"
            onChange={this.handlePaymentIdChange}
            value={paymentId}
          />
        </Spacing>
        <Spacing ml="0.6rem" display="inline-block" style={{ minWidth: '23%' }}>
          <Select
            value={type}
            onChange={this.handleTypeChange}
            style={{ width: '100%' }}
            id="sale-receive-payment_type"
          >
            <Select.Option value="cash">Gotówka</Select.Option>
            <Select.Option value="vipps">Vipps Bedrift</Select.Option>
            <Select.Option value="stripe">Stripe</Select.Option>
            <Select.Option value="rounding">Zaokrąglenie</Select.Option>
            <Select.Option value="transfer">
              Przelew (Vipps privat)
            </Select.Option>
          </Select>
        </Spacing>
        <ResourceProvider
          url={`/orders/${sale.id}`}
          render={({ data }) => {
            if (!data) return null
            return (
              <>
                <Spacing ml="0.6rem" display="inline-block">
                  <RequestButton
                    onSuccess={() => {
                      this.handleValueChange(0)
                      refresh()
                    }}
                    disabled={!value}
                    url={`/payments/${sale.id}`}
                    method="post"
                    id="sale-receive-payment_receive-payment-button"
                    payload={{
                      value,
                      paymentId,
                      currency: sale.currency,
                      type,
                    }}
                    type={
                      value === data.paymentPendingAmount ? 'primary' : 'danger'
                    }
                    content="Przyjmij ręcznie"
                    ghost
                  />
                </Spacing>
                {value > data.paymentPendingAmount && (
                  <div>
                    <Typography.Text type="warning">
                      Uwaga! Wprowadzona wartość przekracza kwotę pozostałą do
                      zapłaty!
                    </Typography.Text>
                  </div>
                )}
                {data.paymentPendingAmount === 0 && (
                  <div>
                    <Typography.Text type="secondary">
                      Zamówienie zostało w pełni opłacone
                    </Typography.Text>
                  </div>
                )}
                {data.paymentTotal > data.summary.brutto && (
                  <div>
                    <Typography.Text type="warning">
                      Uwaga! Zamówienie zostało nadpłacone o{' '}
                      {formatCurrency(
                        data.paymentTotal - data.summary.brutto,
                        data.currency
                      )}
                    </Typography.Text>
                  </div>
                )}
              </>
            )
          }}
        />
      </Paper>
    )
  }
}
