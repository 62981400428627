import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Link } from 'react-router-dom'
import { Row, Col, Table, Button } from 'antd'
import { PlusOutlined, WarningOutlined } from '@ant-design/icons'
import { TableSearch } from 'components/TableSearch/TableSearch'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { PurchaseOrderStatus } from 'components/Status/Status'
import { formatCurrency, formatDateTime } from 'utils/formatters'

const getColumns = refresh => [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    key: 'sku',
    render: (id, item) => (
      <>
        <Link to={`/purchase-orders/${id}`}>{item.number.toString().padStart(4, 0).slice(0, 8)}</Link>{' '}
        {item.isFlagged && <WarningOutlined style={{ color: 'red' }} />}
      </>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: status => <PurchaseOrderStatus status={status} />,
  },
  {
    title: 'Dostawca',
    dataIndex: 'supplierName',
  },
  {
    title: 'Transport',
    dataIndex: 'transportNumber',
    render: (transportNumber) => transportNumber ? transportNumber.toString().padStart(4, 0) : '-',
  },
  {
    title: 'Ilość',
    dataIndex: 'items',
    render: items => items.length,
  },
  {
    title: 'Data dostawy',
    dataIndex: 'deliveryExpectedAt',
    render: value => formatDateTime(value),
  },
  {
    title: 'Zamówione',
    dataIndex: 'orderedAt',
    render: value => formatDateTime(value),
  },
  {
    title: 'Dostarczono',
    dataIndex: 'deliveredAt',
    render: value => formatDateTime(value),
  },
  {
    title: 'Rozpakowano',
    dataIndex: 'unpackedAt',
    render: value => formatDateTime(value),
  },
  {
    title: 'Koszt dostawy',
    dataIndex: 'deliveryCost',
    render: value => formatCurrency(value, 'PLN'),
  },
]

export const PurchaseOrderList = ({ history }) => (
  <MainLayout header="Zlecenia zaopatrzenia" minWidth>
    <ResourceProvider
      v2
      url="/purchase-orders"
      pagination
      encodePageNumber
      responseNormalizer={items =>
        items.map((item, index) => ({ ...item, index }))
      }
      sorting
      render={({
        data,
        paginationParams,
        loading,
        onChange,
        refresh,
        onFiltersChange,
        filterParams,
      }) => (
        <>
          <Row style={{ marginBottom: '3rem' }}>
            <Col md={12}>
              <TableSearch
                onFiltersChange={onFiltersChange}
                filterParams={filterParams}
                options={{
                  query: 'Wyszukiwarka',
                }}
              />
            </Col>
            <Col md={12} style={{ textAlign: 'right' }}>
              <Button.Group>
                <Button
                  id="purchase-orders-link"
                  type="primary"
                  onClick={() => {
                    history.push('/purchase-orders/create')
                  }}
                >
                  <PlusOutlined />
                  Dodaj
                </Button>
              </Button.Group>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {console.log(data)}
              <Row>
                <Col md={24}>
                  <Table
                    rowKey="index"
                    columns={getColumns(refresh)}
                    dataSource={data}
                    pagination={{
                      ...paginationParams,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                    onChange={onChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    />
  </MainLayout>
)
