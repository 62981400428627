import React from 'react'
import { Tag } from 'antd'

export const SaleStatus = ({ status }) => {
  switch (status) {
    case 'INITIALIZED':
      return <Tag>Wersja robocza</Tag>
    case 'ACTIVE':
      return <Tag color="#108ee9">Koszyk</Tag>
    case 'ORDERED':
      return <Tag color="#fadb14">Złożone</Tag>
    case 'ACCEPTED':
      return <Tag color="#faad14">Zaakceptowane</Tag>
    case 'CANCELLED':
      return <Tag color="#f50">Anulowane</Tag>
    case 'COMPLETED':
      return <Tag color="#87d068">Zakończone</Tag>
    default:
      return <Tag>{status || 'Brak danych'}</Tag>
  }
}

export const FulfillmentStatus = ({ status }) => {
  switch (status) {
    case 'INITIALIZED':
      return <Tag>Oczekujące</Tag>
    case 'PARTIAL':
      return <Tag color="#fadb14">Częściowe</Tag>
    case 'COMPLETED':
      return <Tag color="#87d068">Zapakowane</Tag>
    default:
      return <Tag>{status || 'Brak danych'}</Tag>
  }
}

export const BillingStatus = ({ status }) => {
  switch (status) {
    case 'INITIALIZED':
      return <Tag>Brak faktur</Tag>
    case 'PARTIAL':
      return <Tag color="#fadb14">Cześciowe</Tag>
    case 'COMPLETED':
      return <Tag color="#87d068">Zaksięgowane</Tag>
    default:
      return <Tag>{status || 'Brak danych'}</Tag>
  }
}

export const ParcelStatus = ({ status }) => {
  switch (status) {
    case 'CREATED':
      return <Tag>Pusta</Tag>
    case 'PARTIALLY_PACKED':
      return <Tag color="#13c2c2">Częściowo zapakowana</Tag>
    case 'PACKED':
      return <Tag color="#108ee9">Zapakowana</Tag>
    case 'LADED':
      return <Tag color="#eb2f96">Załadowana</Tag>
    case 'SENT':
      return <Tag color="#faad14">Wysłana</Tag>
    case 'DELIVERED':
      return <Tag color="#87d068">Dostarczona</Tag>
    default:
      return <Tag>{status || 'Brak danych'}</Tag>
  }
}

export const PaymentStatus = ({ status }) => {
  switch (status) {
    case 'PENDING':
      return <Tag>Oczekujące</Tag>
    case 'PARTIAL':
      return <Tag color="#fadb14">Cześciowe</Tag>
    case 'PAID':
      return <Tag color="#87d068">Opłacone</Tag>
    default:
      return <Tag>{status || 'Brak danych'}</Tag>
  }
}

export const PurchaseOrderStatus = ({ status }) => {
  switch (status) {
    case 'DRAFT':
      return <Tag>Szablon</Tag>
    case 'ORDERED':
      return <Tag color="#fadb14">Wysłane</Tag>
    case 'DELIVERED':
      return <Tag color="#13c2c2">Dostarczone</Tag>
    case 'UNPACKED':
      return <Tag color="#87d068">Rozpakowane</Tag>
    default:
      return <Tag>{status || 'Brak danych'}</Tag>
  }
}
