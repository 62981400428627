import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { compose } from 'recompose'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { pathOr } from 'ramda'
import { Row, Col, Button, Table } from 'antd'
import { PlusOutlined, InboxOutlined } from '@ant-design/icons'
import { PrintQrLabel, StatsBadge } from 'components'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { TableSearch } from 'components/TableSearch/TableSearch'
import { truncate } from 'utils/formatters'

const columns = [
  {
    title: 'SKU',
    dataIndex: 'sku',
    sorter: true,
    key: 'sku',
    render: (value, record) => (
      <span>
        <Link to={`/products/${encodeURIComponent(value)}`}>{value}</Link> <PrintQrLabel value={value} />
        <div>
          <small style={{ marginRight: 3 }}>{record.ean}</small>
        </div>
      </span>
    ),
  },
  {
    title: 'Nazwa',
    key: 'name',
    render: record => (
      <>
        <div>
          <small>{truncate(record.nameNO, 40) || '-'}</small>
        </div>
      </>
    ),
  },
  {
    title: 'Producent',
    dataIndex: 'manufacturerCode',
    sorter: true,
    key: 'manufacturerCode',
  },
  {
    title: 'Statsy',
    render: record =>
      record.stockLevels.map((stockLevel, index) => (
        <StatsBadge
          key={index}
          count={stockLevel.quantity}
          icon={<InboxOutlined />}
          tooltip={`${pathOr(
            '-',
            ['zone', 'warehouse', 'name'],
            stockLevel
          )} / ${pathOr('-', ['zone', 'name'], stockLevel)}`}
        />
      )),
  },
  {
    render: product => (
      <Link to={`/products/${encodeURIComponent(product.sku)}`}>Zobacz</Link>
    ),
  },
]

export const ProductListUnwrapped = ({ history }) => (
  <MainLayout header="Produkty" minWidth>
    <ResourceProvider
      url="/products"
      pagination
      encodePageNumber
      sorting
      render={({
        data,
        paginationParams,
        filterParams,
        loading,
        onChange,
        onFiltersChange,
      }) => (
        <>
          <Row style={{ marginBottom: '3rem' }}>
            <Col md={12}>
              <TableSearch
                onFiltersChange={onFiltersChange}
                filterParams={filterParams}
                options={{
                  sku: 'Kod Towaru',
                  manufacturerCode: 'Producent',
                  finnId: 'Finn ID',
                  nameNO: 'Nazwa NO',
                  namePL: 'Nazwa PL',
                }}
              />
            </Col>
            <Col md={12} style={{ textAlign: 'right' }}>
              <Button.Group>
                <Button
                  type="primary"
                  onClick={() => {
                    history.push('/products/create')
                  }}
                >
                  <PlusOutlined />
                  Dodaj
                </Button>
              </Button.Group>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Table
                rowKey="sku"
                columns={columns}
                dataSource={data}
                pagination={{
                  ...paginationParams,
                  showSizeChanger: true,
                }}
                loading={loading}
                onChange={onChange}
              />
            </Col>
          </Row>
        </>
      )}
    />
  </MainLayout>
)

export const ProductList = compose(withRouter)(ProductListUnwrapped)
