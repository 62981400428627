import {
  isValid,
  format,
  parseISO,
  isToday,
  isYesterday,
  differenceInCalendarDays,
} from 'date-fns'

export const DATE_FORMAT = 'dd-MM-yyyy'
const DATETIME_FORMAT = 'dd-MM-yyyy HH:mm'
const TIME_FORMAT = 'HH:mm'

export const formatDateTime = (dateISOString, defaultValue = '-') => {
  const dateTime = parseISO(dateISOString)
  if (!isValid(dateTime)) return defaultValue
  if (isToday(dateTime)) {
    return `Dzisiaj ${format(dateTime, TIME_FORMAT)}`
  }
  if (isYesterday(dateTime)) {
    return `Wczoraj ${format(dateTime, TIME_FORMAT)}`
  }
  return format(dateTime, DATETIME_FORMAT)
}

export const formatDate = (dateISOString, defaultValue = '-') => {
  const date = parseISO(dateISOString)
  if (!isValid(date)) return defaultValue
  return format(date, DATE_FORMAT)
}

export const formatDeadline = (dateISOString, defaultValue = '-') => {
  const date = parseISO(dateISOString)
  const now = new Date()
  if (!isValid(date)) return defaultValue
  if (isToday(date)) {
    return `Na dzisiaj`
  }
  if (isYesterday(date)) {
    return `Na wczoraj`
  }

  const days = differenceInCalendarDays(now, date)
  if (days > 0) {
    return `Opóźnienie ${days} dni`
  }
  return `Za ${Math.abs(days)} dni`
}

export const formatCurrency = (value, currency) => isNaN(value) ? '-' :
  (value / 100).toLocaleString('pl-PL', { style: 'currency', currency })

export const truncate = (string, length) =>
  (string || '').length > length ? `${string.slice(0, length - 2)}...` : string
