import React from 'react'
import { Input, Col, Row } from 'antd'
import { request } from 'api/request'
import { TextField, Table } from 'components'
import { Spacing } from 'components/Spacing/Spacing'
import { Link } from 'react-router-dom'

export class BillingSelector extends React.Component {
  state = {
    saleId: '',
  }

  handleChange = e => {
    this.setState({ saleId: e.target.value })
  }

  handleSearch = async saleId => {
    try {
      const response = await request.get(`/billings/${saleId}`)
      this.props.addBilling(response.data.data)
      this.setState({ saleId: '' })
    } catch {}
  }

  render() {
    const { billings } = this.props
    return (
      <div>
        <Spacing mb="1rem">
          <Row>
            <Col md={12} sm={24}>
              <Input.Search
                placeholder="Wpisz numer sprzedaży"
                onSearch={this.handleSearch}
                enterButton
                value={this.state.saleId}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        </Spacing>
        <Table>
          <Table.Body>
            <Table.Tr>
              <Table.Th>Numer</Table.Th>
              <Table.Th>Status fakturowania</Table.Th>
              <Table.Th>Faktury</Table.Th>
              <Table.Th>Zamówionych sku</Table.Th>
            </Table.Tr>
            {billings.map(billing => (
              <Table.Tr key={billing.id}>
                <Table.Td>
                  <Link to={`/sales/${billing.id}`}>{billing.number}</Link>
                </Table.Td>
                <Table.Td>
                  <TextField mb="0" value={billing.status} />
                </Table.Td>
                <Table.Td>
                  <TextField
                    mb="0"
                    value={billing.invoices.map(invoice => (
                      <Spacing key={invoice.id} display="inline-block" mr="3px">
                        <Link to={`/sale-invoices/${invoice.id}`}>
                          {invoice.id.slice(-8)}
                        </Link>
                      </Spacing>
                    ))}
                  />
                </Table.Td>
                <Table.Td>
                  <TextField mb="0" value={billing.items.length || '0'} />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Body>
        </Table>
      </div>
    )
  }
}
