import React from 'react'
import styled from 'styled-components'
import { Paper, SLabel, Spacing, Align } from 'components'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Input, Tabs, Switch, Button } from 'antd'
import { CSVTable } from './CSVTable/CSVTable'

const TabNameInput = styled.input`
  border: none;
  padding: 0;
  outline: none;
  color: inherit;
  margin: 0;
`

export class ProductCsvTables extends React.Component {
  state = {
    tables: this.props.product.csvTables,
    pristineTablesString: JSON.stringify(this.props.product.csvTables),
    activeKey: null,
  }

  onSuccess = () => {
    this.props.refresh()
  }

  add = () => {
    const table = {
      name: `Table ${this.state.tables.length + 1}`,
      hasHeader: false,
      csv: '',
    }
    this.setState(state => ({
      tables: [...state.tables, table],
    }))
  }

  updateTable = index => newTable => {
    this.setState(state => ({
      tables: state.tables.map((table, idx) => {
        if (idx !== index) return table
        return {
          ...table,
          ...newTable,
        }
      }),
    }))
  }

  remove = key => {
    this.setState(state => ({
      tables: state.tables.filter((_, idx) => key !== idx.toString()),
    }))
  }

  onTabChange = activeKey => {
    this.setState({ activeKey })
  }

  onEdit = (targetKey, action) => {
    this[action](targetKey)
  }

  handleTableNameChange = index => e => {
    this.updateTable(index)({ name: e.target.value })
  }

  handleCSVChange = index => e => {
    this.updateTable(index)({ csv: e.target.value })
  }

  handleHeaderChange = index => hasHeader => {
    this.updateTable(index)({ hasHeader })
  }

  isEdited = () => {
    return this.state.pristineTablesString !== JSON.stringify(this.state.tables)
  }

  reset = () => {
    this.setState(state => ({
      tables: JSON.parse(state.pristineTablesString),
      activeKey: null,
    }))
  }

  render() {
    const { product } = this.props
    const { tables, activeKey } = this.state
    return (
      <Paper title="Opisy CSV">
        <FormProvider
          url={`/products/sku/${encodeURIComponent(product.sku)}`}
          method="patch"
          onSuccessMessage="Pomyślnie zmieniono parametry tabele CSV"
          onSuccess={this.onSuccess}
          render={({ handleSubmit }) => (
            <>
              <Tabs
                onChange={this.onTabChange}
                activeKey={activeKey}
                type="editable-card"
                onEdit={this.onEdit}
              >
                {tables.map((table, idx) => (
                  <Tabs.TabPane
                    tab={
                      <TabNameInput
                        type="text"
                        value={table.name}
                        onChange={this.handleTableNameChange(idx)}
                      />
                    }
                    key={idx}
                  >
                    <Spacing mb="1rem">
                      <SLabel>CSV</SLabel>
                      <Input.TextArea
                        value={table.csv}
                        onChange={this.handleCSVChange(idx)}
                      />
                    </Spacing>
                    <Spacing mb="1rem">
                      <SLabel>Zawiera nagłówek</SLabel>
                      <Switch
                        size="small"
                        checked={table.hasHeader}
                        onChange={this.handleHeaderChange(idx)}
                      />
                    </Spacing>
                    <SLabel>Podgląd</SLabel>
                    <CSVTable value={table.csv} hasHeader={table.hasHeader} />
                  </Tabs.TabPane>
                ))}
              </Tabs>
              <Align value="right">
                <Spacing display="inline-block" mr="0.2rem">
                  <Button onClick={this.reset}>Cofnij</Button>
                </Spacing>
                <Button
                  type="primary"
                  disabled={!this.isEdited()}
                  onClick={() => {
                    handleSubmit({
                      csvTables: this.state.tables,
                    })
                  }}
                >
                  Zapisz
                </Button>
              </Align>
            </>
          )}
        />
      </Paper>
    )
  }
}
