import {
  OPEN_STOCK_LEVELS_DRAWER,
  CLOSE_DRAWER,
  SIGNED_IN,
  TOKEN_CHECK,
  FETCH_PRODUCT,
  ADD_PRODUCT,
  FETCH_PRICE,
} from './constants'

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
})

export const openStockLevelsDrawer = params => ({
  type: OPEN_STOCK_LEVELS_DRAWER,
  params,
})

export const signedIn = data => ({
  type: SIGNED_IN,
  token: data.token,
  user: data.user,
})

export const tokenCheck = () => ({
  type: TOKEN_CHECK,
})

export const fetchProduct = sku => ({
  type: FETCH_PRODUCT,
  sku,
})

export const addProduct = product => ({
  type: ADD_PRODUCT,
  product,
})

export const fetchPrice = (sku, salesChannelCode, currency) => ({
  type: FETCH_PRICE,
  sku,
  salesChannelCode,
  currency,
})
