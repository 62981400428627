import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { withOnErrorRedirect } from 'hocs/withOnErrorRedirect'
import { ProductDetails } from './ProductDetails/ProductDetails'
import { Measurements } from './Measurements/Measurements'
import { StockLevelList } from './StockLevelList/StockLevelList'
import { ProductSalesPrices } from './ProductSalesPrices/ProductSalesPrices'
import { ProductPurchasePrices } from './ProductPurchasePrices/ProductPurchasePrices'
import { Fitments } from './Fitments/Fitments'
import { ProductImages } from './ProductImages/ProductImages'
import { ProductCategories } from './ProductCategories/ProductCategories'
import { ProductDescriptions } from './ProductDescriptions/ProductDescriptions'
import { VirtualAvailability } from './VirtualAvailability/VirtualAvailability'
import { ProductParams } from './ProductParams/ProductParams'
import { ProductCsvTables } from './ProductCsvTables/ProductCsvTables'
import { IsSoldOut } from './IsSoldOut/IsSoldOut'

class ProductShowUnwrapped extends React.Component {
  render() {
    const sku = decodeURIComponent(this.props.match.params.sku)

    return (
      <ResourceProvider
        url={`/products/sku/${encodeURIComponent(sku)}`}
        onError={this.props.onErrorRedirect}
        render={({ data, loading, refresh, refreshKey }) => (
          <MainLayout
            header={`SKU ${sku}`}
            isLoading={!data && loading}
            transparent
          >
            {data && (
              <Row>
                <Col xxl={12} xl={20} lg={24} xs={24}>
                  <Row type="flex" justify="space-between">
                    <Col lg={24} xs={24}>
                      <ProductDetails product={data} refresh={refresh} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <ProductDescriptions product={data} refresh={refresh} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <ProductParams product={data} refresh={refresh} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <ProductCsvTables
                        key={refreshKey}
                        product={data}
                        refresh={refresh}
                      />
                    </Col>
                    <Col lg={24} xs={24}>
                      <StockLevelList sku={sku} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <ProductSalesPrices sku={sku} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <ProductPurchasePrices sku={sku} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Fitments sku={sku} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <ProductCategories sku={sku} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Measurements sku={sku} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <ProductImages sku={sku} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <VirtualAvailability product={data} refresh={refresh} />
                    </Col>
                    <Col lg={24} xs={24}>
                      <IsSoldOut product={data} refresh={refresh} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </MainLayout>
        )}
      />
    )
  }
}

export const ProductShow = withOnErrorRedirect(ProductShowUnwrapped)
