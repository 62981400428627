import React from 'react'
import { Paper } from 'components'
import { withResource } from 'hocs/withResource'
import { AddPurchasePrice } from './AddPurchasePrice/AddPurchasePrice'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { PriceList } from './PriceList/PriceList'

const ProductPurchasePricesUnwrapped = ({ sku, suppliers }) => (
  <ResourceProvider
    url={`/products/purchase-prices/${encodeURIComponent(sku)}`}
    render={({ data, loading, refresh }) =>
      !loading && (
        <Paper title="Cenu zakupu">
          <PriceList
            prices={data}
            refresh={refresh}
            suppliers={suppliers.data || []}
          />
          <AddPurchasePrice
            sku={sku}
            suppliers={suppliers.data || []}
            refresh={refresh}
          />
        </Paper>
      )
    }
  />
)

export const ProductPurchasePrices = withResource({
  name: 'suppliers',
  url: 'suppliers?perPage=99999',
})(ProductPurchasePricesUnwrapped)
