import React from 'react'
import { connect } from 'react-redux'
import { selectPrice } from 'redux/selectors/priceSelectors'
import { fetchPrice } from 'redux/actions'
import { formatCurrency } from 'utils/formatters'

class SalesPriceUnwrapped extends React.Component {
  componentDidMount = () => {
    const { fetchPrice, price, sku, salesChannelCode, currency } = this.props
    if (price === undefined) {
      fetchPrice(sku, salesChannelCode, currency)
    }
  }

  multiply = (value, quantity) => Math.round(value * quantity)

  render() {
    const { price, field, quantity } = this.props
    if (price === undefined) {
      return '...'
    }
    if (!price) {
      return 'Brak ceny'
    }

    switch (field) {
      case 'netto':
        return formatCurrency(price.netto, price.currency)
      case 'brutto':
        return formatCurrency(price.brutto, price.currency)
      case 'vat':
        return formatCurrency(price.vat, price.currency)
      case 'vatRate':
        return formatCurrency(price.vatRate, price.currency)
      case 'totalNetto':
        return formatCurrency(
          this.multiply(price.netto, quantity),
          price.currency
        )
      case 'totalVat':
        return formatCurrency(
          this.multiply(price.vat, quantity),
          price.currency
        )
      case 'totalBrutto':
        return formatCurrency(
          this.multiply(price.brutto, quantity),
          price.currency
        )
      default:
        return '-'
    }
  }
}

SalesPriceUnwrapped.defaultProps = {
  quantity: 1,
}

export const SalesPrice = connect(
  (state, props) => ({
    price: selectPrice(state, props.sku),
  }),
  { fetchPrice }
)(SalesPriceUnwrapped)
