import React from 'react'
import PropTypes from 'prop-types'
import { Input, Checkbox } from 'antd'

const SEARCH_SCOPE_OPTIONS = [
  { label: 'Dokumenty', value: 'documents' },
  { label: 'Pozycje dokumentu', value: 'lineItems' },
]

export class DocumentsSearch extends React.Component {
  state = {
    query: '',
    searchScope: ['lineItems'],
  }

  handleChange = e => {
    this.setState({ query: e.target.value }, this.search)
  }

  onScopeChange = searchScope => {
    this.setState({ searchScope })
  }

  matchLegalEntity = legalEntity => {
    if (!legalEntity) return true
    const { query } = this.state
    return (
      legalEntity.name.includes(query) ||
      legalEntity.address.addressLine1.includes(query) ||
      legalEntity.address.addressLine2.includes(query) ||
      legalEntity.address.city.includes(query) ||
      legalEntity.address.postalCode.includes(query)
    )
  }

  matchDocument = document => {
    const { query } = this.state
    return (
      document.number.includes(query) ||
      this.matchLegalEntity(document.issuer) ||
      this.matchLegalEntity(document.recipient)
    )
  }

  matchLineItem = lineItem => {
    const { query } = this.state
    return (
      lineItem.number.includes(query) || lineItem.productNumber.includes(query)
    )
  }

  getFilteredDocuments = () => {
    if (!this.state.searchScope.includes('documents')) {
      return this.props.documents
    }
    return this.props.documents.filter(this.matchDocument)
  }

  getFilteredDocumentsByLineItems = documents => {
    if (!this.state.searchScope.includes('lineItems')) {
      return documents
    }
    return documents
      .map(document => ({
        ...document,
        lineItems: document.lineItems.filter(this.matchLineItem),
      }))
      .filter(document => document.lineItems.length > 0)
  }

  search = () => {
    const documents = this.getFilteredDocuments()
    const result = this.getFilteredDocumentsByLineItems(documents)
    this.props.onChange(result)
  }

  render() {
    const { placeholder, marginBottom } = this.props
    const { query, searchScope } = this.state

    return (
      <div style={{ marginBottom }}>
        <Input.Search
          placeholder={placeholder}
          value={query}
          onChange={this.handleChange}
        />
        <Checkbox.Group
          options={SEARCH_SCOPE_OPTIONS}
          value={searchScope}
          onChange={this.onScopeChange}
        />
      </div>
    )
  }
}

DocumentsSearch.defaultProps = {
  placeholder: 'Szukaj...',
  marginBottom: '1.5rem',
}

const legalEntityPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  address: PropTypes.shape({
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string,
    postalCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
})

const lineItemPropType = PropTypes.shape({
  number: PropTypes.string.isRequired,
  productNumber: PropTypes.string.isRequired,
})

DocumentsSearch.propTypes = {
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string.isRequired,
      issuer: legalEntityPropType.isRequired,
      recipient: legalEntityPropType.isRequired,
      lineItems: PropTypes.arrayOf(lineItemPropType).isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  marginBottom: PropTypes.string,
}
