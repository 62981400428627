import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { EventsLog } from 'components/EventsLog/EventsLog'
import { withOnErrorRedirect } from 'hocs/withOnErrorRedirect'
import { ParcelHeader } from './ParcelHeader/ParcelHeader'
import { Measurements } from './Measurements/Measurements'
import { Address } from './Address/Address'
import { MarkAsDelivered } from './MarkAsDelivered/MarkAsDelivered'
import { MarkAsSent } from './MarkAsSent/MarkAsSent'
import { SetShippingLabel } from './SetShippingLabel/SetShippingLabel'
import { ParcelItems } from './ParcelItems/ParcelItems'
import { PackItems } from './PackItems/PackItems'
import { DeleteParcel } from './DeleteParcel/DeleteParcel'
import { parcelStatus } from '../parcelStatus'
import { MarkAsPacked } from './MarkAsPacked/MarkAsPacked'

class ParcelShowUnwrapped extends React.Component {
  render() {
    const { id } = this.props.match.params
    return (
      <ResourceProvider
        url={`/parcels/${encodeURIComponent(id)}`}
        onError={this.props.onErrorRedirect}
        render={({ data: parcel, refresh, refreshKey, onRefresh }) => {
          const status = parcelStatus(parcel)
          return (
            <MainLayout
              header={`Paczka ${parcel ? parcel.id.slice(-8) : ''}`}
              isLoading={!parcel}
              transparent
            >
              {parcel && (
                <Row>
                  <Col xxl={12} xl={20} lg={24} xs={24}>
                    <Row type="flex" justify="space-between">
                      <Col lg={24} xs={24}>
                        <ParcelHeader parcel={parcel} />
                      </Col>
                      {status.isEqual(status.SENT) && (
                        <Col lg={24} xs={24}>
                          <MarkAsDelivered parcel={parcel} refresh={refresh} />
                        </Col>
                      )}
                      {status.isEqual(status.PACKED) && (
                        <Col lg={24} xs={24}>
                          <MarkAsSent parcel={parcel} refresh={refresh} />
                        </Col>
                      )}
                      {(status.isEqual(status.PACKED) ||
                        status.isEqual(status.PARTIALLY_PACKED)) && (
                        <Col lg={24} xs={24}>
                          <MarkAsPacked parcel={parcel} refresh={refresh} />
                        </Col>
                      )}
                      {status.isBefore(status.PACKED) && (
                        <Col lg={24} xs={24}>
                          <PackItems
                            parcel={parcel}
                            refresh={refresh}
                            onRefresh={onRefresh}
                          />
                        </Col>
                      )}
                      <Col lg={24} xs={24}>
                        <ParcelItems
                          key={refreshKey}
                          disabled={status.isNot(status.PARTIALLY_PACKED)}
                          parcel={parcel}
                          refresh={refresh}
                        />
                      </Col>
                      <Col lg={24} xs={24}>
                        <SetShippingLabel
                          refresh={refresh}
                          parcel={parcel}
                          key={refreshKey}
                        />
                      </Col>
                      {/* <Col lg={24} xs={24}>
                        <GenerateShippingLabel
                          refresh={refresh}
                          parcel={parcel}
                        />
                      </Col> */}
                      <Col lg={24} xs={24}>
                        <Address
                          key={refreshKey}
                          refresh={refresh}
                          parcel={parcel}
                          disabled={status.isEqual(status.DELIVERED)}
                        />
                      </Col>
                      <Col lg={24} xs={24}>
                        {status.isAfter(status.PARTIALLY_PACKED) && (
                          <Measurements
                            key={refreshKey}
                            refresh={refresh}
                            parcel={parcel}
                          />
                        )}
                      </Col>
                      <Col lg={24} xs={24}>
                        <EventsLog events={parcel.events} />
                      </Col>
                      <Col lg={24} xs={24}>
                        <DeleteParcel
                          history={this.props.history}
                          refresh={refresh}
                          parcel={parcel}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </MainLayout>
          )
        }}
      />
    )
  }
}

export const ParcelShow = withOnErrorRedirect(ParcelShowUnwrapped)
