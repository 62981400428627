import React from 'react'
import { Typography, Button, Divider } from 'antd'
import { TextField, Paper } from 'components'
import { formatDateTime, formatCurrency } from 'utils/formatters'
import { TransportEditForm } from './TransportEditForm/TransportEditForm'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { EditOutlined } from '@ant-design/icons'

export class TransportHeader extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(state => ({ editMode: !state.editMode }))
  }

  render() {
    const { transport, refresh } = this.props
    const { editMode } = this.state
    return (
      <Paper
        title="Informacje"
        extra={
          <Button
            icon={<EditOutlined />}
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        <TextField
          label="ID"
          value={
            <Typography.Text id="transport_header_id" copyable>
              {transport.id}
            </Typography.Text>
          }
        />
        <TextField
          label="Numer"
          value={
            <Typography.Text id="transport_header_number" copyable>
              {transport.number.toString().padStart(4, '0')}
            </Typography.Text>
          }
        />
        <Divider />
        {!editMode && (
          <>
            <TextField
              label="Numer faktury"
              value={transport.invoiceNumber}
            />
            <TextField
              label="Koszt"
              value={formatCurrency(transport.cost, 'PLN')}
            />
            <TextField
              label="Kierowca"
              value={transport.driver}
            />
            <TextField
              label="Samochód"
              value={transport.vehicle}
            />
            <TextField
              label="Agencja celna"
              value={transport.customsAgency}
            />
            <TextField
              label="Planowana data wyjazdu"
              value={formatDateTime(transport.departureAt)}
            />
            <TextField
              label="Data wyjazdu"
              value={formatDateTime(transport.departuredAt)}
            />
            <TextField
              label="Data przyjazdu"
              value={formatDateTime(transport.arrivedAt)}
            />
          </>
        )}
        {editMode && (
          <FormProvider
            v2
            url={`/transports/${transport.id}`}
            method="put"
            onSuccessMessage="Pomyślnie zaktualizowano dane transportu"
            onSuccess={() => {
              this.toggleMode()
              this.props.refresh()
            }}
            render={({ handleSubmit, updating }) => (
              <TransportEditForm
                transport={transport}
                onSubmit={handleSubmit}
                updating={updating}
                refresh={refresh}
              />
            )}
          />
        )}
      </Paper>
    )
  }
}
