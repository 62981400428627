import React from 'react'
import styled from 'styled-components'
import { parseISO, addDays } from 'date-fns'
import { pathOr, path } from 'ramda'
import { withRouter, Link } from 'react-router-dom'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col, Button, Table as AntTable, Popover, List } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import {
  Align,
  Table,
  SkuStockLevel,
  ProductNumber,
  TableSearch,
  PrintQr,
} from 'components'
import {
  formatDateTime,
  formatDate,
  formatCurrency,
  formatDeadline,
} from 'utils/formatters'
import { getBrutto } from 'utils/prices'
import { SaleFilters } from './SaleFilters/SaleFilters'
import { SaleToggleFilters } from './SaleToggleFilters/SaleToggleFilters'
import { PlusOutlined, CheckCircleOutlined } from '@ant-design/icons'
import {
  Spacing,
  SaleStatus,
  FulfillmentStatus,
  BillingStatus,
  PaymentStatus,
  Copy,
} from 'components'
import { BLUE, RED, GREEN } from 'layouts/colors'
import { displayPrice } from 'utils/prices'

const SpanBlue = styled.span`
  color: ${BLUE};
  font-size: 10px;
  margin-right: 3px;
`

const SpanRed = styled.span`
  color: ${RED};
  font-size: 10px;
`

const Nowrap = styled.div`
  white-space: nowrap;
`

const formatEvents = events => {
  return events.map((event, index) => {
    switch (event.name) {
      case 'CART_ITEMS_SET':
        return {
          name: index === 0 ? 'Utworzono koszyk' : 'Zaktualizowano koszyk',
          timestamp: event.timestamp,
        }
      case 'ORDER_PLACED':
        return {
          name: 'Utworzono zamówienie',
          timestamp: event.timestamp,
        }
      case 'STRIPE_CHECKOUT_ACCEPTED':
        return {
          name: 'Otrzymano przelew Stripe',
          timestamp: event.timestamp,
        }
      case 'VIPPS_PAYMENT_CAPTURE_ACCEPTED':
        return {
          name: 'Otrzymano przelew Vipps',
          timestamp: event.timestamp,
        }
      case 'PAYMENT_RECEIVED':
        return {
          name: 'Wprowadzono płatność',
          timestamp: event.timestamp,
        }
      case 'ORDER_ACCEPTED':
        return {
          name: 'Zaakceptowano zamówienie',
          timestamp: event.timestamp,
        }
      case 'ORDER_CANCELLED':
        return {
          name: 'Anulowano zamówienie',
          timestamp: event.timestamp,
        }
      case 'SALE_COMPLETED':
        return {
          name: 'Zakończono',
          timestamp: event.timestamp,
        }
      default:
        return {
          name: event.name,
          timestamp: event.timestamp,
        }
    }
  })
}

const getTimesLog = sale => (
  <List
    dataSource={formatEvents(sale.events)}
    style={{ width: 300, maxHeight: 500, overflowY: 'scroll' }}
    size="small"
    renderItem={event => (
      <List.Item>
        <List.Item.Meta
          title={event.name}
          description={formatDateTime(event.timestamp)}
        />
      </List.Item>
    )}
  />
)

const Pointer = styled.div`
  cursor: pointer;
`

const columns = [
  {
    title: 'Number',
    dataIndex: 'number',
    key: 'number',
    render: (_, sale) => (
      <Nowrap>
        <Pointer>
          <Popover title="Logi" content={getTimesLog(sale)}>
            <small>{formatDateTime(sale.updatedAt)}</small>
          </Popover>
        </Pointer>
        <Link to={`/sales/${sale.id}`}>{sale.number}</Link>{' '}
        <Copy value={sale.id} />
        <PrintQr value={sale.id} />
      </Nowrap>
    ),
  },
  {
    title: 'Poz',
    dataIndex: 'skuCount',
    render: (_, sale) => sale.skuCount || '-',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (_, sale) => <SaleStatus status={sale.status} />,
  },
  {
    title: 'Status płatności',
    dataIndex: 'paymentStatus',
    render: (_, sale) => (
      <>
        <PaymentStatus status={sale.paymentStatus} />
        <div>
          {!!sale.paymentTotal && (
            <SpanBlue>{displayPrice(sale.paymentTotal)}</SpanBlue>
          )}
          {!!sale.paymentPendingAmount && (
            <SpanRed>{displayPrice(sale.paymentPendingAmount)}</SpanRed>
          )}
        </div>
      </>
    ),
  },
  {
    title: 'Status pakowania',
    dataIndex: 'fulfillmentStatus',
    render: (_, sale) => (
      <>
        <FulfillmentStatus status={sale.fulfillmentStatus} />
        <div>
          {(sale.parcels || []).map(parcel => (
            <Spacing key={parcel.id} display="inline-block" mr="0.3rem">
              <Link to={`/parcels/${parcel.id}`}>
                <small>{parcel.id.slice(-5)}</small>
              </Link>
            </Spacing>
          ))}
        </div>
      </>
    ),
  },
  {
    title: 'Status fakturowania',
    dataIndex: 'billingStatus',
    render: (_, sale) => (
      <>
        <BillingStatus status={sale.billingStatus} />
        <div>
          {(sale.invoices || []).map(invoice => (
            <Spacing key={invoice.id} display="inline-block" mr="0.2rem">
              <Link to={`/sale-invoices/${invoice.id}`}>
                <small>{invoice.id.slice(-6)}</small>
              </Link>
            </Spacing>
          ))}
        </div>
      </>
    ),
  },
  {
    title: 'Klient',
    render: record => (
      <React.Fragment>
        <div>
          <strong>{pathOr('', ['deliveryAddress', 'name'], record)}</strong>
        </div>
        <div>
          <small>
            {pathOr('-', ['deliveryAddress', 'addressLine1'], record)}{' '}
            {pathOr('', ['deliveryAddress', 'addressLine2'], record)}{' '}
            {pathOr('', ['deliveryAddress', 'postalCode'], record)}{' '}
            {pathOr('', ['deliveryAddress', 'city'], record)}
          </small>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: 'Kontakt',
    render: record => (
      <React.Fragment>
        <div>{pathOr('', ['billingAddress', 'telephone'], record)}</div>
        <div>
          <small>{pathOr('', ['emailAddress'], record)}</small>
        </div>
      </React.Fragment>
    ),
  },
]

const getPlacedAt = sale =>
  path(
    ['timestamp'],
    sale.events.find(event => event.name === 'ORDER_PLACED')
  )

const getPackedQuantity = (sale, sku) =>
  sale.parcels
    .map(parcel =>
      parcel.items
        .filter(item => item.sku === sku && item.saleId === sale.id)
        .reduce((sum, item) => item.quantity + sum, 0)
    )
    .reduce((sum, quantity) => quantity + sum, 0)

const getDeadline = (placedAt, availabilityWhenSold) => {
  const date = parseISO(placedAt)
  if (availabilityWhenSold === 0) {
    return addDays(date, 10)
  }
  return addDays(date, 1)
}

const expandedRowRender = sale => {
  const placedAt = getPlacedAt(sale)
  if (!placedAt) return

  const items = sale.items.map(item => {
    const packedQuantity = getPackedQuantity(sale, item.sku)
    return {
      ...item,
      remainingToPack: item.quantity - packedQuantity,
      packedQuantity,
      deadline: getDeadline(placedAt, item.availability).toISOString(),
    }
  })

  return (
    <Table>
      <Table.Body>
        <Table.Tr>
          <Table.Th>SKU</Table.Th>
          <Table.Th width="18%">Cena</Table.Th>
          <Table.Th width="12%">Zamówiono</Table.Th>
          <Table.Th width="15%">Do zapakowania</Table.Th>
          <Table.Th width="12%">Na magazynie</Table.Th>
          <Table.Th width="15%">Deadline</Table.Th>
        </Table.Tr>
        {items.map(item => (
          <Table.Tr key={item.sku}>
            <Table.Td>
              <ProductNumber sku={item.sku} />
            </Table.Td>
            <Table.Td>
              {formatCurrency(
                getBrutto(item.netto, item.vatRate),
                sale.currency
              )}
            </Table.Td>
            <SkuStockLevel
              sku={item.sku}
              render={({ loading, sum, renderWidget }) => {
                if (loading) {
                  return <Table.Td colSpan={4} />
                }
                const isPackable =
                  item.remainingToPack > 0 && sum >= item.remainingToPack
                return (
                  <>
                    <Table.Td>{item.quantity}</Table.Td>
                    <Table.Td>
                      {item.remainingToPack}{' '}
                      {isPackable && (
                        <CheckCircleOutlined style={{ color: GREEN }} />
                      )}
                    </Table.Td>
                    <Table.Td>{renderWidget()}</Table.Td>
                    <Table.Td>
                      {item.remainingToPack > 0
                        ? formatDeadline(item.deadline)
                        : formatDate(item.deadline)}
                    </Table.Td>
                  </>
                )
              }}
            />
          </Table.Tr>
        ))}
      </Table.Body>
    </Table>
  )
}
const SalesListUnwrapped = ({ history }) => (
  <MainLayout header="Sprzedaż" minWidth>
    <ResourceProvider
      url="/sales"
      filterParams={{
        status: 'ORDERED,ACCEPTED',
      }}
      encodePageNumber
      encodeFilters
      pagination
      render={({
        data,
        paginationParams,
        loading,
        onChange,
        onFiltersChange,
        filterParams,
      }) => (
        <>
          <Row style={{ marginBottom: '3rem' }}>
            <Col md={12}>
              <TableSearch
                onFiltersChange={onFiltersChange}
                filterParams={filterParams}
                options={{
                  emailAddress: 'E-mail',
                  sequenceNumber: 'Numer',
                }}
              />
            </Col>
            <Col span={12}>
              <Align value="right">
                <Button
                  type="primary"
                  id="sales-create-link"
                  onClick={() => {
                    history.push('/sales/create')
                  }}
                >
                  <PlusOutlined />
                  Dodaj
                </Button>
              </Align>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Spacing mb="1rem">
                <SaleFilters
                  onFiltersChange={onFiltersChange}
                  filterParams={filterParams}
                />
              </Spacing>
              <Spacing mb="3rem">
                <SaleToggleFilters
                  onFiltersChange={onFiltersChange}
                  filterParams={filterParams}
                />
              </Spacing>
              <Spacing mb="1rem">
                <Button
                  size="small"
                  onClick={() => {
                    document
                      .querySelectorAll('#sales-table .ant-table-row')
                      .forEach((item, idx) => {
                        setTimeout(() => {
                          item.click()
                        }, idx * 10)
                      })
                  }}
                >
                  Rozwiń wszystkie
                </Button>
              </Spacing>
              <AntTable
                id="sales-table"
                columns={columns}
                dataSource={data}
                rowKey="id"
                pagination={{
                  ...paginationParams,
                  showSizeChanger: true,
                }}
                loading={loading}
                onChange={onChange}
                expandable={{ expandRowByClick: true, expandedRowRender }}
              />
            </Col>
          </Row>
        </>
      )}
    />
  </MainLayout>
)

export const SalesList = withRouter(SalesListUnwrapped)
