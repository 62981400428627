import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { ProductNumber, RequestButton } from 'components'
import { formatDateTime } from 'utils/formatters'
import { Table, Row, Col } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'

const getColumns = refresh => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
    render: value => <ProductNumber sku={value} />,
  },
  {
    title: 'Ilość',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'Komentarz',
    dataIndex: 'comment',
    key: 'comment',
  },
  {
    title: 'Dodano',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: createdAt => formatDateTime(createdAt),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'actions',
    render: id => (
      <RequestButton
        method="delete"
        size="small"
        url={`replenishments/${id}`}
        title={`Czy na pewno chcesz usunąć wpis nr ${id}?`}
        content="Usuń"
        onSuccess={refresh}
      />
    ),
  },
]

export const Replenishments = () => (
  <MainLayout header="Historia zleceń zaopatrzenia" minWidth>
    <Row>
      <Col span={24}>
        <ResourceProvider
          url="/replenishments"
          pagination
          encodePageNumber
          render={({ data, loading, paginationParams, onChange, refresh }) => (
            <Table
              rowKey="id"
              columns={getColumns(refresh)}
              dataSource={data}
              loading={loading}
              pagination={{
                ...paginationParams,
                showSizeChanger: true,
              }}
              onChange={onChange}
            />
          )}
        />
      </Col>
    </Row>
  </MainLayout>
)
