import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { Grid, TextField } from 'components'

export const ChannelList = () => (
  <MainLayout header="Kanały sprzedaży">
    <ResourceProvider
      url="/sales-channel"
      render={({ data, loading }) =>
        !loading &&
        data && (
          <Grid>
            {data.map(channel => (
              <Grid.Row key={channel.code}>
                <Grid.Col span={4}>
                  <Grid.Item>
                    <TextField label="Kod" value={channel.code} />
                  </Grid.Item>
                </Grid.Col>
                <Grid.Col span={8}>
                  <Grid.Item>
                    <Grid.Row>
                      <Grid.Col span={6}>
                        <Grid.Item>
                          <TextField label="Otwartch koszyków" value="-" />
                          <TextField label="Zamówień" value="-" />
                          <TextField
                            label="Zamówień w trakcie realizacji"
                            value="-"
                          />
                          <TextField label="Klientów online" value="-" />
                        </Grid.Item>
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <Grid.Item>
                          <TextField label="Odwiedzin dzisiaj" value="-" />
                          <TextField
                            label="Liczba wystawionych produktów"
                            value="-"
                          />
                          <TextField
                            label="Wartość zamówień w tym miesiącu"
                            value="-"
                          />
                          <TextField
                            label="Wartość zamówień łącznie"
                            value="-"
                          />
                        </Grid.Item>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Item>
                </Grid.Col>
              </Grid.Row>
            ))}
          </Grid>
        )
      }
    />
  </MainLayout>
)
