import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, InputNumber, Button, Typography } from 'antd'
import { TextField } from 'components'

export class UpdateMeasurementsForm extends React.Component {
  state = {
    height: this.props.data.height || 0,
    width: this.props.data.width || 0,
    length: this.props.data.length || 0,
    weight: this.props.data.weight || 0,
    error: '',
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    })
  }

  handleClick = () => {
    const error = this.getError()
    this.setState({ error })
    if (!error) {
      this.props.onSubmit(this.getFormData())
    }
  }

  getFormData = () => {
    return {
      height: this.state.height,
      width: this.state.width,
      length: this.state.length,
      weight: this.state.weight,
    }
  }

  getError = () => {
    // eslint-disable-next-line no-unused-vars
    for (const val of Object.values(this.getFormData())) {
      if (!val) {
        return 'Wszystkie wartości muszą być powyżej zera'
      }
    }
    return ''
  }

  render() {
    const { height, width, length, weight, error } = this.state
    return (
      <>
        <Row>
          <Col lg={12} xs={12}>
            <TextField
              label="Wysokość (mm)"
              value={
                <InputNumber
                  size="small"
                  min={0}
                  step={50}
                  value={height}
                  onChange={this.handleChange('height')}
                />
              }
            />
            <TextField
              label="Szerokość (mm)"
              value={
                <InputNumber
                  size="small"
                  min={0}
                  step={50}
                  value={width}
                  onChange={this.handleChange('width')}
                />
              }
            />
            <TextField
              label="Długość (mm)"
              value={
                <InputNumber
                  size="small"
                  min={0}
                  step={50}
                  value={length}
                  onChange={this.handleChange('length')}
                />
              }
            />
          </Col>
          <Col lg={12} xs={12}>
            <TextField
              label="Waga (g)"
              value={
                <InputNumber
                  size="small"
                  min={0}
                  step={50}
                  value={weight}
                  onChange={this.handleChange('weight')}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={18} xs={18}>
            {error && (
              <Typography.Text type="danger">
                <small>{error}</small>
              </Typography.Text>
            )}
          </Col>
          <Col lg={6} xs={6} style={{ textAlign: 'right' }}>
            <Button
              size="small"
              type="primary"
              onClick={this.handleClick}
              loading={this.props.updating}
              disabled={this.props.updating}
            >
              Zapisz
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

UpdateMeasurementsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
