export const OPEN_STOCK_LEVELS_DRAWER = 'OPEN_STOCK_LEVELS_DRAWER'
export const CLOSE_DRAWER = 'CLOSE_DRAWER'
export const DrawerTypes = {
  StockLevels: 'StockLevels',
}

export const SIGNED_IN = 'SIGNED_IN'
export const TOKEN_CHECK = 'TOKEN_CHECK'

export const FETCH_PRODUCT = 'FETCH_PRODUCT'
export const ADD_PRODUCT = 'ADD_PRODUCT'

export const ADD_PRICES = 'ADD_PRICES'
export const FETCH_PRICE = 'FETCH_PRICE'
export const QUEUE_PRICE_REQUEST = 'QUEUE_PRICE_REQUEST'
export const CLEAR_PRICE_REQUEST_QUEUE = 'CLEAR_PRICE_REQUEST_QUEUE'
