import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { TextField, Table } from 'components'
import { displayPrice } from 'utils/prices'
import { formatDateTime } from 'utils/formatters'

export const VippsDetails = ({ saleId, paymentId }) => (
  <ResourceProvider
    url={`/payments/${saleId}/${paymentId}/vipps/details`}
    render={({ data, loading }) => {
      if (loading) return 'Loading...'
      return (
        <>
          <TextField
            label="ID"
            id="payment_id"
            value={<Typography.Text copyable>{data.orderId}</Typography.Text>}
          />
          {data.transactionSummary && (
            <>
              <TextField
                label="Ściągnięto"
                id="captured_amount"
                value={displayPrice(data.transactionSummary.capturedAmount)}
              />
              <TextField
                label="Pozostało do ściągnięcia"
                id="remaining_to_capture_amount"
                value={displayPrice(
                  data.transactionSummary.remainingAmountToCapture
                )}
              />
              <TextField
                label="Zwrócono"
                id="refund_amount"
                value={displayPrice(data.transactionSummary.refundedAmount)}
              />
              <TextField
                label="Pozostało do zwrócenia"
                id="remaining_to_refund_amount"
                value={displayPrice(
                  data.transactionSummary.remainingAmountToRefund
                )}
              />
              <TextField
                label="Bank ID"
                id="bank_id"
                value={data.transactionSummary.bankIdentificationNumber}
              />
            </>
          )}
          <Table>
            <Table.Body>
              <Table.Tr>
                <Table.Th>Operacja</Table.Th>
                <Table.Th>Wartość</Table.Th>
                <Table.Th>Data</Table.Th>
              </Table.Tr>
              {data.transactionLogHistory.map((logItem, idx) => (
                <Table.Tr key={idx}>
                  <Table.Td title={logItem.requestId}>
                    {logItem.operation}
                    {logItem.operationSuccess && (
                      <CheckOutlined
                        style={{ marginLeft: '0.4rem', color: '#52c41a' }}
                      />
                    )}
                  </Table.Td>
                  <Table.Td>{displayPrice(logItem.amount)}</Table.Td>
                  <Table.Td>{formatDateTime(logItem.timeStamp)}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Body>
          </Table>
        </>
      )
    }}
  />
)

VippsDetails.propTypes = {
  saleId: PropTypes.string.isRequired,
  paymentId: PropTypes.string.isRequired,
}
