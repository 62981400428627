import React from 'react';
import { Layout, Row, Col } from 'antd';
import { withRouter } from 'react-router'
import { LoginForm } from './LoginForm/LoginForm'

const styles = {
  layout: {
    minHeight: '100vh',
  },
  content: {
    margin: '24px 16px',
    padding: '4rem',
    background: '#fff',
    minHeight: 'calc(100vh - 48px)',
  }
}

class LoginUnwrapped extends React.Component {

  render() {
    return (
      <Layout>
        <Layout.Content style={styles.content}>
          <Row>
            <Col lg={2} md={0}/>
            <Col lg={8} md={12} sm={24}>
              <h1>Altbra Sp. z o.o.</h1>
              <p>Dystrybucja części samochodowych</p><br/>
              <p>
                ul. Jakuba Wejhera 3B / 114<br/>
                80-346 Gdańsk<br/>
                NIP 5842750313
              </p>
              <br/>
            </Col>
            <Col lg={8} md={12} sm={24}>
              <h1>Login</h1>
              <LoginForm />
              <br/>
            </Col>
            <Col lg={2} md={0}/>
          </Row>
        </Layout.Content>
      </Layout>
    )
  }
}

export const Login = withRouter(LoginUnwrapped)
