import { StatusHelpers } from 'utils/status'

export const CREATED = 'CREATED'
export const BOOKED = 'BOOKED'
export const CANCELLED = 'CANCELLED'

const sequence = [CREATED, BOOKED, CANCELLED]

export const saleInvoiceStatus = invoice =>
  new StatusHelpers(invoice ? invoice.status : null, sequence)
