import React from 'react'
import { Table, Align, RequestButton } from 'components'

export const AttachmentList = ({ data, refresh }) =>
  data.length > 0 && (
    <Table>
      <Table.Body>
        <Table.Tr>
          <Table.Th width="80%">Pliki</Table.Th>
          <Table.Th></Table.Th>
        </Table.Tr>
        {data.map(attachment => (
          <Table.Tr key={attachment.id}>
            <Table.Td>
              <RequestButton
                v2
                url={`/files/${attachment.id}/url`}
                onSuccess={(data) => {
                  console.log(data)
                  const win = window.open(data, '_blank')
                  win.focus()
                }}
                method="get"
                type="link"
                content={attachment.filename}
              />
            </Table.Td>
            <Table.Td>
              <Align value="right">
                <RequestButton
                  v2
                  onSuccess={refresh}
                  onSuccessMessage="Pomyślnie usunięto załącznik"
                  url={`/files/${attachment.id}`}
                  method="delete"
                  type="link"
                  content="Usuń"
                  title={`Czy na pewno chcesz usunąć załącznik ${attachment.fileName}?`}
                />
              </Align>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Body>
    </Table>
  )
