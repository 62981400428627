import React from 'react'
import { Row, Col, Table } from 'antd'
import { Paper } from 'components'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { WarehouseForm } from './WarehouseForm/WarehouseForm'
import { ZoneForm } from './ZoneForm/ZoneForm'
import { RequestButton } from 'components/RequestButton/RequestButton'

const getWarehouseColumns = refresh => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '33%',
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    key: 'name',
    width: '33%',
  },
  {
    title: '',
    width: '34%',
    key: 'delete',
    render: (_text, record) => (
      <RequestButton
        content="Usuń wraz ze strefami"
        method="delete"
        url={`/warehouses/${record.id}`}
        type="link"
        onSuccessMessage={'Pomyślnie usunięto magazyn!'}
        onSuccess={() => {
          refresh()
        }}
      />
    ),
  },
]

const getZoneColumns = refresh => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '22%',
  },
  {
    title: 'Nazwa',
    dataIndex: 'name',
    key: 'name',
    width: '22%',
  },
  {
    title: 'Magazyn',
    dataIndex: 'warehouse',
    key: 'warehouse',
    width: '22%',
    render: warehouse => (warehouse ? warehouse.name : '-'),
  },
  {
    title: '',
    key: 'delete',
    width: '34%',
    render: (_text, record) => (
      <RequestButton
        content="Usuń"
        method="delete"
        url={`/zones/${record.id}`}
        type="link"
        onSuccessMessage="Pomyślnie usunięto strefę!"
        onSuccess={() => {
          refresh()
        }}
      />
    ),
  },
]

const normalizeWarehouses = records =>
  records.map(record => ({
    key: record.id,
    ...record,
  }))

const normalizeZones = warehouses => records =>
  records.map(record => ({
    key: record.id,
    ...record,
    warehouse: warehouses.find(
      warehouse => warehouse.id === record.warehouseId
    ),
  }))

export const WarehouseList = () => (
  <MainLayout
    header="Magazyny i strefy"
    minWidth
    backgroundColor="none"
    marginTop="0"
  >
    <ResourceProvider
      url="/warehouses"
      responseNormalizer={normalizeWarehouses}
      render={({ data: warehouses, loading: loading1, refresh: refresh1 }) => (
        <>
          <Paper title="Magazyny">
            <Row style={{ paddingBottom: '2rem' }}>
              <Col span={24}>
                <WarehouseForm refresh={refresh1} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  loading={loading1}
                  columns={getWarehouseColumns(refresh1)}
                  dataSource={warehouses || []}
                />
              </Col>
            </Row>
          </Paper>
          {warehouses && !loading1 && (
            <ResourceProvider
              url="/zones"
              responseNormalizer={normalizeZones(warehouses || [])}
              render={({
                data: zones,
                loading: loading2,
                refresh: refresh2,
              }) => (
                <Paper title="Strefy">
                  <Row style={{ paddingBottom: '2rem' }}>
                    <Col span={24}>
                      <ZoneForm
                        warehouses={warehouses || []}
                        refresh={refresh1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Table
                        loading={loading2}
                        columns={getZoneColumns(refresh2)}
                        dataSource={zones || []}
                      />
                    </Col>
                  </Row>
                </Paper>
              )}
            />
          )}
        </>
      )}
    />
  </MainLayout>
)
