import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { request } from 'api/request'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col, Button } from 'antd'
import { Form } from '@ant-design/compatible'
import { Paper, AsyncSelect, SLabel, Right, RequestButton } from 'components'
import { ItemsTable } from './ItemsTable/ItemsTable'
import { AddItemForm } from './AddItemForm/AddItemForm'
import { pathOr } from 'ramda'

class ReceiveStockUnwrapped extends React.Component {
  state = {
    items: [],
    warehouseId: 2,
    zones: [],
  }

  componentDidMount = () => {
    this.fetchZones();
  }

  onChange = key => value => {
    this.setState({ [key]: value }, this.fetchZones)
  }

  fetchZones = async () => {
    const { warehouseId } = this.state
    if (!warehouseId) {
      return this.setState({ zones: [] })
    }

    const response = await request.get(`/warehouses/${warehouseId}/zones`)
    if (response) {
      this.setState({
        zones: response.data.data,
      })
    }
  }

  onSuccess = () => {
    this.props.history.push('/stock')
  }

  handleChange = (sku, field) => value => {
    this.setState(state => ({
      items: state.items.map(item => {
        if (item.sku !== sku) return item
        return {
          ...item,
          [field]: value,
        }
      }),
    }))
  }

  addItem = sku => {
    this.setState(state => ({
      items: [
        ...state.items.filter(item => item.sku !== sku),
        {
          sku,
          quantity: 1,
          zoneId: pathOr(
            state.zones[0].id,
            ['zoneId'],
            state.items[state.items.length - 1]
          ),
        },
      ],
    }))
  }

  render() {
    const { warehouseId, zones, items } = this.state
    return (
      <MainLayout
        header="Przyjmij na magazyn"
        backgroundColor="none"
        marginTop="0"
      >
        <Row>
          <Col xxl={12} xl={20} lg={24} xs={24}>
            <Paper title="Wybierz magazyn">
              <AsyncSelect
                disabled={items.length > 0}
                value={warehouseId}
                id="receive_stock-warehouseId"
                url="/warehouses"
                fullList
                responseNormalizer={item => ({
                  text: item.name,
                  value: item.id,
                })}
                onChange={this.onChange('warehouseId')}
              />
            </Paper>
          </Col>
        </Row>
        <Row>
          <Col xxl={12} xl={20} lg={24} xs={24}>
            <Paper title="Lista towarów">
              <ItemsTable
                zones={zones}
                items={items}
                onChange={this.handleChange}
              />
            </Paper>
            <Paper title="Dodaj">
              <Row gutter={{ sm: 16 }}>
                <Col lg={12} xs={24}>
                  <AddItemForm
                    onSubmit={this.addItem}
                    disabled={zones.length === 0}
                  />
                </Col>
                <Col lg={12} xs={24}>
                  <SLabel>Importuj</SLabel>
                  <Button type="primary" disabled>
                    WZ XML Insert Subiekt Nexo
                  </Button>
                </Col>
              </Row>
            </Paper>
          </Col>
        </Row>
        <Row>
          <Col xxl={12} xl={20} lg={24} xs={24}>
            <Right>
              <RequestButton
                onSuccess={this.onSuccess}
                url="/stock"
                method="post"
                id="receive-stock_submit-button"
                type="danger"
                content="Przyjmij"
                disabled={items.length === 0}
                payload={{
                  items,
                }}
                title="Czy na pewno chcesz przyjąć te towary na magazyn?"
              />
            </Right>
          </Col>
        </Row>
      </MainLayout>
    )
  }
}

export const ReceiveStock = compose(
  withRouter,
  Form.create({ name: 'stock-receive-form' })
)(ReceiveStockUnwrapped)
