import React from 'react'
import { Link } from 'react-router-dom'
import { TextField, Paper } from 'components'
import { formatDateTime } from 'utils/formatters'
import { Typography } from 'antd'
import { ShopOutlined, UserOutlined } from '@ant-design/icons'

export const SaleInvoiceHeader = ({ invoice }) => (
  <Paper title="Informacje">
    <TextField
      label="ID"
      value={<Typography.Text copyable>{invoice.id}</Typography.Text>}
    />
    <TextField
      label="Numer"
      value={
        <Typography.Text copyable>{invoice.number || '-'}</Typography.Text>
      }
    />
    <TextField label="Status" value={invoice.status} />
    <TextField
      label="Adresat"
      value={
        invoice.recipient.userId ? (
          <Link to={`/users/${invoice.recipient.userId}`}>
            {invoice.recipient.isCorporate ? (
              <ShopOutlined />
            ) : (
              <UserOutlined />
            )}
            &nbsp;{invoice.recipient.name}
          </Link>
        ) : (
          <span>
            {invoice.recipient.isCorporate ? (
              <ShopOutlined />
            ) : (
              <UserOutlined />
            )}
            &nbsp;{invoice.recipient.name}
          </span>
        )
      }
    />
    {invoice.recipient.taxId && (
      <TextField label="NIP" value={invoice.recipient.taxId} />
    )}
    <TextField
      label="Adres"
      value={
        <>
          <div>{invoice.recipientAddress.addressLine1}</div>
          {invoice.recipientAddress.addressLine2 && (
            <div>{invoice.recipientAddress.addressLine2}</div>
          )}
          <div>
            {invoice.recipientAddress.postalCode},{' '}
            {invoice.recipientAddress.city},{' '}
            {invoice.recipientAddress.countryCode}
          </div>
        </>
      }
    />
    <TextField label="Ilość zdarzeń" value={invoice.events.length.toString()} />
    <TextField label="Utworzono" value={formatDateTime(invoice.createdAt)} />
    <TextField label="Edytowano" value={formatDateTime(invoice.updatedAt)} />
  </Paper>
)
