import React from 'react'
import PropTypes from 'prop-types'
import { showSuccess } from 'layouts/notifications'
import { withRouter } from 'react-router-dom'
import { request, requestV2 } from 'api/request'
import { compose } from 'recompose'
import { path } from 'ramda'
import { withIsMounted } from 'utils/withIsMounted'
import { difference } from 'utils/diff'

class FormProviderUnwrapped extends React.Component {
  constructor(props) {
    super()
    this.state = {
      updating: false,
      error: '',
      data: props.data,
    }
  }

  setStateSafe = (obj, callback) => {
    if (this.props.isMounted()) {
      this.setState(obj, callback)
    }
  }

  updateFormState = (updateObject, cb) => {
    this.setState(
      state => ({
        data: {
          ...state.data,
          ...updateObject,
        },
      }),
      cb
    )
  }

  handleRequest = async payload => {
    try {
      this.setStateSafe({ updating: true, error: '' })
      const response = await this.makeRequest(
        this.props.mapFormDataToPayload(payload),
        this.props.queryParams
      )
      this.setStateSafe({ updating: false }, () => {
        showSuccess(this.props.onSuccessMessage)
        this.updateFormState(payload, () => {
          if (response) {
            this.props.onSuccess(response.data.data)
          } else {
            this.props.onSuccess()
          }
        })
      })
    } catch (err) {
      const errMessage = path(['response', 'data', 'status'], err)
        ? `${err.response.data.status}: ${err.response.data.statusText}`
        : err.message

      this.setStateSafe({ updating: false, error: errMessage }, () => {
        this.props.onError()
      })
    }
  }

  wrapPayload = data => this.props.v2 ? data : { data }

  makeRequest = (payload, params) => {
    return (this.props.v2 ? requestV2 : request)({
      method: this.props.method,
      url: this.props.url,
      data: this.wrapPayload({
        ...this.props.additionalData,
        ...payload,
      }),
      params,
    })
  }

  handleSubmit = eventOrData => {
    if (eventOrData.stopPropagation) {
      eventOrData.stopPropagation()
    }
    if (!eventOrData.preventDefault) {
      return this.submitForm(eventOrData)
    }
    eventOrData.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.submitForm(values)
      }
    })
  }

  submitForm = async formValues => {
    const values = path(this.props.requestDataPath, formValues)
    if (this.props.diff) {
      const diff = difference(this.state.data, values, this.props.diffOptions)
      if (Object.keys(diff).length > 0) {
        await this.handleRequest(diff)
      }
    } else {
      await this.handleRequest(values)
    }
  }

  render() {
    const { updating, error } = this.state
    return this.props.render({
      error,
      updating,
      handleSubmit: this.handleSubmit,
      formData: this.state.data,
    })
  }
}

FormProviderUnwrapped.defaultProps = {
  method: 'post',
  onSuccessMessage: 'Wszystko będzie dobrze!',
  diff: true,
  onError: () => { },
  onSuccess: () => { },
  mapFormDataToPayload: val => val,
  requestDataPath: [],
  data: {},
  additionalData: {},
  diffOptions: {},
  v2: false,
}

FormProviderUnwrapped.propTypes = {
  url: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  isMounted: PropTypes.func.isRequired,
  data: PropTypes.object,
  additionalData: PropTypes.object,
  method: PropTypes.string,
  onSuccessMessage: PropTypes.string,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  diff: PropTypes.bool,
  mapFormDataToPayload: PropTypes.func,
  requestDataPath: PropTypes.arrayOf(PropTypes.string),
  diffOptions: PropTypes.object,
  v2: PropTypes.bool,
}

export const FormProvider = compose(
  withIsMounted,
  withRouter
)(FormProviderUnwrapped)
