import axios from 'axios'
import { path, pathOr } from 'ramda'
import { store } from 'index'
import { API_BASE_URL_V1, API_BASE_URL_V2 } from 'config/index'
import { paths } from './paths'
import { showError } from 'layouts/notifications'
import { history } from '../router/history'

const getToken = () => {
  const state = store.getState()
  return path(['authReducer', 'token'], state)
}


const buildRequest = (baseURL) => {
  const request = axios.create({
    baseURL,
  })

  request.interceptors.request.use(
    config => {
      config.headers.Authorization = getToken()
      return config
    },
    error => Promise.reject(error)
  )

  request.interceptors.response.use(
    _ => _,
    error => {
      switch (path(['response', 'status'], error)) {
        case 403:
          history.push(paths.logout)
          showError('Brak dostępu.', 403)
          break
        case 404:
          showError(
            pathOr(
              'Nieprawidłowy URL',
              ['response', 'data', 'statusText'],
              error
            ),
            404
          )
          break
        case 500:
          showError('Błąd serwera', 404)
          break

        default: {
          showError(
            path(['response', 'data', 'message'], error) || path(['response', 'data', 'statusText'], error),
            path(['response', 'data', 'status'], error)
          )
        }
      }

      throw error
    }
  )
  return request
}

export const request = buildRequest(API_BASE_URL_V1)
export const requestV2 = buildRequest(API_BASE_URL_V2)
