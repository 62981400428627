import React from 'react'
import { InputNumber, Select, Table } from 'antd'
import { Copy, PrintQrLabel } from 'components'

const getColumns = (zones, onChange) => [
  {
    title: 'SKU',
    dataIndex: 'sku',
    render: sku => <>{sku} <Copy value={sku} /> <PrintQrLabel value={sku} /></>
  },
  {
    title: 'Ilość',
    dataIndex: 'quantity',
    render: (_, item) => (
      <InputNumber
        id="receive-stock_items-table_item_quantity"
        value={item.quantity}
        onChange={onChange(item.sku, 'quantity')}
        min={1}
      />
    ),
  },
  {
    title: 'Strefa',
    dataIndex: 'zoneId',
    render: (_, item) => (
      <Select
        value={item.zoneId}
        onChange={onChange(item.sku, 'zoneId')}
        id="receive-stock_items-table_item_zoneId"
      >
        {zones.map(zone => (
          <Select.Option key={zone.id} value={zone.id}>
            {zone.name}
          </Select.Option>
        ))}
      </Select>
    ),
  },
]

export const ItemsTable = ({ zones, items, onChange }) => (
  <Table
    rowKey="sku"
    columns={getColumns(zones, onChange)}
    dataSource={items}
    pagination={false}
  />
)
