import React from 'react'
import { GridRow } from './GridRow/GridRow'
import { GridItem } from './GridItem/GridItem'
import { GridHeader } from './GridHeader/GridHeader'
import { GridCol } from './GridCol/GridCol'
import { SGrid } from './SGrid'

export const Grid = ({ children, ...props }) => (
  <SGrid {...props}>{children}</SGrid>
)

Grid.Row = GridRow
Grid.Item = GridItem
Grid.Header = GridHeader
Grid.Col = GridCol
