import React from 'react'
import { Row, Col, Select, InputNumber } from 'antd'
import { RequestButton } from 'components/RequestButton/RequestButton'

const currencies = ['NOK']

export class AddSalesPrice extends React.Component {
  state = {
    netto: null,
    currency: undefined,
  }

  handleCurrencyChange = currency => {
    this.setState({
      currency,
    })
  }

  handleValueChange = netto => {
    this.setState({
      netto,
    })
  }

  isDisabled = () => !this.state.netto || !this.state.currency

  onSuccess = () => {
    this.setState({
      netto: null,
      currency: undefined,
    })
    this.props.refresh()
  }

  render() {
    const { sku, salesChannelCode } = this.props
    const { netto, currency } = this.state
    return (
      <Row gutter={12} id="add-sales-price-form">
        <Col md={8}>
          <InputNumber
            style={{ width: '100%' }}
            value={netto}
            placeholder="Netto"
            id="add-sales-price-form_netto"
            min={0}
            onChange={this.handleValueChange}
            formatter={value =>
              value.toString().replace(/\B(?=(\d{2}$))/g, ',')
            }
            parser={value => value.toString().replace(/\$\s?|(,*)/g, '')}
          />
        </Col>
        <Col md={8}>
          <Select
            placeholder="Waluta"
            value={currency}
            style={{ width: '100%' }}
            onChange={this.handleCurrencyChange}
            id="add-sales-price-form_currency"
          >
            {currencies.map(currency => (
              <Select.Option key={currency}>{currency}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col md={8}>
          <RequestButton
            url="/products/sales-prices"
            method="post"
            id="add-sales-price-form_submit"
            payload={{
              netto,
              currency,
              sku,
              salesChannelCode,
            }}
            onSuccess={this.onSuccess}
            type="primary"
            disabled={this.isDisabled()}
            content="Dodaj"
          />
        </Col>
      </Row>
    )
  }
}
