import React from 'react'
import { pathOr } from 'ramda'
import { TextField, Paper, FulfillmentsWidget } from 'components'
import { formatDateTime } from 'utils/formatters'
import { Typography } from 'antd'

export const ParcelHeader = ({ parcel }) => (
  <Paper title="Informacje">
    <TextField
      label="ID"
      value={
        <Typography.Text copyable id="parcel_header_id">
          {parcel.id}
        </Typography.Text>
      }
    />
    <TextField label="Status" value={parcel.status} />
    <TextField
      label="Adresat"
      value={pathOr('', ['deliveryAddress', 'name'], parcel)}
    />
    <TextField label="Ilość zdarzeń" value={parcel.events.length.toString()} />
    <TextField label="Utworzono" value={formatDateTime(parcel.createdAt)} />
    <TextField label="Edytowano" value={formatDateTime(parcel.updatedAt)} />
    <TextField label="Zamówienia" value={<FulfillmentsWidget saleIds={parcel.saleIds} />} />
  </Paper>
)
