import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Input, Select, Button } from 'antd'
import { Form } from '@ant-design/compatible'
import { Paper, Right } from 'components'
import { Prefixer } from './Prefixer/Prefixer'
import { withManufacturers } from 'hocs/withManufacturers'

const sortManufacturers = items => items.sort((a, b) => {
  if (a.name > b.name) return 1
  if (a.name < b.name) return -1
  return 0
})

const ProductCreateUnwrapped = ({ history, form, manufacturers }) => (
  <MainLayout header="Dodaj nowy produkt" backgroundColor="none" marginTop="0">
    <FormProvider
      url="/products/sku"
      form={form}
      onSuccessMessage="Pomyślnie dodano nowy produkt!"
      onSuccess={() => {
        history.push(`/products/${form.getFieldValue('sku').toUpperCase()}`)
      }}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} id="create-product-form">
          <Row>
            <Col xxl={12} xl={20} lg={24} xs={24}>
              <Row type="flex" justify="space-between">
                <Col lg={24} xs={24}>
                  <Paper title="Informacje podstawowe">
                    <Col lg={12} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            Kod towaru{' '}
                            <Prefixer
                              form={form}
                              sku={form.getFieldsValue().sku}
                              handleChange={sku => {
                                form.setFieldsValue({
                                  sku,
                                })
                              }}
                              manufacturer={manufacturers.find(
                                m =>
                                  m.code ===
                                  form.getFieldsValue().manufacturerCode
                              )}
                            />
                          </span>
                        }
                      >
                        {form.getFieldDecorator('sku', {
                          normalize: value => value
                            .toUpperCase()
                            .trim()
                            .replaceAll(/(:|\.|\+|\*|–|\/)/g, '-')
                            .replaceAll(/(\s|\/|=|%)/g, '')
                            .replaceAll(/(\(|\))/g, '_'),
                          rules: [
                            {
                              required: true,
                              message: 'Podaj kod towaru',
                              transform: value => value.toUpperCase().trim(),
                            },
                            {
                              message: 'Nieprawidłowy format. Tylko duże litery, cyfry, "_" oraz "-" bez spacji',
                              transform: value => value.toUpperCase().trim(),
                              pattern: /^[A-Z0-9_-]*$/,
                            },
                          ],
                        })(<Input placeholder="SKU" />)}
                      </Form.Item>
                      <Form.Item label="Producent">
                        {form.getFieldDecorator('manufacturerCode')(
                          <Select
                            virtual={false}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option.children.toUpperCase()).includes(input.toUpperCase())}
                            placeholder="Producent"
                            style={{ width: '100%' }}
                          >
                            {sortManufacturers(manufacturers).map((manufacturer, index) => (
                              <Select.Option
                                key={index}
                                value={manufacturer.code}
                              >
                                {manufacturer.name}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                      <Form.Item label="Kod CN">
                        {form.getFieldDecorator('cnCode')(
                          <Input placeholder="Kod CN" />
                        )}
                      </Form.Item>
                      <Form.Item label="Kod EAN">
                        {form.getFieldDecorator('ean')(
                          <Input placeholder="EAN" />
                        )}
                      </Form.Item>
                      <Form.Item label="Numer TecDoc">
                        {form.getFieldDecorator('tecDocId')(
                          <Input placeholder="Numer TecDoc" />
                        )}
                      </Form.Item>
                    </Col>
                  </Paper>
                  <Row>
                    <Form.Item>
                      <Right>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Right>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    />
  </MainLayout>
)

export const ProductCreate = compose(
  withRouter,
  withManufacturers,
  Form.create({ name: 'product-create-form' })
)(ProductCreateUnwrapped)
