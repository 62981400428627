import React from 'react'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'

export const withFormProvider = options => Component => props => {
  const { name, ...providerProps } = options
  if (!name) {
    throw new Error(
      'Higher order component withFormProvider options.name is required.'
    )
  }
  return (
    <FormProvider
      {...providerProps}
      url={
        typeof providerProps.url === 'function'
          ? providerProps.url(props)
          : providerProps.url
      }
      render={providerData => (
        <Component {...props} {...{ [name]: providerData }} />
      )}
    />
  )
}
