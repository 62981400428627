import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Input, Button } from 'antd'
import { Form } from '@ant-design/compatible'

export const AddManufacturerFormUnwrapped = ({ form, onSuccess }) => (
  <FormProvider
    url="/manufacturers"
    form={form}
    onSuccessMessage="Pomyślnie dodano nowego producenta!"
    onSuccess={() => {
      form.resetFields(['name', 'code'])
      onSuccess()
    }}
    render={({ handleSubmit, updating }) => (
      <Form layout="inline" onSubmit={handleSubmit}>
        <Form.Item>
          {form.getFieldDecorator('code', {
            rules: [{ required: true, message: 'Podaj kod producenta!' }],
            normalize: val => val.toUpperCase(),
            initialValue: '',
          })(<Input placeholder="Kod producenta" />)}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator('name', {
            rules: [{ required: true, message: 'Podaj nazwę producenta!' }],
            initialValue: '',
          })(<Input placeholder="Nazwa producenta" />)}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={updating}>
            Dodaj
          </Button>
        </Form.Item>
      </Form>
    )}
  />
)

AddManufacturerFormUnwrapped.defaultProps = {
  onSuccess: () => {},
}

AddManufacturerFormUnwrapped.propTypes = {
  onSuccess: PropTypes.func,
}

export const AddManufacturerForm = Form.create({
  name: 'manufacturer-create-form',
})(AddManufacturerFormUnwrapped)
