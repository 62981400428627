import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { withIsMounted } from 'utils/withIsMounted'
import {
  CopyOutlined as CopyIcon,
  CheckOutlined as CheckIcon,
} from '@ant-design/icons'

class CopyUnwrapped extends React.Component {
  state = {
    copied: false,
  }

  onCopy = () => {
    this.setState({ copied: true })
    setTimeout(() => {
      if (this.props.isMounted) {
        this.setState({
          copied: false,
        })
      }
    }, 1000)
  }

  render() {
    const { value } = this.props
    const { copied } = this.state
    return (
      <CopyToClipboard text={String(value || '').trim()} onCopy={this.onCopy}>
        <span>
          {!copied && (
            <CopyIcon
              style={{
                marginLeft: '0.4rem',
                color: '#1890ff',
                cursor: 'pointer',
              }}
            />
          )}
          {copied && (
            <CheckIcon
              type="check"
              style={{ marginLeft: '0.4rem', color: '#52c41a' }}
            />
          )}
        </span>
      </CopyToClipboard>
    )
  }
}

export const Copy = withIsMounted(CopyUnwrapped)
