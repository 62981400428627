import React from 'react'
import { connect } from 'react-redux'
import { closeDrawer } from 'redux/actions'
import { DrawerTypes } from 'redux/actions/constants'
import { StockLevelsDrawer } from './StockLevelsDrawer/StockLevelsDrawer'

export const DrawersManagerUnwrapped = props => (
  <React.Fragment>
    <StockLevelsDrawer
      params={props.params}
      visible={props.drawerType === DrawerTypes.StockLevels}
      onClose={props.onClose}
    />
  </React.Fragment>
)

const mapStateToProps = state => ({
  ...state.drawerReducer,
})

const mapDispatchToProps = dispatch => ({
  onClose: () => {
    dispatch(closeDrawer())
  },
})

export const DrawersManager = connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawersManagerUnwrapped)
