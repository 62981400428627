import React from 'react'
import * as saleStatus from './saleStatus'
import { isBefore, isEqual, isAfter, isNot } from './saleStatus'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { withOnErrorRedirect } from 'hocs/withOnErrorRedirect'
import { SaleHeader } from './SaleHeader/SaleHeader'
import { Cart } from './Cart/Cart'
import { EventsLog } from 'components/EventsLog/EventsLog'
import { PlaceOrder } from './PlaceOrder/PlaceOrder'
import { SetDeliveryCost } from './SetDeliveryCost/SetDeliveryCost'
import { Order } from './Order/Order'
import { AcceptOrder } from './AcceptOrder/AcceptOrder'
import { ReceivePayment } from './ReceivePayment/ReceivePayment'
import { Fulfillment } from './Fulfillment/Fulfillment'
import { Billing } from './Billing/Billing'
import { CompleteOrder } from './CompleteOrder/CompleteOrder'
import { CancelOrder } from './CancelOrder/CancelOrder'

class SaleShowUnwrapped extends React.Component {
  render() {
    const { id } = this.props.match.params
    return (
      <ResourceProvider
        url={`/sales/${encodeURIComponent(id)}`}
        onError={this.props.onErrorRedirect}
        render={({ data: sale, loading, refresh }) => (
          <MainLayout
            header={`Sprzedaż ${sale ? sale.number : ''}`}
            isLoading={!sale && loading}
            transparent
          >
            {sale && !loading && (
              <Row>
                <Col xxl={12} xl={20} lg={24} xs={24}>
                  <Row type="flex" justify="space-between">
                    <Col lg={24} xs={24}>
                      <SaleHeader sale={sale} />
                    </Col>
                    {isAfter(sale.status, saleStatus.ORDERED) &&
                      isBefore(sale.status, saleStatus.COMPLETED) &&
                      isNot(sale.status, saleStatus.CANCELLED) && (
                        <Col lg={24} xs={24}>
                          <CompleteOrder sale={sale} refresh={refresh} />
                        </Col>
                      )}
                    {isAfter(sale.status, saleStatus.ORDERED) && (
                      <Col lg={24} xs={24}>
                        <Billing sale={sale} refresh={refresh} />
                      </Col>
                    )}
                    {isAfter(sale.status, saleStatus.ORDERED) &&
                      isBefore(sale.status, saleStatus.SHIPPING) && (
                        <Col lg={24} xs={24}>
                          <Fulfillment sale={sale} refresh={refresh} />
                        </Col>
                      )}
                    {isEqual(sale.status, saleStatus.ORDERED) && (
                      <Col lg={24} xs={24}>
                        <AcceptOrder sale={sale} refresh={refresh} />
                      </Col>
                    )}
                    {isAfter(sale.status, saleStatus.ACTIVE) &&
                      isNot(sale.status, saleStatus.CANCELLED) && (
                        <Col lg={24} xs={24}>
                          <ReceivePayment sale={sale} refresh={refresh} />
                        </Col>
                      )}
                    {isAfter(sale.status, saleStatus.ACTIVE) && (
                      <Col lg={24} xs={24}>
                        <Order sale={sale} refresh={refresh} />
                      </Col>
                    )}
                    {isEqual(sale.status, saleStatus.ACTIVE) && (
                      <Col lg={24} xs={24}>
                        <PlaceOrder sale={sale} refresh={refresh} />
                      </Col>
                    )}
                    {isEqual(sale.status, saleStatus.ACTIVE) && (
                      <Col lg={24} xs={24}>
                        <SetDeliveryCost sale={sale} refresh={refresh} />
                      </Col>
                    )}
                    {isBefore(sale.status, saleStatus.ORDERED) && (
                      <Col lg={24} xs={24}>
                        <Cart sale={sale} refresh={refresh} />
                      </Col>
                    )}
                    <Col lg={24} xs={24}>
                      <EventsLog events={sale.events} />
                    </Col>
                    {isAfter(sale.status, saleStatus.ORDERED) &&
                      isNot(sale.status, saleStatus.CANCELLED) &&
                      isBefore(sale.status, saleStatus.COMPLETED) && (
                        <Col lg={24} xs={24}>
                          <CancelOrder sale={sale} refresh={refresh} />
                        </Col>
                      )}
                  </Row>
                </Col>
              </Row>
            )}
          </MainLayout>
        )}
      />
    )
  }
}

export const SaleShow = withOnErrorRedirect(SaleShowUnwrapped)
