import React from 'react'
import { pathOr } from 'ramda'
import { Row, Button, Select, Col } from 'antd'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { FitmentSelect } from './FitmentSelect/FitmentSelect'
import * as api from 'api'
import { Spacing } from 'components/Spacing/Spacing'

const selectStyle = { width: '100%' }

const years = (() => {
  let year = 1980
  const currentYear = new Date().getFullYear()
  const years = []
  while (years[years.length - 1] !== currentYear) {
    years.push(year)
    year++
  }
  return years.reverse().map(year => ({ id: year, label: year }))
})()

export class AddFitmentForm extends React.Component {
  state = {
    tree: undefined,
    classification: undefined,
    brandId: undefined,
    modelId: undefined,
    typeId: undefined,
    yearFrom: undefined,
    yearTo: undefined,
    options: {
      classifications: ['cv'],
      brands: [],
      models: [],
      types: [],
    },
  }

  componentDidMount = () => {
    this.onTreeChange('ws')
  }

  mapOptions = items => items.map(item => ({ id: item.id, label: item.label }))

  handleYearFromChange = yearFrom => {
    const { yearTo } = this.state
    if (!yearTo || yearFrom === '*' || yearTo >= yearFrom) {
      this.setState({ yearFrom })
    }
  }

  handleYearToChange = yearTo => {
    const { yearFrom } = this.state
    if (!yearFrom || yearTo === '*' || yearFrom <= yearTo) {
      this.setState({ yearTo })
    }
  }

  onTreeChange = tree => {
    this.setState({ tree })
    this.onClassChange(tree === 'ws' ? 'pc' : undefined)
  }

  onClassChange = classification => {
    this.setState({ classification }, () => {
      this.fetchBrands(classification)
    })
    this.onBrandChange(undefined)
  }

  onBrandChange = brandId => {
    this.setState({ brandId }, () => {
      this.fetchModels(this.state.classification, brandId)
    })
    this.onModelChange(undefined)
  }

  onModelChange = modelId => {
    this.setState({ modelId }, () => {
      this.fetchTypes(this.state.classification, this.state.brandId, modelId)
    })
    this.onTypeChange(undefined)
  }

  onTypeChange = typeId => {
    this.setState({
      typeId,
    })
  }

  setBrands = brands => {
    this.setState(state => ({
      options: {
        ...state.options,
        brands,
        models: [],
        types: [],
      },
    }))
  }

  setModels = models => {
    this.setState(state => ({
      options: {
        ...state.options,
        models,
        types: [],
      },
    }))
  }

  setTypes = types => {
    this.setState(state => ({
      options: {
        ...state.options,
        types,
      },
    }))
  }

  fetchBrands = async classification => {
    if (classification && classification !== '*') {
      const response = await api.fetchBrands(this.state.tree, classification)
      const brands = pathOr([], ['data', 'data'], response)
      this.setBrands(this.mapOptions(brands))
    }
  }

  fetchModels = async (classification, brandId) => {
    if (brandId && brandId !== '*') {
      const response = await api.fetchModels(
        this.state.tree,
        classification,
        brandId
      )
      const models = pathOr([], ['data', 'data'], response)
      this.setModels(this.mapOptions(models))
    }
  }

  fetchTypes = async (classification, brandId, modelId) => {
    if (modelId && modelId !== '*') {
      const response = await api.fetchTypes(
        this.state.tree,
        classification,
        brandId,
        modelId
      )
      const types = pathOr([], ['data', 'data'], response)
      this.setTypes(this.mapOptions(types))
    }
  }

  onSubmit = handleSubmit => () => {
    const {
      classification,
      brandId,
      modelId,
      typeId,
      yearFrom,
      yearTo,
      tree,
    } = this.state
    handleSubmit(
      this.mapAsteriskToNull({
        sku: this.props.sku,
        classification,
        brandId,
        modelId,
        typeId,
        yearFrom,
        yearTo,
        tree,
      })
    )
  }

  mapAsteriskToNull = obj =>
    Object.entries(obj).reduce(
      (acc, curr) => ({
        ...acc,
        [curr[0]]: curr[1] === '*' ? null : curr[1],
      }),
      {}
    )

  isDisabled = () => {
    const {
      classification,
      brandId,
      modelId,
      typeId,
      yearFrom,
      yearTo,
    } = this.state
    return [classification, brandId, modelId, typeId, yearFrom, yearTo].some(
      item => !item
    )
  }

  render() {
    const { refresh } = this.props
    const {
      tree,
      classification,
      brandId,
      modelId,
      typeId,
      yearFrom,
      yearTo,
      options,
    } = this.state

    return (
      <FormProvider
        url="/products/fitments"
        onSuccessMessage="Pomyślnie dodano dopasowanie do pojazdu!"
        onSuccess={refresh}
        render={({ handleSubmit }) => (
          <Row gutter={16}>
            <Col md={12}>
              <Spacing mb="5px">
                <Select
                  style={selectStyle}
                  value={tree}
                  onChange={this.onTreeChange}
                  placeholder="Drzewo samochodów"
                >
                  <Select.Option value="*">
                    <strong>*</strong>
                  </Select.Option>
                  <Select.Option value="ws">
                    <strong>wheel-size</strong>
                  </Select.Option>
                </Select>
              </Spacing>
            </Col>
            <Col md={12}>
              <Spacing mb="5px">
                <Select
                  style={selectStyle}
                  value={classification}
                  disabled={!tree || tree === 'ws'}
                  onChange={this.onClassChange}
                  placeholder="Klasa pojazdu"
                >
                  <Select.Option value="*">
                    <strong>*</strong>
                  </Select.Option>
                  {options.classifications.map(classification => (
                    <Select.Option key={classification} value={classification}>
                      <strong>{classification.toUpperCase()}</strong>
                    </Select.Option>
                  ))}
                </Select>
              </Spacing>
            </Col>
            <Col md={12}>
              <Spacing mb="5px">
                <FitmentSelect
                  selectStyle={selectStyle}
                  disabled={!classification}
                  options={this.state.options.brands}
                  value={brandId}
                  onChange={this.onBrandChange}
                  placeholder="Marka"
                />
              </Spacing>
            </Col>
            <Col md={12}>
              <Spacing mb="5px">
                <FitmentSelect
                  selectStyle={selectStyle}
                  disabled={!brandId}
                  options={this.state.options.models}
                  value={modelId}
                  onChange={this.onModelChange}
                  placeholder="Model"
                />
              </Spacing>
            </Col>
            <Col md={12}>
              <Spacing mb="5px">
                <FitmentSelect
                  selectStyle={selectStyle}
                  disabled={!modelId}
                  options={this.state.options.types}
                  value={typeId}
                  onChange={this.onTypeChange}
                  placeholder="Typ"
                />
              </Spacing>
            </Col>
            <Col md={12}>
              <Spacing mb="5px">
                <FitmentSelect
                  selectStyle={selectStyle}
                  options={years}
                  value={yearFrom}
                  placeholder="Rok od"
                  onChange={this.handleYearFromChange}
                />
              </Spacing>
            </Col>
            <Col md={12}>
              <Spacing mb="5px">
                <FitmentSelect
                  selectStyle={selectStyle}
                  options={years}
                  value={yearTo}
                  placeholder="Rok do"
                  onChange={this.handleYearToChange}
                />
              </Spacing>
            </Col>
            <Col md={12}>
              <Spacing mb="5px">
                <Button
                  type="primary"
                  onClick={this.onSubmit(handleSubmit)}
                  disabled={this.isDisabled()}
                >
                  Dodaj
                </Button>
              </Spacing>
            </Col>
          </Row>
        )}
      />
    )
  }
}
