import React from 'react'
import PropTypes from 'prop-types'
import * as selectors from 'redux/selectors'
import { connect } from 'react-redux'
import { fetchProduct } from 'redux/actions'

export class AsyncProductDescriptionUnwrapped extends React.Component {
  componentDidMount = () => {
    const { products, sku } = this.props
    if (!products[sku]) {
      this.props.fetchProduct(this.props.sku)
    }
  }

  render() {
    const { products, sku, placeholder } = this.props
    if (!products[sku]) {
      return placeholder || '-'
    }
    if (products[sku].errorStatus) {
      return products[sku].errorStatus
    }
    return this.props.render(products[sku])
  }
}

const defaultRender = product => {
  let description = product.manufacturerCode
  if (product.oe) {
    description += ` - ${product.oe}`
  }
  if (product.tecDocId) {
    description += ` - ${product.tecDocId}`
  }
  if (product.eans.length > 0) {
    description += ` - ${product.eans.join(', ')}`
  }
  return description
}

AsyncProductDescriptionUnwrapped.defaultProps = {
  render: defaultRender,
}

AsyncProductDescriptionUnwrapped.propTypes = {
  sku: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
}

export const AsyncProductDescription = connect(
  state => ({
    products: selectors.selectProducts(state),
  }),
  { fetchProduct }
)(AsyncProductDescriptionUnwrapped)
