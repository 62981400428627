import React from 'react'
import { withResource } from 'hocs/withResource'
import {
  Paper,
  TextField,
  Table,
  ProductNumber,
  FulfillmentStatus,
} from 'components'
import { Spacing } from 'components/Spacing/Spacing'
import { Typography } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const FulfillmentUnwrapped = ({ fulfillment }) =>
  fulfillment.data && (
    <>
      <Paper title="Kompletacja">
        <TextField
          id="sale_fulfillment_status"
          label="Status"
          value={<FulfillmentStatus status={fulfillment.data.status} />}
        />
        <TextField
          label={`Paczki (${fulfillment.data.parcels.length})`}
          value={fulfillment.data.parcels.map(parcel => (
            <Spacing key={parcel.id} display="inline-block" mr="4px">
              <Link to={`/parcels/${parcel.id}`}>{parcel.id.slice(-8)}</Link>
            </Spacing>
          ))}
        />
        <TextField
          label="Pozostałe do zapakowania"
          value={
            <>
              {fulfillment.data.remainingToPack.length === 0 && 'Brak'}
              {fulfillment.data.remainingToPack.length > 0 && (
                <Spacing mt="1rem">
                  <Table>
                    <Table.Body>
                      <Table.Tr>
                        <Table.Th style={{ width: '50%' }}>SKU</Table.Th>
                        <Table.Th style={{ width: '50%' }}>Zapakowano</Table.Th>
                      </Table.Tr>
                      {fulfillment.data.remainingToPack.map(item => {
                        const orderedQuantity = (
                          fulfillment.data.items.find(
                            orderedItem => orderedItem.sku === item.sku
                          ) || {}
                        ).quantity
                        return (
                          <Table.Tr key={item.sku}>
                            <Table.Td>
                              <ProductNumber sku={item.sku} />{' '}
                              {item.quantity === 0 && (
                                <CheckOutlined style={{ color: '#52c41a' }} />
                              )}
                            </Table.Td>
                            <Table.Td>
                              <Typography.Text
                                type={item.quantity > 0 ? 'danger' : undefined}
                              >
                                {orderedQuantity - item.quantity} /{' '}
                                {orderedQuantity}
                              </Typography.Text>
                            </Table.Td>
                          </Table.Tr>
                        )
                      })}
                    </Table.Body>
                  </Table>
                </Spacing>
              )}
            </>
          }
        />
      </Paper>
    </>
  )

export const Fulfillment = withResource({
  url: props => `/fulfillments/${props.sale.id}`,
  name: 'fulfillment',
})(FulfillmentUnwrapped)
