import React from 'react'
import { withResource } from 'hocs/withResource'
import {
  Paper,
  TextField,
  Table,
  ProductNumber,
  BillingStatus,
} from 'components'
import { Spacing } from 'components/Spacing/Spacing'
import { Typography } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const BillingUnwrapped = ({ billing }) =>
  billing.data && (
    <>
      <Paper title="Księgowość">
        <TextField
          label="Status"
          value={<BillingStatus status={billing.data.status} />}
        />
        <TextField
          label={`Faktury (${billing.data.invoices.length})`}
          value={billing.data.invoices.map(invoice => (
            <Spacing key={invoice.id} display="inline-block" mr="4px">
              <Link to={`/sale-invoices/${invoice.id}`}>
                {invoice.id.slice(-8)}
              </Link>
            </Spacing>
          ))}
        />
        <TextField
          label="Pozostałe do zafakturowania"
          value={
            <>
              {billing.data.remainingToInvoice.length === 0 && 'Brak'}
              {billing.data.remainingToInvoice.length > 0 && (
                <Spacing mt="1rem">
                  <Table>
                    <Table.Body>
                      <Table.Tr>
                        <Table.Th style={{ width: '50%' }}>SKU</Table.Th>
                        <Table.Th style={{ width: '50%' }}>
                          Zafakturowano
                        </Table.Th>
                      </Table.Tr>
                      {billing.data.remainingToInvoice.map(item => {
                        const orderedQuantity = (
                          billing.data.items.find(
                            orderedItem => orderedItem.sku === item.sku
                          ) || {}
                        ).quantity
                        return (
                          <Table.Tr key={item.sku}>
                            <Table.Td>
                              <ProductNumber sku={item.sku} />{' '}
                              {item.quantity === 0 && (
                                <CheckOutlined style={{ color: '#52c41a' }} />
                              )}
                            </Table.Td>
                            <Table.Td>
                              <Typography.Text
                                type={item.quantity > 0 ? 'danger' : undefined}
                              >
                                {orderedQuantity - item.quantity} /{' '}
                                {orderedQuantity}
                              </Typography.Text>
                            </Table.Td>
                          </Table.Tr>
                        )
                      })}
                    </Table.Body>
                  </Table>
                </Spacing>
              )}
            </>
          }
        />
      </Paper>
    </>
  )

export const Billing = withResource({
  url: props => `/billings/${props.sale.id}`,
  name: 'billing',
})(BillingUnwrapped)
