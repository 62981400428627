import React from 'react'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'
import { showSuccess } from 'layouts/notifications'
import { path } from 'ramda'
import { request, requestV2 } from 'api/request'
import { Button, Modal } from 'antd'

export class RequestButton extends React.Component {
  state = {
    loading: false,
  }

  handleClick = () => {
    if (this.props.method === 'get' || this.props.unprotected) {
      return this.handleConfirm()
    }
    Modal.confirm({
      title: this.props.title,
      onOk: () => {
        this.handleConfirm()
      },
      onCancel() { },
    })
  }

  handleConfirm = async () => {
    try {
      this.setState({ loading: true })

      const response = await this.makeRequest()

      this.setState({ loading: false }, () => {
        showSuccess(this.props.onSuccessMessage)
        this.props.onSuccess(pathOr(null, ['data', 'data'], response))
      })
    } catch (err) {
      const errMessage = path(['response', 'data', 'status'], err)
        ? `${err.response.data.status}: ${err.response.data.statusText}`
        : err.message

      this.setState({ loading: false }, () => {
        this.props.onError(errMessage)
      })
    }
  }

  makeRequest = () => {
    const options = {
      method: this.props.method,
      url: this.props.url,
    }
    if (this.props.params) {
      options.params = this.props.params
    }
    if (this.props.payload) {
      if (this.props.v2) {
        options.data = this.props.payload
      } else {
        options.data = {
          data: this.props.payload,
        }
      }
    }
    return (this.props.v2 ? requestV2 : request)(options)
  }

  render() {
    const {
      method,
      url,
      content,
      onSuccess,
      onSuccessMessage,
      onError,
      unprotected,
      v2,
      ...rest
    } = this.props

    return (
      <Button {...rest} loading={this.state.loading} onClick={this.handleClick}>
        {content}
      </Button>
    )
  }
}

RequestButton.defaultProps = {
  method: 'get',
  onSuccess: () => { },
  onSuccessMessage: 'Wszystko będzie dobrze!',
  onError: () => { },
  title: 'Na pewno chcesz to zrobić?',
  unprotected: false,
}

RequestButton.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string,
  content: PropTypes.node,
  onSuccess: PropTypes.func,
  onSuccessMessage: PropTypes.string,
  onError: PropTypes.func,
  title: PropTypes.string,
  unprotected: PropTypes.bool,
}
