import React from 'react'
import { Switch, Row, Col } from 'antd'

export const SaleToggleFilters = ({ filterParams, onFiltersChange }) => (
  <Row>
    <Col span={8}>
      <small>Ukryj zapakowane</small>{' '}
      <Switch
        value={filterParams.hidePacked}
        onChange={checked => {
          onFiltersChange({
            ...filterParams,
            hidePacked: checked ? true : undefined,
          })
        }}
      />
    </Col>
  </Row>
)
