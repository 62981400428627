import { takeEvery, put, debounce } from 'redux-saga/effects'
import * as actionTypes from 'redux/actions/constants'
import { request } from 'api/request'
import { store } from '../../index'

function* fetchPrice() {
  try {
    const queue = store.getState().prices.queue

    yield put({
      type: actionTypes.CLEAR_PRICE_REQUEST_QUEUE,
    })

    // TODO group by sales channel code
    const [{ salesChannelCode, currency }] = queue
    const skus = queue.map(item => item.sku)

    const { data } = yield request.get(
      `/products/price/${salesChannelCode}/${currency}/${skus.join(',')}`
    )

    const prices = skus
      .map(sku => data.data.find(item => item.sku === sku) || null)
      .filter(price => !!price)

    yield put({ type: actionTypes.ADD_PRICES, prices })
  } catch (error) {
    console.error(error)
  }
}

function* queuePriceRequest({ sku, salesChannelCode, currency }) {
  yield put({
    type: actionTypes.QUEUE_PRICE_REQUEST,
    sku,
    salesChannelCode,
    currency,
  })
}

export const priceSagas = [
  takeEvery(actionTypes.FETCH_PRICE, queuePriceRequest),
  debounce(100, actionTypes.QUEUE_PRICE_REQUEST, fetchPrice),
]
