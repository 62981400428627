import React from 'react'
import { countryCodes } from 'constants/countryCodes'
import { TextField } from 'components'
import { Input, Select, Typography } from 'antd'

export const RecipientAddress = ({
  recipientAddress,
  handleRecipientAddressChange,
  errors,
}) => (
  <>
    <TextField
      label="Adres 1"
      value={
        <>
          <Input
            value={recipientAddress.addressLine1}
            onChange={e =>
              handleRecipientAddressChange('addressLine1')(e.target.value)
            }
          />
          {errors.addressLine1 && (
            <Typography.Text type="danger">
              {errors.addressLine1}
            </Typography.Text>
          )}
        </>
      }
    />
    <TextField
      label="Adres 2"
      value={
        <>
          <Input
            value={recipientAddress.addressLine2}
            onChange={e =>
              handleRecipientAddressChange('addressLine2')(e.target.value)
            }
          />
          {errors.addressLine2 && (
            <Typography.Text type="danger">
              {errors.addressLine2}
            </Typography.Text>
          )}
        </>
      }
    />
    <TextField
      label="Miasto"
      value={
        <>
          <Input
            value={recipientAddress.city}
            onChange={e => handleRecipientAddressChange('city')(e.target.value)}
          />
          {errors.city && (
            <Typography.Text type="danger">{errors.city}</Typography.Text>
          )}
        </>
      }
    />

    <TextField
      label="Kod pocztowy"
      value={
        <>
          <Input
            value={recipientAddress.postalCode}
            onChange={e =>
              handleRecipientAddressChange('postalCode')(e.target.value)
            }
          />
          {errors.postalCode && (
            <Typography.Text type="danger">{errors.postalCode}</Typography.Text>
          )}
        </>
      }
    />
    <TextField
      label="Kod kraju"
      value={
        <>
          <Select
            placeholder="Kod kraju"
            value={recipientAddress.countryCode}
            onChange={handleRecipientAddressChange('countryCode')}
          >
            {countryCodes.map(code => (
              <Select.Option key={code} value={code}>
                {code}
              </Select.Option>
            ))}
          </Select>
          {errors.countryCode && (
            <Typography.Text type="danger">
              {errors.countryCode}
            </Typography.Text>
          )}
        </>
      }
    />
  </>
)
