import React from 'react'
import { pathOr } from 'ramda'
import { Row, Col, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { TextField, Paper } from 'components'
import { UpdateMeasurementsForm } from './UpdateMeasurementsForm/UpdateMeasurementsForm'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'

const formatValue = (value, unit) => {
  if (!value) {
    return '-'
  }
  return `${value} ${unit}`
}

export class Measurements extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(state => ({ editMode: !state.editMode }))
  }

  normalizeMeasurement = measurement => ({
    sku: pathOr('', ['sku'], measurement),
    width: pathOr('', ['width'], measurement),
    height: pathOr('', ['height'], measurement),
    length: pathOr('', ['length'], measurement),
    weight: pathOr('', ['weight'], measurement),
  })

  render() {
    const { sku } = this.props
    const { editMode } = this.state
    const skuURI = encodeURIComponent(sku)
    return (
      <ResourceProvider
        url={`/products/measurement/${skuURI}`}
        onError={this.props.onErrorRedirect}
        responseNormalizer={this.normalizeMeasurement}
        render={({ data, loading, refresh }) =>
          !loading && (
            <Paper
              title="Wymiary i waga"
              extra={
                <Button
                  icon={<EditOutlined />}
                  type={editMode ? 'primary' : 'dashed'}
                  onClick={this.toggleMode}
                />
              }
            >
              {editMode && (
                <FormProvider
                  url={`/products/measurement/${skuURI}`}
                  method="patch"
                  onSuccessMessage="Pomyślnie zaktualizowano wagę i wymiary"
                  onSuccess={() => {
                    this.toggleMode()
                    refresh()
                  }}
                  render={({ handleSubmit, updating }) => (
                    <UpdateMeasurementsForm
                      data={data}
                      onSubmit={handleSubmit}
                      updating={updating}
                    />
                  )}
                />
              )}
              {!editMode && (
                <Row>
                  <Col lg={12} xs={12}>
                    <TextField
                      label="Wysokość"
                      value={formatValue(data.height, 'mm')}
                    />
                    <TextField
                      label="Szerokość"
                      value={formatValue(data.width, 'mm')}
                    />
                    <TextField
                      label="Długość"
                      value={formatValue(data.length, 'mm')}
                    />
                  </Col>
                  <Col lg={12} xs={12}>
                    <TextField
                      label="Waga"
                      value={formatValue(data.weight, 'g')}
                    />
                  </Col>
                </Row>
              )}
            </Paper>
          )
        }
      />
    )
  }
}
