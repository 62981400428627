import React from 'react'
import { notification } from 'antd'
import { GREEN, RED } from 'layouts/colors'
import { MehOutlined, SmileOutlined } from '@ant-design/icons'

export const showError = (message = 'Coś poszło nie tak.', code = '') => {
  notification.open({
    message: `Błąd! ${code}`,
    description: message,
    icon: <MehOutlined style={{ color: RED }} />,
  })
}

export const showSuccess = (message = 'Wszystko jest git.') => {
  notification.open({
    message: 'Udało się!',
    description: message,
    icon: <SmileOutlined style={{ color: GREEN }} />,
  })
}
