import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { request, requestV2 } from 'api/request'
import { Select, Spin } from 'antd'

export class AsyncSelect extends React.Component {
  lastFetchId = 0

  state = {
    data: [],
    fetching: false,
  }

  componentDidMount = () => {
    this.onSearch(this.props.value)
  }

  onSearch = debounce(async value => {
    if (!this.props.fullList && !value) return
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ data: [], fetching: true })
    const { queryParam } = this.props
    const response = await (this.props.v2 ? requestV2 : request).get(
      `${this.props.url}${queryParam ? `?${queryParam}=` : ''}${!this.props.fullList && value ? value : ''}`
    )
    if (fetchId !== this.lastFetchId) {
      return
    }
    const data = response.data.data.map(this.props.responseNormalizer)
    this.setState({ data, fetching: false })
  }, 300)

  onFocus = () => {
    if (!this.state.initialFetch) {
      this.onSearch()
      this.setState({ initialFetch: true })
    }
  }

  render() {
    const { id, value, disabled } = this.props
    const { fetching, data } = this.state
    return (
      <Select
        data-cy-id={id}
        showSearch
        disabled={disabled}
        value={value || ''}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        onSearch={this.onSearch}
        onFocus={this.onFocus}
        onChange={this.props.onChange}
        style={{ width: '100%' }}
      >
        <Select.Option value="">-</Select.Option>
        {data.map(item => (
          <Select.Option value={item.value} key={item.value} {...item}>
            {item.text}
          </Select.Option>
        ))}
      </Select>
    )
  }
}

AsyncSelect.defaultProps = {
  responseNormalizer: option => ({
    text: option,
    value: option,
  }),
}

AsyncSelect.propTypes = {
  value: PropTypes.any,
  url: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
