import React from 'react'
import { Row, Col, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { TextField, Paper } from 'components'
import { UpdateMeasurementsForm } from './UpdateMeasurementsForm/UpdateMeasurementsForm'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'

const formatValue = (value, unit) => {
  if (!value) {
    return '-'
  }
  return `${value} ${unit}`
}

export class Measurements extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(state => ({ editMode: !state.editMode }))
  }

  render() {
    const { parcel } = this.props
    const { editMode } = this.state
    return (
      <Paper
        title="Wymiary i waga"
        extra={
          <Button
            icon={<EditOutlined />}
            id="parcel_measurement_edit-mode-button"
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        {(editMode || !parcel.measurements) && (
          <FormProvider
            url={`/parcels/${parcel.id}/measure`}
            method="post"
            onSuccessMessage="Pomyślnie zaktualizowano wagę i wymiary"
            onSuccess={() => {
              this.toggleMode()
              this.props.refresh()
            }}
            render={({ handleSubmit, updating }) => (
              <UpdateMeasurementsForm
                data={parcel}
                onSubmit={handleSubmit}
                updating={updating}
              />
            )}
          />
        )}
        {!editMode && parcel.measurements && (
          <Row>
            <Col lg={12} xs={12}>
              <TextField
                label="Wysokość"
                value={formatValue(parcel.measurements.height, 'mm')}
              />
              <TextField
                label="Szerokość"
                value={formatValue(parcel.measurements.width, 'mm')}
              />
              <TextField
                label="Długość"
                value={formatValue(parcel.measurements.length, 'mm')}
              />
            </Col>
            <Col lg={12} xs={12}>
              <TextField
                label="Waga"
                value={formatValue(parcel.measurements.weight, 'g')}
              />
            </Col>
          </Row>
        )}
      </Paper>
    )
  }
}
