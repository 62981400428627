import React from 'react'
import { request } from 'api/request'
import { Paper, SLabel, AsyncSelect, Spacing, Right } from 'components'
import { Row, Col, Button } from 'antd'
import { FulfillmentList } from './FulfillmentList/FulfillmentList'
import { FulfillmentSelector } from './FulfillmentSelector/FulfillmentSelector'
import { showError } from 'layouts/notifications'
import { SyncOutlined } from '@ant-design/icons'

export class PackItems extends React.Component {
  state = {
    loading: false,
    warehouseId: 2,
    fulfillments: [],
    zones: [],
  }

  componentDidMount = async () => {
    this.fetchZones();
    this.setState({ loading: true })
    await Promise.all(
      this.props.parcel.saleIds.map(async saleId => {
        try {
          const response = await request.get(`/fulfillments/${saleId}`)
          this.setState(state => ({
            fulfillments: [...state.fulfillments, response.data.data],
          }))
        } catch { }
      })
    )
    this.setState({ loading: false })
    this.props.onRefresh(this.handleRefresh)
  }

  isFulfilled = fulfillment =>
    fulfillment.remainingToPack.every(item => item.quantity === 0)

  normalizeFulfillment = fulfillment => ({
    ...fulfillment,
    remainingToPack: fulfillment.remainingToPack.map(item => ({
      ...item,
      saleId: fulfillment.id,
      parcelQuantity: item.quantity,
    })),
  })

  addFulfillment = fulfillment => {
    if (this.isFulfilled(fulfillment)) {
      showError('To zamówienie jest w pełni zapakowane')
    } else {
      this.setState(state => ({
        fulfillments: [
          ...state.fulfillments.filter(f => f.id !== fulfillment.id),
          this.normalizeFulfillment(fulfillment),
        ],
      }))
    }
  }

  handleRefresh = async () => {
    this.setState({ loading: true })
    await Promise.all(
      this.state.fulfillments.map(async fulfillment => {
        try {
          const response = await request.get(`/fulfillments/${fulfillment.id}`)
          this.setState(state => ({
            fulfillments: [
              ...state.fulfillments.filter(f => f.id !== fulfillment.id),
              response.data.data,
            ],
          }))
        } catch { }
      })
    )
    this.setState({ loading: false })
  }

  onChange = key => value => {
    this.setState({ [key]: value }, this.fetchZones)
  }

  fetchZones = async () => {
    const { warehouseId } = this.state
    if (!warehouseId) {
      return this.setState({ zones: [] })
    }

    const response = await request.get(`/warehouses/${warehouseId}/zones`)
    if (response) {
      this.setState({
        zones: response.data.data,
      })
    }
  }

  render() {
    const { parcel } = this.props
    const { warehouseId, fulfillments, zones, loading } = this.state
    return (
      <Paper title="Zapakuj produkty">
        <SLabel>Magazyn z którego pakujesz</SLabel>
        <Spacing mb="1.6rem">
          <Row>
            <Col md={12}>
              <AsyncSelect
                value={warehouseId}
                id="parcels_pack-items_warehouseId"
                url="/warehouses/"
                fullList
                responseNormalizer={item => ({
                  text: item.name,
                  value: item.id,
                })}
                onChange={this.onChange('warehouseId')}
              />
            </Col>
            <Col md={12}>
              <Right>
                <Button type="link" onClick={this.refresh}>
                  <SyncOutlined spin={loading} />
                </Button>
              </Right>
            </Col>
          </Row>
        </Spacing>
        <Spacing mb="1.6rem">
          <SLabel>Wybierz zamówienia</SLabel>
          <FulfillmentSelector
            fulfillments={fulfillments}
            addFulfillment={this.addFulfillment}
          />
        </Spacing>
        <FulfillmentList
          fulfillments={fulfillments}
          zones={zones}
          refresh={this.props.refresh}
          parcelId={parcel.id}
          disabled={!warehouseId}
        />
      </Paper>
    )
  }
}
