import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Center } from 'components/Center'
import { Display1 } from 'components/Display1'

export const ErrorPage = props => (
  <MainLayout>
    <Center>
      <Display1>{props.location.state.status}</Display1>
      <h1>{props.location.state.statusText}</h1>
    </Center>
  </MainLayout>
)
