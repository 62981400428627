import React from 'react'
import PropTypes from 'prop-types'
import { fetchComments as fetchCommentsRequest, deleteComment, addComment } from 'api'
import { Comment, Form, Input, Button, Avatar, Spin, Modal } from 'antd'
import { Spacing } from 'components'
import { formatDateTime } from 'utils/formatters'
import { showError, showSuccess } from 'layouts/notifications'
import { compose } from 'recompose'
import { Center } from '../Center'
import { connect } from 'react-redux'
import { Paper } from '../Paper/Paper'

const sortByCreatedAt = array => array.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

const Editor = ({ body, loading, onChange, onSubmit }) => (
  <>
    <Form.Item>
      <Input.TextArea rows={4} placeholder="Dodaj komentarz" value={body} onChange={onChange} />
    </Form.Item>
    <Form.Item>
      <Button loading={loading} disabled={!body} type="primary" onClick={onSubmit}>
        Dodaj komentarz
      </Button>
    </Form.Item>
  </>
)

class CommentsUnwrapped extends React.Component {
  state = {
    comments: [],
    limit: 20,
    offset: 0,
    isFinished: false,
    isLoading: true,
    isPosting: false,
    body: '',
  }

  componentDidMount = async () => {
    await this.fetchComments()
  }

  fetchMore = () => {
    this.setState(
      state => ({
        offset: state.offset + state.limit,
      }),
      this.fetchComments
    )
  }

  fetchComments = async () => {
    const { limit, offset } = this.state
    this.setState({ isLoading: true })
    try {
      const response = await fetchCommentsRequest(this.props.targetId, limit, offset)
      const { data, count } = response.data.data
      if (response.data) {
        this.setState(state => ({
          comments: sortByCreatedAt([...state.comments, ...data]),
          count,
          isFinished: data.length < state.limit,
          isLoading: false,
        }))
      }
    } catch (err) {
      this.setState({
        isLoading: false,
      })
    }
  }

  handleSubmit = async () => {
    this.setState({ isPosting: true })
    const { error } = await addComment(this.props.targetId, this.state.body)
    if (!error) {
      showSuccess('Pomyślnie dodano komentarz')
      this.setState({
        body: '',
        comments: [],
        limit: 20,
        offset: 0,
      }, this.fetchComments)
    } else {
      showError(error)
    }
    this.setState({ isPosting: false })
  }

  handleDelete = async id => {
    const { error } = await deleteComment(id)
    if (!error) {
      showSuccess('Pomyślnie usunięto komentarz')
      this.setState(state => ({
        comments: state.comments.filter(comment => comment.id !== id),
      }))
    } else {
      showError(error)
    }
  }

  handleChage = e => {
    this.setState({
      body: e.target.value,
    })
  }

  render() {
    const { user, label } = this.props
    const { comments, isLoading, isFinished, isPosting } = this.state
    return (
      <Paper title={label}>
        <Comment
          avatar={
            <Avatar shape="square">
              {user.firstName[0]}
              {user.lastName[0]}
            </Avatar>
          }
          content={
            <Editor
              loading={isPosting}
              onChange={this.handleChage}
              body={this.state.body}
              onSubmit={this.handleSubmit}
            />
          }
        />
        {comments.length === 0 && (
          <Spacing ml="5" mb="5">
            Brak komentarzy
          </Spacing>
        )}
        {comments.map(comment => (
          <Comment
            key={comment.id}
            author={comment.emailAddress}
            content={<p>{comment.body}</p>}
            datetime={formatDateTime(comment.createdAt)}
            actions={
              comment.id
                ? [
                  <span
                    className="comment-action"
                    onClick={() => {
                      Modal.confirm({
                        title: 'Na pewno?',
                        content: 'Czy na pewno chcesz usunąć ten komentarz?',
                        okText: 'Potwierdzam',
                        cancelText: 'Anuluj',
                        onOk: async () => {
                          await this.handleDelete(comment.id)
                        },
                      })
                    }}
                  >
                    Usuń
                  </span>,
                ]
                : []
            }
          />
        ))}
        {!isFinished && !isLoading && (
          <Center>
            <Button onClick={this.fetchMore} type="link">
              Pobierz więcej
            </Button>
          </Center>
        )}
        {isLoading && (
          <Center>
            <Spin />
          </Center>
        )}
      </Paper>
    )
  }
}

CommentsUnwrapped.propTypes = {
  targetId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export const Comments = compose(connect(state => ({ user: state.authReducer.user }), null))(CommentsUnwrapped)
