import React from 'react'
import { TextField } from 'components'
import { Typography } from 'antd'
import { pathOr } from 'ramda'

export const Address = ({ address }) => (
  <>
    <TextField
      label="Imię i nazwisko / nazwa"
      value={
        <Typography.Text copyable>
          {pathOr('-', ['name'], address)}
        </Typography.Text>
      }
    />
    <TextField
      label="NIP (org.nr)"
      value={
        <Typography.Text copyable>
          {pathOr('-', ['taxId'], address)}
        </Typography.Text>
      }
    />
    <TextField
      label="Telefon"
      value={
        <Typography.Text copyable>
          {pathOr('-', ['telephone'], address)}
        </Typography.Text>
      }
    />
    <TextField
      label="Adres 1"
      value={
        <Typography.Text copyable>
          {pathOr('-', ['addressLine1'], address)}
        </Typography.Text>
      }
    />
    <TextField
      label="Adres 2"
      value={
        <Typography.Text copyable>
          {pathOr('-', ['addressLine2'], address)}
        </Typography.Text>
      }
    />
    <TextField
      label="Miasto"
      value={
        <Typography.Text copyable>
          {pathOr('-', ['city'], address)}
        </Typography.Text>
      }
    />
    <TextField
      label="Kod pocztowy"
      value={
        <Typography.Text copyable>
          {pathOr('-', ['postalCode'], address)}
        </Typography.Text>
      }
    />
    <TextField
      label="Kraj"
      value={
        <Typography.Text copyable>
          {pathOr('-', ['countryCode'], address)}
        </Typography.Text>
      }
    />
  </>
)
