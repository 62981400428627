import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Button, Input, Select, Typography } from 'antd'
import { Form } from '@ant-design/compatible'
import { Paper, Right, Table, Spacing } from 'components'

class BookInvoiceUnwrapped extends React.Component {
  render() {
    const { invoice, refresh, form } = this.props
    return (
      <Paper title="Zaksięguj fakturę ręcznie">
        <Row>
          <Col md={24}>
            <Spacing mb="1rem">
              <Typography.Text disabled>
                Wygeneruj fakturę w programie księgowym i przenieś dane nowo
                powstałej faktury do poniższego formularza
              </Typography.Text>
            </Spacing>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Spacing mb="2rem">
              <Table>
                <Table.Body>
                  <Table.Tr>
                    <Table.Th>SKU</Table.Th>
                    <Table.Th>Netto</Table.Th>
                    <Table.Th>Ilość</Table.Th>
                  </Table.Tr>
                  {invoice.items.map((item, index) => (
                    <Table.Tr key={index}>
                      <Table.Td>
                        <Typography.Text copyable>{item.sku}</Typography.Text>
                      </Table.Td>
                      <Table.Td>
                        <Typography.Text copyable>
                          {(item.netto / 100.0).toFixed(2)}
                        </Typography.Text>
                      </Table.Td>
                      <Table.Td>
                        <Typography.Text copyable>
                          {item.quantity}
                        </Typography.Text>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Body>
              </Table>
            </Spacing>
          </Col>
        </Row>
        <FormProvider
          url={`/sale-invoices/${invoice.id}/mark-as-booked`}
          form={form}
          onSuccessMessage="Pomyślnie zaksięgowano fakturę!"
          onSuccess={refresh}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Row gutter={16}>
                <Col lg={12} xs={24}>
                  <Form.Item label="Program księgowy">
                    {form.getFieldDecorator('provider', {
                      rules: [
                        { required: true, message: 'Wybierz program księgowy' },
                      ],
                    })(
                      <Select placeholder="Wybierz program księgowy">
                        <Select.Option value="fiken">Fiken</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item label="Numer faktury">
                    {form.getFieldDecorator('number', {
                      rules: [
                        {
                          required: true,
                          message: 'Wpisz numer faktury z programu księgowego',
                        },
                      ],
                    })(<Input placeholder="Numer faktury" />)}
                  </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item label="Link do pdf">
                    {form.getFieldDecorator('uri')(
                      <Input placeholder="Link" />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item>
                  <Right>
                    <Button type="primary" htmlType="submit">
                      Oznacz jako zaksięgowaną
                    </Button>
                  </Right>
                </Form.Item>
              </Row>
            </Form>
          )}
        />
      </Paper>
    )
  }
}

export const BookInvoice = compose(
  withRouter,
  Form.create({ name: 'book-invoice-form' })
)(BookInvoiceUnwrapped)
