import React from 'react'
import { Popover } from 'antd'
import { displayPrice } from 'utils/prices'
import { SContainer } from './SContainer'
import { STd } from './STd'

const exchange = (value, exchangeRate = 1) => Math.round(value * exchangeRate)

const getContent = value => (
  <table style={{ width: '100%' }}>
    <tbody>
      <tr>
        <STd>
          <strong>NOK</strong>
        </STd>
        <STd>2.28</STd>
        <STd>{displayPrice(exchange(value, 2.28))}</STd>
      </tr>
      <tr>
        <STd>
          <strong>EUR</strong>
        </STd>
        <STd>0.23</STd>
        <STd>{displayPrice(exchange(value, 0.23))}</STd>
      </tr>
    </tbody>
  </table>
)

export const Price = ({ value, currency }) => (
  <Popover
    content={getContent(value, currency)}
    title="Przewalutowanie"
    trigger="click"
  >
    <SContainer>
      {displayPrice(value)} {currency}
    </SContainer>
  </Popover>
)
