import React from 'react'
import { Paper, Spacing } from 'components'
import { InputNumber } from 'antd'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { displayPrice, getBrutto } from 'utils/prices'

export class SetDeliveryCost extends React.Component {
  state = {
    amount: null,
  }

  handleChange = amount => {
    this.setState({
      amount,
    })
  }

  render() {
    const { sale, refresh } = this.props
    const { amount } = this.state
    return (
      <Paper title={`Manualnie zmień cenę wysyłki (netto w ${sale.currency})`}>
        <div>
          <InputNumber
            style={{ width: '30%' }}
            value={amount}
            placeholder={sale.currency}
            min={0}
            onChange={this.handleChange}
            formatter={value => (value || '').replace(/\B(?=(\d{2}$))/g, ',')}
            parser={value => (value || '').replace(/\$\s?|(,*)/g, '')}
          />
          <Spacing ml="1rem" display="inline-block">
            <RequestButton
              onSuccess={refresh}
              url={`/carts/${sale.id}/set-delivery-cost`}
              method="post"
              type="primary"
              disabled={amount === null}
              content="Zapisz"
              payload={{
                amount,
              }}
              title="Czy na pewno chcesz manualnie zmienić cenę wysyłki dla tego zamówienia?"
            />
          </Spacing>
        </div>
        <div>
          {/* Hardcoded vatRate */}
          <small>
            Brutto:{' '}
            {amount !== null ? (
              <strong>
                {sale.currency} {displayPrice(getBrutto(amount, 25))}
              </strong>
            ) : (
              '-'
            )}
          </small>
        </div>
      </Paper>
    )
  }
}
