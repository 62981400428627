import styled from 'styled-components'

export const Spacing = styled.div`
  ${({ display }) => display && `display: ${display}`}
  ${({ mt }) => mt && `margin-top: ${mt}`}
  ${({ mb }) => mb && `margin-bottom: ${mb}`}
  ${({ ml }) => ml && `margin-left: ${ml}`}
  ${({ mr }) => mr && `margin-right: ${mr}`}
  ${({ pt }) => pt && `padding-top: ${pt}`}
  ${({ pb }) => pb && `padding-bottop: ${pb}`}
  ${({ pl }) => pl && `padding-left: ${pl}`}
  ${({ pr }) => pr && `padding-right: ${pr}`}
`
