import React from 'react'
import { Input, Select } from 'antd'

export class TableSearch extends React.Component {
  state = {
    options: this.props.options,
    queryField:
      this.props.filterParams.queryField || Object.keys(this.props.options)[0],
  }

  handleSelectChange = queryField => {
    this.setState({
      queryField,
    })
  }

  handleSubmit = query => {
    this.props.onFiltersChange({
      ...this.getPristineFilterParams(),
      [this.state.queryField]: query,
    })
  }

  getPristineFilterParams = () => {
    const optionKeys = Object.keys(this.props.options)
    return Object.entries(this.props.filterParams).reduce((acc, curr) => {
      if (optionKeys.includes(curr[0])) {
        return acc
      }
      return {
        ...acc,
        [curr[0]]: curr[1],
      }
    }, {})
  }

  render() {
    const { options } = this.props
    const { queryField } = this.state

    return (
      <Input.Group compact>
        <Select value={queryField} onChange={this.handleSelectChange}>
          {Object.entries(options).map(([key, label]) => (
            <Select.Option key={key} value={key}>
              {label}
            </Select.Option>
          ))}
        </Select>
        <Input.Search
          placeholder="Szukaj..."
          onSearch={this.handleSubmit}
          enterButton
          style={{ width: '60%' }}
        />
      </Input.Group>
    )
  }
}
