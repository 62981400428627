import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TextField, Paper, SaleStatus, LinkButton, Spacing, PrintQr } from 'components'
import { formatDateTime } from 'utils/formatters'
import { Typography } from 'antd'

const paymentReminder = sale => `Hei ☀️,

Bestillingen din #${sale.number} er under behandling men er ikke akseptert enda pga betalingen.
For at delen skal kunne sendes må bestillingen være godkjent.
Trykk på linken nedenfor og betal med kort for å gjennomføre kjøpet ditt.
https://geparden.no/orders/${sale.id}

Dersom du ikke vil ha varene alikevel svar på denne mailen med "Jeg ønsker ikke varene mine".

Kontakt oss om du har spørsmål eller noe du vil vi skal se på 🚀
Linken til chatten: https://direct.lc.chat/11908521/`

export const SaleHeader = ({ sale }) => (
  <Paper
    title="Informacje"
    extra={
      <>
        <CopyToClipboard text={`https://geparden.no/orders/${sale.id}`}>
          <LinkButton type="link">Link klienta</LinkButton>
        </CopyToClipboard>
        <Spacing ml="1rem" display="inline">
          <CopyToClipboard
            text={`https://geparden.no/orders/${sale.id}?preview=true`}
          >
            <LinkButton type="link">Podgląd klienta</LinkButton>
          </CopyToClipboard>
        </Spacing>
      </>
    }
  >
    <TextField
      label="ID"
      id="sale_id"
      value={<><Typography.Text copyable>{sale.id}</Typography.Text> <PrintQr value={sale.id} /></>}
    />
    <TextField
      label="Numer"
      value={<><Typography.Text copyable>{sale.number}</Typography.Text> <PrintQr value={sale.number} /></>}
    />
    <TextField
      label="E-mail"
      value={
        sale.emailAddress ? (
          <Typography.Text copyable>{sale.emailAddress}</Typography.Text>
        ) : (
          '-'
        )
      }
    />
    <TextField label="Waluta" value={sale.currency} />
    <TextField label="Kanał sprzedaży" value={sale.salesChannelCode} />
    <TextField
      label="Status"
      id="sale_status"
      value={<SaleStatus status={sale.status} />}
    />
    <TextField label="Ilość zdarzeń" value={sale.eventsCount.toString()} />
    <TextField label="Utworzono" value={formatDateTime(sale.createdAt)} />
    <TextField label="Edytowano" value={formatDateTime(sale.updatedAt)} />
    <TextField
      label="Szablony e-mail"
      value={
        <>
          <CopyToClipboard text={paymentReminder(sale)}>
            <LinkButton type="link">Ponaglenie do zapłaty</LinkButton>
          </CopyToClipboard>
        </>
      }
    />
  </Paper>
)
