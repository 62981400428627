export const INITIALIZED = 'INITIALIZED'
export const ACTIVE = 'ACTIVE'
export const ORDERED = 'ORDERED'
export const ACCEPTED = 'ACCEPTED'
export const REJECTED = 'REJECTED'
export const PROCESSING = 'PROCESSING'
export const SHIPPING = 'SHIPPING'
export const DELIVERED = 'DELIVERED'
export const CANCELLED = 'CANCELLED'
export const COMPLETED = 'COMPLETED'

const sequence = [
  INITIALIZED,
  ACTIVE,
  ORDERED,
  ACCEPTED,
  REJECTED,
  PROCESSING,
  SHIPPING,
  DELIVERED,
  CANCELLED,
  COMPLETED,
]

export const isBefore = (currentStatus, status) =>
  sequence.indexOf(currentStatus) < sequence.indexOf(status)

export const isAfter = (currentStatus, status) =>
  sequence.indexOf(currentStatus) > sequence.indexOf(status)

export const isEqual = (currentStatus, status) => currentStatus === status
export const isNot = (currentStatus, status) => currentStatus !== status
