import React from 'react'
import { compose } from 'recompose'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Input, Button, Select } from 'antd'
import { Form } from '@ant-design/compatible'

export const ZoneFormUnwrapped = ({ form, refresh, warehouses = [] }) => (
  <FormProvider
    url="/zones"
    form={form}
    onSuccessMessage="Pomyślnie dodano strefę magazynu!"
    onSuccess={refresh}
    render={({ handleSubmit }) => (
      <Form onSubmit={handleSubmit}>
        <Row gutter={16}>
          <Col xxl={8} xl={8} lg={8} xs={24}>
            <Form.Item label="Kod strefy">
              {form.getFieldDecorator('name', {
                rules: [{ required: true, message: 'Podaj kod strefy' }],
              })(<Input placeholder="Kod" />)}
            </Form.Item>
          </Col>
          <Col xxl={8} xl={8} lg={8} xs={24}>
            <Form.Item label="Magazyn">
              {form.getFieldDecorator('warehouseId', {
                rules: [
                  {
                    required: true,
                    message: 'Wybierz magazyn!',
                  },
                ],
              })(
                <Select placeholder="Magazyn">
                  {warehouses.map(warehouse => (
                    <Select.Option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Dodaj
            </Button>
          </Form.Item>
        </Row>
      </Form>
    )}
  />
)

export const ZoneForm = compose(Form.create({ name: 'zone-create-form' }))(
  ZoneFormUnwrapped
)
