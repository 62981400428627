import React from 'react'
import { Paper } from 'components'
import { RequestButton } from 'components/RequestButton/RequestButton'

export const IsSoldOut = ({ product, refresh, disabled }) => (
  <Paper title="Obecność w ofercie">
    {!product.isSoldOut && (
      <RequestButton
        onSuccess={refresh}
        url={`/products/sku/${encodeURIComponent(product.sku)}`}
        method="patch"
        type="danger"
        content="Oznacz jako wyprzedany"
        disabled={disabled}
        payload={{
          isSoldOut: true,
        }}
        title="Czy na pewno chcesz oznaczyć ten produkt jako wyprzedany?"
      />
    )}
    {product.isSoldOut && (
      <RequestButton
        onSuccess={refresh}
        url={`/products/sku/${encodeURIComponent(product.sku)}`}
        method="patch"
        type="danger"
        content="Przywróć do oferty"
        disabled={disabled}
        payload={{
          isSoldOut: false,
        }}
        title="Czy na pewno chcesz przywrócić produkt do oferty?"
      />
    )}
  </Paper>
)
