import React from 'react'
import { SLabel } from '../SLabel/SLabel'
import { SContainer } from './SContainer'

export const TextField = ({ id, label, value, mb, placeholder, ...rest }) => (
  <SContainer mb={mb}>
    <SLabel>{label}</SLabel>
    <span id={id} {...rest}>
      {!value || (Array.isArray(value) && value.length === 0)
        ? placeholder
        : value}
    </span>
  </SContainer>
)

TextField.defaultProps = {
  placeholder: '-',
}
