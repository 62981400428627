export const WHITE = '#fff'
export const BLUE = '#1890ff'
export const DARK_BLUE = '#002142'
export const DARKER_BLUE = '#001629'
export const GREEN = '#87d068'
export const RED = '#f5222d'
export const RED_LIGHT = '#ffa39e'
export const RED_LIGHTER = '#fff1f0'
export const GREY = '#eff2f5'
export const GREY_DARK = '#e8e8e8'
export const GREY_DARKER = '#d9d9d9'
export const GREY_DARKEST = '#898989'
export const BLACK = '#000000d9'
export const GRAY = '#9a9a9a'

export const grey = [
  '#ffffff',
  '#fafafa',
  '#f5f5f5',
  '#e8e8e8',
  '#d9d9d9',
  '#bfbfbf',
  '#8c8c8c',
  '#595959',
  '#262626',
  '#000000',
]
