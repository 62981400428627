import React from 'react'
import { Col, Button, Row, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Paper, Align, Spacing, AsyncSelect, SLabel } from 'components'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { fetchProduct } from 'api'

const normalizeParams = params =>
  Object.entries(params || {}).map(entry => ({
    key: entry[0],
    value: entry[1],
  }))

export class ProductParams extends React.Component {
  state = {
    params: normalizeParams(this.props.product.params),
    pristineParams: normalizeParams(this.props.product.params),
  }

  add = () => {
    this.setState(state => ({
      params: [
        ...state.params,
        {
          key: '',
          value: '',
        },
      ],
    }))
  }

  remove = index => () => {
    this.setState(state => ({
      params: [
        ...state.params.slice(0, index),
        ...state.params.slice(index + 1),
      ],
    }))
  }

  onSuccess = () => {
    this.props.refresh()
  }

  onKeyChange = index => e => {
    const key = e.target.value
    this.setState(state => {
      state.params[index] = {
        ...state.params[index],
        key,
      }
      return {
        ...state,
      }
    })
  }

  onValueChange = index => e => {
    const value = e.target.value
    this.setState(state => {
      state.params[index] = {
        ...state.params[index],
        value,
      }
      return {
        ...state,
      }
    })
  }

  isDisabled = () => {
    const { params, pristineParams } = this.state
    if (params.length !== pristineParams.length) return false
    for (let i = 0; i < params.length; i++) {
      if (params[i].key !== pristineParams[i].key) {
        return false
      }
      if (params[i].value !== pristineParams[i].value) {
        return false
      }
    }
    return true
  }

  getPayload = () =>
    this.state.params.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.key]: curr.value,
      }),
      {}
    )

  reset = () => {
    this.setState(state => ({
      params: state.pristineParams,
    }))
  }

  render() {
    const { product } = this.props
    const { params } = this.state
    return (
      <Paper title="Parametry produktu">
        <FormProvider
          url={`/products/sku/${encodeURIComponent(product.sku)}`}
          method="patch"
          onSuccessMessage="Pomyślnie zmieniono parametry produktu"
          onSuccess={this.onSuccess}
          render={({ handleSubmit }) => (
            <Row gutter={{ md: 8 }}>
              {params.map((param, idx) => (
                <React.Fragment key={idx}>
                  <Col sm={9}>
                    <Spacing mb="0.5rem">
                      <Input
                        value={param.key}
                        onChange={this.onKeyChange(idx)}
                      />
                    </Spacing>
                  </Col>
                  <Col sm={12}>
                    <Spacing mb="0.5rem">
                      <Input
                        value={param.value}
                        onChange={this.onValueChange(idx)}
                      />
                    </Spacing>
                  </Col>
                  <Col sm={3}>
                    <Spacing mb="0.5rem">
                      <Button type="link" onClick={this.remove(idx)}>
                        Usuń
                      </Button>
                    </Spacing>
                  </Col>
                </React.Fragment>
              ))}
              <Col md={24}>
                <Spacing mb="1rem">
                  <Button
                    type="dashed"
                    onClick={this.add}
                    style={{ width: '100%' }}
                  >
                    <PlusOutlined /> Dodaj parametr
                  </Button>
                </Spacing>
              </Col>
              <Col md={24}>
                <div style={{ maxWidth: 400 }}>
                  <SLabel>Pobierz od</SLabel>
                  <AsyncSelect
                    id="sale-items-table_sku-selector"
                    url="/products"
                    queryParam="sku"
                    onChange={async sku => {
                      try {
                        const { data } = await fetchProduct(sku)
                        if (!data || !data.data) {
                          return
                        }
                        const product = data.data
                        this.setState(state => ({
                          params: [
                            ...state.params,
                            ...normalizeParams(product.params),
                          ],
                        }))
                      } catch (err) { }
                    }}
                    placeholder="SKU"
                    responseNormalizer={item => ({
                      text: item.sku,
                      value: item.sku,
                    })}
                  />
                </div>
                <Align value="right">
                  <Spacing display="inline-block" mr="0.2rem">
                    <Button disabled={this.isDisabled()} onClick={this.reset}>
                      Cofnij
                    </Button>
                  </Spacing>
                  <Button
                    type="primary"
                    disabled={this.isDisabled()}
                    onClick={() => {
                      handleSubmit({
                        params: this.getPayload(),
                      })
                    }}
                  >
                    Zapisz
                  </Button>
                </Align>
              </Col>
            </Row>
          )}
        />
      </Paper>
    )
  }
}
