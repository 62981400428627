import React from 'react'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Paper } from 'components'
import { withResource } from 'hocs/withResource'
import { ProductImagesSelector } from './ProductImagesSelector/ProductImagesSelector'
import { SKUImagesTable } from './SKUImagesTable/SKUImagesTable'

class ProductImagesUnwrapped extends React.Component {
  state = {
    isSelectorOpen: false,
  }

  toggleSelector = () => {
    this.setState(state => ({ isSelectorOpen: !state.isSelectorOpen }))
  }

  render() {
    const { productImages, sku } = this.props
    const { isSelectorOpen } = this.state
    return (
      <>
        <Paper
          title="Zdjęcia"
          extra={
            <Button
              icon={<EditOutlined />}
              type="dashed"
              onClick={this.toggleSelector}
            />
          }
        >
          <SKUImagesTable
            productImages={productImages.data || []}
            sku={sku}
            refresh={productImages.refresh}
          />
        </Paper>
        <ProductImagesSelector
          isSelectorOpen={isSelectorOpen}
          productImages={productImages.data || []}
          sku={sku}
          onClose={this.toggleSelector}
          refresh={productImages.refresh}
        />
      </>
    )
  }
}

export const ProductImages = withResource({
  name: 'productImages',
  url: props => `/products/${encodeURIComponent(props.sku)}/images`,
})(ProductImagesUnwrapped)
