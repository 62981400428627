import React from 'react'
import { Checkbox, Row, Col } from 'antd'

export const SaleFilters = ({ filterParams, onFiltersChange }) => (
  <Checkbox.Group
    style={{ width: '100%' }}
    value={filterParams.status.split(',')}
    onChange={values => {
      onFiltersChange({ ...filterParams, status: values.join(',') })
    }}
  >
    <Row>
      <Col span={8}>
        <Checkbox
          data-cy-id="sale-filters_checkbox_INITIALIZED"
          value="INITIALIZED"
        >
          Puste
        </Checkbox>
        <br />
        <Checkbox data-cy-id="sale-filters_checkbox_ACTIVE" value="ACTIVE">
          Koszyki
        </Checkbox>
      </Col>
      <Col span={8}>
        <Checkbox data-cy-id="sale-filters_checkbox_ORDERED" value="ORDERED">
          Zamówione
        </Checkbox>
        <br />
        <Checkbox data-cy-id="sale-filters_checkbox_ACCEPTED" value="ACCEPTED">
          Zaakceptowane
        </Checkbox>
      </Col>
      <Col span={8}>
        <Checkbox
          data-cy-id="sale-filters_checkbox_CANCELLED"
          value="CANCELLED"
        >
          Anulowane
        </Checkbox>
        <br />
        <Checkbox
          data-cy-id="sale-filters_checkbox_COMPLETED"
          value="COMPLETED"
        >
          Zakończone
        </Checkbox>
      </Col>
    </Row>
  </Checkbox.Group>
)
