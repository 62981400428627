import { ADD_PRODUCT } from 'redux/actions/constants'

const defaultState = {
  products: {},
}

export const productReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        products: {
          ...state.products,
          [action.product.sku]: action.product,
        },
      }
    default:
      return state
  }
}
