import React from 'react'
import { Paper } from 'components'
import { RequestButton } from 'components/RequestButton/RequestButton'

export const BookFikenInvoice = ({ invoice, refresh }) => (
  <Paper title="Zaksięguj w Fiken">
    <RequestButton
      onSuccess={refresh}
      url={`/sale-invoices/${invoice.id}/book`}
      method="post"
      type="danger"
      content="Zaksięguj w Fiken"
      title="Czy na pewno chcesz wprowdzić tą fakturę do Fiken?"
    />
  </Paper>
)
