import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { authenticate } from 'api'
import { signedIn } from 'redux/actions'
import { Input, Button } from 'antd'
import { Form } from '@ant-design/compatible'
import { showError } from 'utils/showError'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

export class LoginFormUnwrapped extends React.Component {
  state = {
    loading: false,
  }

  componentDidMount = () => {
    localStorage.removeItem('auth_token')
  }

  makeAuthenticationRequest = async ({ emailAddress, password }) => {
    this.setState({ loading: true })
    try {
      const response = await this.props.authenticate({ emailAddress, password })
      const { token, user } = response.data
      if (token) {
        this.setState({ loading: false })
        this.props.signedIn({
          token,
          user,
        })
        this.props.history.push('/')
      } else {
        showError()
      }
    } catch (err) {
      this.setState({ loading: false })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, { emailAddress, password }) => {
      if (!err) {
        this.makeAuthenticationRequest({ emailAddress, password })
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item>
          {getFieldDecorator('emailAddress', {
            rules: [{ required: true, message: 'Wpisz swój adres e-mail.' }],
          })(
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Adres e-mail"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Hasło jest wymagane.' }],
          })(
            <Input
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Hasło"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Zaloguj się
          </Button>
        </Form.Item>
      </Form>
    )
  }
}

LoginFormUnwrapped.defaultProps = {
  authenticate,
}

LoginFormUnwrapped.propTypes = {
  authenticate: PropTypes.func,
  setAuthToken: PropTypes.func,
}

const mapDispatchToProps = {
  signedIn,
}

export const LoginForm = compose(
  connect(undefined, mapDispatchToProps),
  withRouter,
  Form.create()
)(LoginFormUnwrapped)
