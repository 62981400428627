import React from 'react'
import { compose } from 'recompose'
import { Col, Button, Input, Row, Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { TextField, Paper } from 'components'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'

export class ProductDescriptionsUnwrapped extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(
      state => ({ editMode: !state.editMode }),
      () => {
        if (this.state.editMode) {
          this.props.form.setFieldsValue({
            namePL: this.props.product.namePL || '',
            nameNO: this.props.product.nameNO || '',
            nameEN: this.props.product.nameEN || '',
            descriptionPL: this.props.product.descriptionPL || '',
            descriptionNO: this.props.product.descriptionNO || '',
            descriptionEN: this.props.product.descriptionEN || '',
          })
        }
      }
    )
  }

  onSuccess = () => {
    this.toggleMode()
    this.props.refresh()
  }

  render() {
    const { editMode } = this.state
    const { form, product } = this.props
    return (
      <Paper
        title="Nazwa i opis"
        extra={
          <Button
            icon={<EditOutlined />}
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        {editMode && (
          <FormProvider
            url={`/products/sku/${encodeURIComponent(product.sku)}`}
            method="patch"
            form={form}
            onSuccessMessage="Pomyślnie dodano nowy produkt!"
            onSuccess={this.onSuccess}
            render={({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row gutter={{ md: 16 }}>
                  <Col md={24}>
                    <Typography.Text strong>
                      <small>PL</small>
                    </Typography.Text>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Nazwa PL">
                      {form.getFieldDecorator('namePL', {
                        rules: [
                          { max: 255, message: 'Maksymalnie 255 znaków' },
                        ],
                      })(<Input placeholder="Nazwa PL" />)}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Opis PL">
                      {form.getFieldDecorator('descriptionPL')(
                        <Input.TextArea rows={3} placeholder="Opis PL" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Typography.Text strong>
                      <small>NO</small>
                    </Typography.Text>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Nazwa NO">
                      {form.getFieldDecorator('nameNO', {
                        rules: [
                          { max: 255, message: 'Maksymalnie 255 znaków' },
                        ],
                      })(<Input placeholder="Nazwa NO" />)}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Opis NO">
                      {form.getFieldDecorator('descriptionNO')(
                        <Input.TextArea rows={3} placeholder="Opis NO" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Typography.Text strong>
                      <small>EN</small>
                    </Typography.Text>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Nazwa EN">
                      {form.getFieldDecorator('nameEN', {
                        rules: [
                          { max: 255, message: 'Maksymalnie 255 znaków' },
                        ],
                      })(<Input placeholder="Nazwa EN" />)}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Opis EN">
                      {form.getFieldDecorator('descriptionEN')(
                        <Input.TextArea rows={3} placeholder="Opis EN" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          />
        )}
        {!editMode && (
          <>
            <Row gutter={{ md: 16 }}>
              <Col md={24}>
                <Typography.Text strong>
                  <small>PL</small>
                </Typography.Text>
              </Col>
              <Col md={24}>
                <TextField label="Nazwa PL" value={product.namePL} />
              </Col>
              <Col md={24}>
                <TextField label="Opis PL" value={product.descriptionPL} />
              </Col>
            </Row>
            <Row gutter={{ md: 16 }}>
              <Col md={24}>
                <Typography.Text strong>
                  <small>NO</small>
                </Typography.Text>
              </Col>
              <Col md={24}>
                <TextField label="Nazwa NO" value={product.nameNO} />
              </Col>
              <Col md={24}>
                <TextField label="Opis NO" value={product.descriptionNO} />
              </Col>
            </Row>
            <Row gutter={{ md: 16 }}>
              <Col md={24}>
                <Typography.Text strong>
                  <small>EN</small>
                </Typography.Text>
              </Col>
              <Col md={24}>
                <TextField label="Nazwa EN" value={product.nameEN} />
              </Col>
              <Col md={24}>
                <TextField label="Opis EN" value={product.descriptionEN} />
              </Col>
            </Row>
          </>
        )}
      </Paper>
    )
  }
}

export const ProductDescriptions = compose(
  Form.create({ name: 'product-descriptions-edit-form' })
)(ProductDescriptionsUnwrapped)
