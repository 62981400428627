import React from 'react'
import { Paper } from 'components'
import * as saleStatus from '../saleStatus'
import { CreateCartForm } from './CreateCartForm/CreateCartForm'
import { EditCartForm } from './EditCartForm/EditCartForm'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'

const getTitle = status => {
  switch (status) {
    case saleStatus.INITIALIZED:
      return 'Utwórz koszyk'
    case saleStatus.ACTIVE:
      return 'Edytuj koszyk'
    default:
      return 'Koszyk'
  }
}

let refreshKey = 0

const renderContent = (sale, handleSubmit, posting) => {
  switch (sale.status) {
    case saleStatus.INITIALIZED:
      return (
        <CreateCartForm
          key={refreshKey++}
          sale={sale}
          handleSubmit={handleSubmit}
          posting={posting}
        />
      )
    case saleStatus.ACTIVE:
      return (
        <EditCartForm
          key={refreshKey++}
          sale={sale}
          handleSubmit={handleSubmit}
          posting={posting}
        />
      )
    default:
      return null
  }
}

export const Cart = ({ sale, refresh }) => (
  <Paper title={getTitle(sale.status)}>
    <FormProvider
      diff={false}
      url={`/carts/${sale.id}`}
      onSuccessMessage="Pomyślnie zaktualizowano koszyk!"
      onSuccess={refresh}
      mapFormDataToPayload={items => ({ items: items })}
      render={({ handleSubmit, updating }) =>
        renderContent(sale, handleSubmit, updating)
      }
    />
  </Paper>
)
