import React from 'react'
import { Modal, Typography } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { CategoryTree } from './CategoryTree/CategoryTree'

const CATEGORY_SLUG = 'geparden-01' // TODO dropdown

export const ProductCategorySelector = ({
  sku,
  onClose,
  isSelectorOpen,
  productCategories,
  refresh,
}) => (
  <Modal
    width="80%"
    title="Kategorie towarów"
    visible={isSelectorOpen}
    onCancel={onClose}
    footer={[]}
  >
    <ResourceProvider
      url={`/category/${CATEGORY_SLUG}`}
      render={({ data, loading }) =>
        !loading && (
          <>
            <Typography.Title level={4}>{CATEGORY_SLUG}</Typography.Title>
            <CategoryTree
              selectedIDs={productCategories.map(cat => cat.uuid) || []}
              categories={data}
              refresh={refresh}
              sku={sku}
            />
          </>
        )
      }
    />
  </Modal>
)
