import styled from 'styled-components'

export const SLogo = styled.h3`
  cursor: default;
  color: white;
  display: inline-block;
  margin: 0;
  font-size: 22px;
  line-height: 62px;
  background: #002041;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-family: "Avenir";
`
