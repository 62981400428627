import { takeEvery, put } from 'redux-saga/effects'
import * as actionTypes from 'redux/actions/constants'
import { request } from 'api/request'

function* fetchProduct({ sku }) {
  try {
    const { data } = yield request.get(
      `/products/sku/${encodeURIComponent(sku)}`
    )
    yield put({ type: actionTypes.ADD_PRODUCT, product: data.data })
  } catch (error) {
    const { status } = error.response
    yield put({
      type: actionTypes.ADD_PRODUCT,
      product: { sku, errorStatus: status },
    })
  }
}

export const productSagas = [takeEvery(actionTypes.FETCH_PRODUCT, fetchProduct)]
