import styled from 'styled-components'
import { BLUE, GRAY } from 'layouts/colors'

export const SLinkButton = styled.button`
  background: none !important;
  border: none;
  padding: 0 !important;
  font: inherit;
  cursor: pointer;
  margin: 0;
  outline: none;
  color: ${({ color }) => color || BLUE};
  ${({ mr }) => (mr ? `margin-right: ${mr};` : '')}
  ${({ ml }) => (ml ? `margin-right: ${ml};` : '')}
  ${({ disabled }) =>
    disabled
      ? `
    color: ${GRAY};
    cursor: not-allowed;
  `
      : ''}
`
