import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Select, Button } from 'antd'
import { Form } from '@ant-design/compatible'
import { Paper, Right } from 'components'
import { withSuppliers } from 'hocs/withSuppliers'
import { CreateSupplierModal } from './CreateSupplierModal/CreateSupplierModal'


const PurchaseOrderCreateUnwrapped = ({ history, form, suppliers, suppliersRefresh }) => (
  <MainLayout header="Dodaj nowe zlecenie" backgroundColor="none" marginTop="0">
    <FormProvider
      v2
      url="/purchase-orders"
      form={form}
      onSuccessMessage="Pomyślnie dodano nowe zlecenie!"
      onSuccess={(id) => {
        history.push(`/purchase-orders/${id}`)
      }}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} id="create-product-form">
          <Row>
            <Col xxl={12} xl={20} lg={24} xs={24}>
              <Row type="flex" justify="space-between">
                <Col lg={24} xs={24}>
                  <Paper title="Informacje podstawowe">
                    <Right>
                      <CreateSupplierModal onSuccess={suppliersRefresh} />
                    </Right>
                    <Form.Item label="Dostawca">
                      {form.getFieldDecorator('supplierId')(
                        <Select
                          virtual={false}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) => (option.children.toUpperCase()).includes(input.toUpperCase())}
                          placeholder="Dostawca"
                          style={{ width: '100%' }}
                        >
                          {suppliers.map((supplier, index) => (
                            <Select.Option
                              key={index}
                              value={supplier.id}
                            >
                              {supplier.address.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Paper>
                  <Row>
                    <Form.Item>
                      <Right>
                        <Button type="primary" htmlType="submit">
                          Dodaj
                        </Button>
                      </Right>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    />
  </MainLayout>
)

export const PurchaseOrderCreate = compose(
  withRouter,
  withSuppliers,
  Form.create({ name: 'purchase-order-create-form' })
)(PurchaseOrderCreateUnwrapped)
