import React from 'react'
import { Button, Modal, Typography } from 'antd'
import { TextField } from 'components'
import { formatCurrency } from 'utils/formatters'
import { RefundPayment } from './RefundPayment/RefundPayment'

export class StripePaymentDetailsModal extends React.Component {
  state = {
    isOpen: false,
  }

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  render() {
    const {
      saleId,
      refresh,
      paymentId,
      type,
      status,
      value,
      currency,
      capturedAmount,
      refundedAmount,
    } = this.props
    const { isOpen } = this.state
    return (
      <>
        <Button onClick={this.toggleModal} style={{ padding: 0 }} type="link">
          {paymentId.slice(0, 6)}
        </Button>
        <Modal
          title="Szczegóły płatności"
          visible={isOpen}
          onCancel={this.toggleModal}
          footer={[]}
        >
          <TextField
            label="ID"
            id="payment_id"
            value={
              <Typography.Text copyable>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://dashboard.stripe.com/payments/${paymentId}`}
                >
                  {paymentId}
                </a>
              </Typography.Text>
            }
          />
          <TextField label="Status" value={<>{status}</>} />
          <TextField label="Typ" value={type} />
          <TextField label="Wartość" value={formatCurrency(value, currency)} />
          <TextField
            label="Pobrano"
            value={formatCurrency(capturedAmount, currency)}
          />
          <TextField
            label="Zwrócono"
            value={formatCurrency(refundedAmount, currency)}
          />
          <TextField
            label="Zwróć płatność"
            value={
              <RefundPayment
                refresh={refresh}
                saleId={saleId}
                paymentId={paymentId}
                capturedAmount={capturedAmount}
                value={value}
                refundedAmount={refundedAmount}
              />
            }
          />
        </Modal>
      </>
    )
  }
}
