import React from 'react'
import { Table } from 'components'
import { PackItem } from './PackItem/PackItem'

export const FulfillmentList = ({
  fulfillments,
  parcelId,
  refresh,
  zones,
  disabled,
}) => (
  <Table>
    <Table.Body>
      <Table.Tr>
        <Table.Th>Zamówienie</Table.Th>
        <Table.Th>SKU</Table.Th>
        <Table.Th>Do zapakowania</Table.Th>
        <Table.Th>Skąd towar?</Table.Th>
        <Table.Th>Ilość</Table.Th>
        <Table.Th />
      </Table.Tr>
      {fulfillments.map(fulfillment =>
        fulfillment.remainingToPack.map(item => (
          <PackItem
            disabled={disabled || item.quantity === 0}
            key={`${item.saleId}.${item.sku}`}
            remainingToPack={item.quantity}
            sku={item.sku}
            saleId={fulfillment.id}
            parcelId={parcelId}
            saleNumber={fulfillment.number}
            refresh={refresh}
            zones={zones}
          />
        ))
      )}
    </Table.Body>
  </Table>
)
