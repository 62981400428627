import React from 'react'
import { compose } from 'recompose'
import { Col, Button, Input, Row } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { TextField, Paper, RequestButton, Right } from 'components'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { PurchaseOrderStatus } from 'components/Status/Status'

export class PurchaseOrderDetailsUnwrapped extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(
      state => ({ editMode: !state.editMode }),
      () => {
        if (this.state.editMode) {
          this.props.form.setFieldsValue({
            orderNumber: this.props.data.orderNumber || '',
            invoiceNumber: this.props.data.invoiceNumber || '',
            uri: this.props.data.uri || '',
          })
        }
      }
    )
  }

  onSuccess = () => {
    this.toggleMode()
    this.props.refresh()
  }

  render() {
    const { editMode } = this.state
    const { form, data, refresh } = this.props
    return (
      <Paper
        title="Zamówienie"
        extra={
          <Button
            icon={<EditOutlined />}
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        <Right>
          {!data.isFlagged ? (
            <RequestButton
              content="Oflaguj"
              method="put"
              v2
              payload={{
                isFlagged: true,
              }}
              url={`/purchase-orders/${data.id}`}
              type="default"
              onSuccessMessage="Pomyślnie oflagowano"
              onSuccess={() => {
                refresh()
              }}
            />
          ) : (
            <RequestButton
              content="Zdejmij oznaczenie"
              method="put"
              v2
              payload={{
                isFlagged: false,
              }}
              url={`/purchase-orders/${data.id}`}
              type="danger"
              onSuccessMessage="Pomyślnie zdjęto oznaczenie"
              onSuccess={() => {
                refresh()
              }}
            />
          )}
        </Right>

        {editMode && (
          <FormProvider
            v2
            url={`/purchase-orders/${data.id}`}
            method="put"
            form={form}
            onSuccessMessage="Pomyślnie edytowano zamówienie!"
            onSuccess={this.onSuccess}
            render={({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row gutter={{ md: 16 }}>
                  <Col md={24}>
                    <TextField label="Status" value={<PurchaseOrderStatus status={data.status} />} />
                  </Col>
                  <Col md={24}>
                    <TextField label="Dostawca" value={data.supplierName} />
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Numer zamówienia">
                      {form.getFieldDecorator('orderNumber', {
                        rules: [
                          { max: 255, message: 'Maksymalnie 255 znaków' },
                        ],
                      })(<Input placeholder="Numer zamówienia" />)}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Numer faktury">
                      {form.getFieldDecorator('invoiceNumber', {
                        rules: [
                          { max: 255, message: 'Maksymalnie 255 znaków' },
                        ],
                      })(<Input placeholder="Numer faktury" />)}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Link do zamówienia">
                      {form.getFieldDecorator('uri')(<Input placeholder="Link do zamówienia" />)}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Zapisz
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          />
        )}
        {!editMode && (
          <>
            <Row gutter={{ md: 16 }}>
              <Col md={24}>
                <TextField label="Status" value={<PurchaseOrderStatus status={data.status} />} />
              </Col>
              <Col md={24}>
                <TextField label="Dostawca" value={data.supplierName} />
              </Col>
              <Col md={24}>
                <TextField label="Numer zamówienia" value={data.orderNumber} />
              </Col>
              <Col md={24}>
                <TextField label="Numer faktury" value={data.invoiceNumber} />
              </Col>
              <Col md={24}>
                <TextField label="Link do zamówienia" value={data.uri ? <a rel="noopener noreferrer" target="_blank" href={data.uri}>{data.uri}</a> : '-'} />
              </Col>
            </Row>
          </>
        )}
      </Paper>
    )
  }
}

export const PurchaseOrderDetails = compose(
  Form.create({ name: 'purchase-order-details-edit-form' })
)(PurchaseOrderDetailsUnwrapped)
