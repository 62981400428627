import deepDiff from 'deep-diff'
import get from 'lodash/get'
import set from 'lodash/set'

const isHashMap = obj =>
  typeof obj === 'object' && !!obj && obj.constructor === Object

const buildArray = (length, value) => new Array(length).fill(value)

const fillPlaceholders = (result, path, obj, { arrayUnchangedPlaceholder }) => {
  if (!get(result, path)) {
    return set(
      result,
      path,
      buildArray(get(obj, path).length, arrayUnchangedPlaceholder)
    )
  }
  return result
}

const getArrayDeletedValue = (
  lhs,
  rhs,
  elementPath,
  { arrayDeleteValue, identityKeys }
) => {
  const beforeUpdate = get(lhs, elementPath)
  if (isHashMap(beforeUpdate)) {
    return {
      ...arrayDeleteValue,
      ...identityKeys.reduce((acc, key) => {
        if (typeof beforeUpdate[key] !== 'undefined') {
          return {
            ...acc,
            [key]: beforeUpdate[key],
          }
        }
        return acc
      }, {}),
    }
  }
  return arrayDeleteValue
}

const handleArrayChange = (result, change, lhs, rhs, options) => {
  const { path, index } = change
  result = fillPlaceholders(result, path, rhs, options)
  const elementPath = [...path, index]
  return set(
    result,
    elementPath,
    get(rhs, elementPath, getArrayDeletedValue(lhs, rhs, elementPath, options))
  )
}

const findObjectPath = (changePath, optionPaths) => {
  const comparePath = changePath.map(el => (isNaN(el) ? el : '.')).join('-')
  const optionPath = optionPaths.find(path =>
    comparePath.includes(path.join('-'))
  )
  if (!optionPath) return undefined
  return optionPath.map((el, index) => (el === '.' ? changePath[index] : el))
}

const getJsonEmptyValue = (lhs, jsonPath) => {
  const obj = get(lhs, jsonPath)
  if (Array.isArray(obj)) return []
  if (isHashMap(obj)) return {}
  return undefined
}

const handleJsonChange = (result, change, lhs, rhs, jsonPath, options) =>
  set(
    appendIdentityKeys(result, change, lhs, options),
    jsonPath,
    get(rhs, jsonPath, getJsonEmptyValue(lhs, jsonPath))
  )

const findEntityPaths = (changePath, entities) => {
  return entities
    .map(entity => {
      const index = changePath.findIndex(key => key === entity)
      if (index === -1) return null
      if (isNaN(changePath[index + 1])) {
        return changePath.slice(0, index + 1)
      }
      return changePath.slice(0, index + 2)
    })
    .filter(path => !!path)
}

const appendIdentityKeys = (
  result,
  change,
  lhs,
  { identityKeys, entities, isRoot }
) => {
  const entityPaths = findEntityPaths(change.path, entities)

  if (isRoot) {
    result = identityKeys.reduce((acc, idKey) => {
      if (lhs[idKey])
        return {
          ...acc,
          [idKey]: lhs[idKey],
        }
      return acc
    }, result)
  }

  return entityPaths.reduce((res, path) => {
    const keyPaths = identityKeys.map(key => [...path, key])
    // eslint-disable-next-line no-unused-vars
    for (const keyPath of keyPaths) {
      const value = get(lhs, keyPath)
      if (value) {
        result = set(result, keyPath, value)
      }
    }
    return res
  }, result)
}

const handleObjectChange = (result, change, lhs, rhs, options) => {
  const value = get(rhs, change.path, options.objDeleteValue)
  if (value !== undefined) {
    return set(
      appendIdentityKeys(result, change, lhs, options),
      change.path,
      value
    )
  }
  return result
}

const defaultOptions = {
  isRoot: false,
  arrayUnchangedPlaceholder: null,
  arrayDeleteValue: { toDelete: true },
  objDeleteValue: undefined,
  identityKeys: ['id', 'slug'],
  ignorePaths: [],
  entities: [],
  jsonPaths: [],
}

const buildDiffObject = (lhs, rhs, passedOptions = {}) => (result, change) => {
  const options = {
    ...defaultOptions,
    ...passedOptions,
  }
  const ignorePath = findObjectPath(change.path, options.ignorePaths)
  if (ignorePath) return result

  const jsonPath = findObjectPath(change.path, options.jsonPaths)
  if (jsonPath)
    return handleJsonChange(result, change, lhs, rhs, jsonPath, options)

  if (change.kind === 'A')
    return handleArrayChange(result, change, lhs, rhs, options)

  return handleObjectChange(result, change, lhs, rhs, options)
}

export const difference = (lhs, rhs, options = {}) => {
  const resultArray = deepDiff(lhs, rhs)
  if (!resultArray) return {}
  return resultArray.reduce(
    buildDiffObject(lhs, rhs, { ...options, isRoot: true }),
    {}
  )
}

export const isChanged = (lhs, rhs) => {
  const resultArray = deepDiff(lhs, rhs)
  return !!resultArray
}
