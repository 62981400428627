import {
  ADD_PRICES,
  QUEUE_PRICE_REQUEST,
  CLEAR_PRICE_REQUEST_QUEUE,
} from 'redux/actions/constants'

const defaultState = {
  prices: {},
  queue: [],
}

export const priceReducer = (state = defaultState, action) => {
  switch (action.type) {
    case QUEUE_PRICE_REQUEST: {
      if (state.queue.find(item => item.sku === action.sku)) {
        return state
      }
      return {
        ...state,
        queue: [
          ...state.queue,
          {
            sku: action.sku,
            salesChannelCode: action.salesChannelCode,
            currency: action.currency,
          },
        ],
      }
    }
    case CLEAR_PRICE_REQUEST_QUEUE: {
      return {
        ...state,
        queue: [],
      }
    }
    case ADD_PRICES:
      return {
        ...state,
        prices: {
          ...state.prices,
          ...action.prices.reduce(
            (acc, curr) => ({ ...acc, [curr.sku]: curr }),
            {}
          ),
        },
      }
    default:
      return state
  }
}
