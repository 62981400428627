import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Input,
  InputNumber,
  Button,
  Typography,
  DatePicker,
} from 'antd'
import { TextField } from 'components'

export class TransportEditForm extends React.Component {
  state = {
    invoiceNumber: this.props.transport.invoiceNumber || '',
    cost: this.props.transport.cost || '',
    driver: this.props.transport.driver || '',
    vehicle: this.props.transport.vehicle || '',
    customsAgency: this.props.transport.customsAgency || '',
    departureAt: this.props.transport.departureAt
      ? moment(this.props.transport.departureAt)
      : null,
    departuredAt: this.props.transport.departuredAt
      ? moment(this.props.transport.departuredAt)
      : null,
    arrivedAt: this.props.transport.arrivedAt
      ? moment(this.props.transport.arrivedAt)
      : null,
    error: '',
  }

  handleChange = name => value => {
    this.setState({
      [name]: value,
    })
  }

  handleTextChange = name => e => {
    this.setState({
      [name]: e.target.value,
    })
  }

  handleClick = () => {

    this.props.onSubmit(this.getFormData())
  }

  getFormData = () => {
    return {
      invoiceNumber: this.state.invoiceNumber || null,
      cost: this.state.cost || null,
      driver: this.state.driver || null,
      vehicle: this.state.vehicle || null,
      customsAgency: this.state.customsAgency || null,
      departureAt: this.state.departureAt || null,
      departuredAt: this.state.departuredAt || null,
      arrivedAt: this.state.arrivedAt || null,
    }
  }

  render() {
    const {
      invoiceNumber,
      cost,
      driver,
      vehicle,
      customsAgency,
      departureAt,
      departuredAt,
      arrivedAt,
      error,
    } = this.state
    return (
      <>
        <Row>
          <Col lg={12} xs={12}>
            <TextField
              label="Numer faktury"
              value={
                <Input
                  placeholder="Numer faktury"
                  value={invoiceNumber}
                  onChange={this.handleTextChange('invoiceNumber')}
                />
              }
            />
            <TextField
              label="Koszt"
              value={
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="Koszt (PLN)"
                  min={0}
                  formatter={value => value.replace(/\B(?=(\d{2}$))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                  value={cost}
                  onChange={this.handleChange('cost')}
                />
              }
            />
            <TextField
              label="Kierowca"
              value={
                <Input
                  placeholder="Kierowca"
                  value={driver}
                  onChange={this.handleTextChange('driver')}
                />
              }
            />
            <TextField
              label="Samochód"
              value={
                <Input
                  placeholder="Samochód"
                  value={vehicle}
                  onChange={this.handleTextChange('vehicle')}
                />
              }
            />
            <TextField
              label="Agencja celna"
              value={
                <Input
                  placeholder="Agencja celna"
                  value={customsAgency}
                  onChange={this.handleTextChange('customsAgency')}
                />
              }
            />
            <TextField
              label="Planowana data wyjazdu"
              value={
                <DatePicker
                  showTime
                  value={departureAt}
                  onChange={this.handleChange('departureAt')}
                />
              }
            />
            <TextField
              label="Data wyjazdu"
              value={
                <DatePicker
                  showTime
                  value={departuredAt}
                  onChange={this.handleChange('departuredAt')}
                />
              }
            />
            <TextField
              label="Data przyjazdu"
              value={
                <DatePicker
                  showTime
                  value={arrivedAt}
                  onChange={this.handleChange('arrivedAt')}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={18} xs={18}>
            {error && (
              <Typography.Text type="danger">
                <small>{error}</small>
              </Typography.Text>
            )}
          </Col>
          <Col lg={6} xs={6} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={this.handleClick}
              loading={this.props.updating}
              disabled={this.props.updating}
            >
              Zapisz
            </Button>
          </Col>
        </Row>
      </>
    )
  }
}

TransportEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
