import React from 'react'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'

class WithSuppliersHOC extends React.Component {
  state = {
    data: [],
  }

  setSuppliers = data => {
    this.setState({
      data,
    })
  }

  normalize = (suppliers = []) =>
    suppliers.map(supplier => ({
      key: supplier.id,
      id: supplier.id,
      address: supplier.address,
    }))

  render() {
    const { Component, ...rest } = this.props
    return (
      <ResourceProvider
        v2
        url="/suppliers"
        pagination
        paginationParams={{
          current: 1,
          pageSize: 10000,
          total: null,
        }}
        responseNormalizer={this.normalize}
        onSuccess={this.setSuppliers}
        render={({ refresh }) => <Component {...rest} suppliers={this.state.data} suppliersRefresh={refresh} />}
      />
    )
  }
}

export const withSuppliers = Component => props => (
  <WithSuppliersHOC Component={Component} {...props} />
)
