import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Paper, RequestButton } from 'components'
import { Col, Row, Button } from 'antd'

export const JobsList = ({ history }) => (
  <MainLayout header="Operacje" transparent>
    <Row>
      <Col xxl={12} xl={20} lg={24} xs={24}>
        <Row type="flex" justify="space-between">
          <Col lg={24} xs={24}>
            <Paper title="Synchronizuj stany Tuning-Tec">
              <RequestButton
                url="/jobs/sync-tuning-tec"
                method="post"
                type="danger"
                content="Synchronizuj stany z Tuning-Tec"
                title="Czy na pewno chcesz synchronizować pole 'Wyprzedane' ze stanami Tuning-Tec?"
              />
            </Paper>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col lg={24} xs={24}>
            <Paper title="Faktura eksportowa">
              <Button
                onClick={() => {
                  history.push('/jobs/export-invoice')
                }}
                type="primary"
              >
                Generuj fakturę eksportową
              </Button>
            </Paper>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col lg={24} xs={24}>
            <Paper title="Generuj etykiety magazynowe">
              <Button
                onClick={() => {
                  history.push('/jobs/warehouse-stickers')
                }}
                type="primary"
              >
                Generuj etykiety magazynowe
              </Button>
            </Paper>
          </Col>
        </Row>
      </Col>
    </Row>
  </MainLayout>
)
