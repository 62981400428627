import React from 'react'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'

class WithSalesChannelsHOC extends React.Component {
  state = {
    data: [],
  }

  setSalesChannels = data => {
    this.setState({
      data,
    })
  }

  render() {
    const { Component, ...rest } = this.props
    return (
      <ResourceProvider
        url="/sales-channel"
        onSuccess={this.setSalesChannels}
        render={() => <Component {...rest} salesChannels={this.state.data} />}
      />
    )
  }
}

export const withSalesChannels = Component => props => (
  <WithSalesChannelsHOC Component={Component} {...props} />
)
