import React, { useState } from 'react'
import { PrinterOutlined } from '@ant-design/icons'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { Input, Modal } from 'antd'
import { TextField } from 'components/TextField/TextField'

export const PrintShippingLabel = ({ uri }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [printerId, setPrinterId] = useState(
    localStorage.getItem('printer_104x159') || ''
  )
  const handlePrinterIdChange = e => {
    setPrinterId(e.target.value)
    localStorage.setItem('printer_104x159', e.target.value)
  }
  return (
    <>
      <PrinterOutlined
        onClick={() => setIsVisible(true)}
        style={{
          marginLeft: '0.4rem',
          color: '#1890ff',
          cursor: 'pointer',
        }}
      />
      <Modal
        title={`Drukuj etykietę`}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={[]}
      >
        <TextField
          label="Numer drukarki 104 x 159"
          value={
            <Input
              type="text"
              value={printerId || ''}
              onChange={handlePrinterIdChange}
            />
          }
        />
        <RequestButton
          v2
          url={`/print/${printerId}/shipping-label`}
          payload={{
            'uri': uri,
          }}
          method="post"
          content="Drukuj"
          unprotected
          type="primary"
          onSuccess={() => setIsVisible(false)}
        />
      </Modal>
    </>
  )
}
