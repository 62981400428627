import React from 'react'
import { Paper, Right } from 'components'
import { Typography } from 'antd'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { Spacing } from 'components/Spacing/Spacing'

export const AcceptOrder = ({ sale, refresh }) => (
  <Paper title="Zaakceptuj zamówienie">
    <Spacing mb="1rem">
      <Typography.Text type="warning">
        Zamówienie zostanie automatycznie zaakceptowane po przyjęciu pełnej
        płatności.
        <br />
        Możesz zaakceptować zamówienie teraz ręcznie.
      </Typography.Text>
    </Spacing>
    <Right>
      <Spacing mr="0.6rem" display="inline-block">
        <RequestButton
          onSuccess={refresh}
          url={`/orders/${sale.id}/cancel`}
          method="post"
          type="danger"
          content="Anuluj zamówienie"
          ghost
        />
      </Spacing>
      <RequestButton
        onSuccess={refresh}
        url={`/orders/${sale.id}/accept`}
        method="post"
        type="danger"
        content="Zaakceptuj zamówienie"
      />
    </Right>
  </Paper>
)
