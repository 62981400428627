import React from 'react'
import { AsyncSelect, Paper } from 'components'
import { Modal } from 'antd'
import { formatDateTime } from 'utils/formatters'
import { requestV2 } from 'api/request'

export const PurchaseOrderTransport = ({ data, refresh }) => (
  <Paper title="Transport">
    <AsyncSelect
      v2
      fullList
      queryParam="query"
      value={data.transportId}
      url="/transports"
      responseNormalizer={item => ({
        text: item.number.toString().padStart(5, 0) + ' ' + formatDateTime(item.departureAt),
        value: item.id,
      })}
      onChange={transportId => {
        Modal.confirm({
          title: 'Czy na pewno chcesz przypisać transport?',
          onOk: async () => {
            try {
              await requestV2({
                method: 'post',
                url: `/purchase-orders/${data.id}/assign-transport`,
                data: {
                  transportId: transportId || null,
                },
              })
            } catch { }
            refresh()
          },
          onCancel() {
            refresh()
          },
        })
      }}
    />
  </Paper>
)
