import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { EventsLog } from 'components/EventsLog/EventsLog'
import { withOnErrorRedirect } from 'hocs/withOnErrorRedirect'
import { SaleInvoiceHeader } from './SaleInvoiceHeader/SaleInvoiceHeader'
import { InvoiceItems } from './InvoiceItems/InvoiceItems'
import { BookInvoice } from './BookInvoice/BookInvoice'
import { BookFikenInvoice } from './BookFikenInvoice/BookFikenInvoice'
import { BookReference } from './BookReference/BookReference'
import { saleInvoiceStatus } from '../saleInvoiceStatus'

class SaleInvoiceShowUnwrapped extends React.Component {
  render() {
    const { id } = this.props.match.params
    return (
      <ResourceProvider
        url={`/sale-invoices/${encodeURIComponent(id)}`}
        onError={this.props.onErrorRedirect}
        render={({ data: invoice, loading, refresh }) => {
          const status = saleInvoiceStatus(invoice)
          return (
            <MainLayout
              header={`Faktura sprzedaży ${
                invoice ? invoice.id.slice(-8) : ''
              }`}
              isLoading={!invoice && loading}
              transparent
            >
              {invoice && !loading && (
                <Row>
                  <Col xxl={12} xl={20} lg={24} xs={24}>
                    <Row type="flex" justify="space-between">
                      <Col lg={24} xs={24}>
                        <SaleInvoiceHeader invoice={invoice} />
                      </Col>
                      {status.isEqual(status.BOOKED) && (
                        <Col lg={24} xs={24}>
                          <BookReference invoice={invoice} />
                        </Col>
                      )}
                      {status.isEqual(status.CREATED) && (
                        <Col lg={24} xs={24}>
                          <BookFikenInvoice
                            invoice={invoice}
                            refresh={refresh}
                          />
                        </Col>
                      )}
                      {status.isEqual(status.CREATED) && (
                        <Col lg={24} xs={24}>
                          <BookInvoice invoice={invoice} refresh={refresh} />
                        </Col>
                      )}
                      <Col lg={24} xs={24}>
                        <InvoiceItems invoice={invoice} />
                      </Col>
                      <Col lg={24} xs={24}>
                        <EventsLog events={invoice.events} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </MainLayout>
          )
        }}
      />
    )
  }
}

export const SaleInvoiceShow = withOnErrorRedirect(SaleInvoiceShowUnwrapped)
