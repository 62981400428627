import React from 'react'
import { Row, Col, Select, InputNumber } from 'antd'
import { RequestButton } from 'components/RequestButton/RequestButton'

const currencies = ['PLN']

export class AddPurchasePrice extends React.Component {
  state = {
    netto: null,
    currency: 'PLN',
    supplierId: null,
  }

  handleCurrencyChange = currency => {
    this.setState({
      currency,
    })
  }

  handleSupplierChange = supplierId => {
    this.setState({
      supplierId,
    })
  }

  handleValueChange = netto => {
    this.setState({
      netto,
    })
  }

  isDisabled = () =>
    !this.state.netto || !this.state.currency || !this.state.supplierId

  onSuccess = () => {
    this.setState({
      netto: null,
      currency: undefined,
    })
    this.props.refresh()
  }

  render() {
    const { sku, suppliers } = this.props
    const { netto, currency, supplierId } = this.state
    return (
      <Row gutter={12} id="add-purchase-price-form">
        <Col md={6}>
          <InputNumber
            style={{ width: '100%' }}
            value={netto}
            placeholder="Netto"
            id="add-purchase-price-form_netto"
            min={0}
            onChange={this.handleValueChange}
            formatter={value =>
              value.toString().replace(/\B(?=(\d{2}$))/g, ',')
            }
            parser={value => value.toString().replace(/\$\s?|(,*)/g, '')}
          />
        </Col>
        <Col md={6}>
          <Select
            placeholder="Waluta"
            value={currency}
            style={{ width: '100%' }}
            onChange={this.handleCurrencyChange}
            id="add-purchase-price-form_currency"
          >
            {currencies.map(currency => (
              <Select.Option key={currency}>{currency}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col md={6}>
          <Select
            placeholder="Dostawca"
            value={supplierId}
            style={{ width: '100%' }}
            onChange={this.handleSupplierChange}
            id="add-purchase-price-form_supplierId"
          >
            {suppliers.map(supplier => (
              <Select.Option key={supplier.id}>{supplier.name}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col md={6}>
          <RequestButton
            url="/products/purchase-prices"
            method="post"
            id="add-purchase-price-form_submit"
            payload={{
              netto,
              currency,
              sku,
              supplierId,
            }}
            onSuccess={this.onSuccess}
            type="primary"
            disabled={this.isDisabled()}
            content="Dodaj"
          />
        </Col>
      </Row>
    )
  }
}
