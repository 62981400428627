import React from 'react'
import { Badge, Avatar, Tooltip } from 'antd'

export const StatsBadge = ({ count, icon, tooltip }) => (
  <span style={{ marginRight: '6px' }}>
    <Tooltip placement="bottomRight" title={tooltip}>
      <Badge count={count} dot={!count} style={{ backgroundColor: '#108ee9' }}>
        <Avatar shape="square" icon={icon} />
      </Badge>
    </Tooltip>
  </span>
)
