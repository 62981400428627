import React from 'react'
import { Select } from 'antd'

export const FitmentSelect = ({
  selectStyle,
  options,
  value,
  onChange,
  disabled,
  placeholder,
}) => (
  <Select
    disabled={disabled}
    showSearch
    style={selectStyle}
    onChange={onChange}
    value={value}
    placeholder={placeholder}
    optionFilterProp="label"
    filterOption={(input, option) =>
      option.props.children
        .toString()
        .toUpperCase()
        .indexOf(input.toUpperCase()) >= 0
    }
  >
    <Select.Option value="*">*</Select.Option>
    {options.map(option => (
      <Select.Option key={option.id} value={option.id}>
        {option.label}
      </Select.Option>
    ))}
  </Select>
)
