import jwtDecode from 'jwt-decode'
import { SIGNED_IN, TOKEN_CHECK } from 'redux/actions/constants'
import { pathOr } from 'ramda'

const decodeToken = token => {
  try {
    return jwtDecode(token)
  } catch (err) {
    return null
  }
}

const getStoredUser = () => {
  try {
    return JSON.parse(localStorage.getItem('user'))
  } catch {
    return {}
  }
}

const isValidToken = (token, user) => {
  const decodedToken = decodeToken(token)
  if (!decodedToken) {
    return false
  }
  if (decodeToken.exp <= new Date().getTime()) {
    return false
  }
  if (decodedToken.emailAddress !== user.emailAddress) {
    return false
  }
  return true
}

const getDefaultState = () => {
  const token = localStorage.getItem('auth_token')
  const user = getStoredUser()
  return {
    token,
    isAuthorized: isValidToken(token, user),
    user: {
      firstName: pathOr('', ['firstName'], user),
      lastName: pathOr('', ['lastName'], user),
      emailAddress: pathOr('', ['emailAddress'], user),
      roles: pathOr([], ['roles'], user),
    },
  }
}

const saveToken = token => {
  localStorage.setItem('auth_token', token)
}

const saveUser = user => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const authReducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case TOKEN_CHECK:
      return {
        ...state,
        isAuthorized: isValidToken(state.token, state.user),
      }
    case SIGNED_IN:
      saveToken(action.token)
      saveUser(action.user)
      return {
        ...state,
        isAuthorized: isValidToken(action.token, action.user),
        token: action.token,
        user: action.user,
      }
    default:
      return state
  }
}
