import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col, Button, Table } from 'antd'
import {
  PlusOutlined,
  ShopOutlined,
  UserOutlined,
  InboxOutlined,
} from '@ant-design/icons'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { StatsBadge } from 'components'
import { formatDateTime } from 'utils/formatters'

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: id => <Link to={`/sale-invoices/${id}`}>{id.slice(-8)}</Link>,
  },
  {
    title: 'Numer',
    dataIndex: 'number',
    key: 'number',
    render: number => number || '-',
  },
  {
    title: 'Kanał sprzedaży',
    dataIndex: 'salesChannelCode',
    key: 'salesChannelCode',
    render: salesChannelCode => salesChannelCode || '-',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Adresat',
    render: ({ recipient }) => (
      <React.Fragment>
        <div>
          {recipient.userId ? (
            <Link to={`/users/${recipient.userId}`}>
              {recipient.isCorporate ? <ShopOutlined /> : <UserOutlined />}
              &nbsp;{recipient.name}
            </Link>
          ) : (
            <span>
              {recipient.isCorporate ? <ShopOutlined /> : <UserOutlined />}
              &nbsp;{recipient.name}
            </span>
          )}
        </div>
      </React.Fragment>
    ),
  },
  {
    title: 'Adres',
    render: ({ recipientAddress }) => (
      <React.Fragment>
        <div>
          <strong>
            {recipientAddress.addressLine1} {recipientAddress.addressLine2}
          </strong>
        </div>
        <div>
          <small>
            {recipientAddress.postalCode}, {recipientAddress.city},{' '}
            {recipientAddress.countryCode}
          </small>
        </div>
      </React.Fragment>
    ),
  },
  {
    render: record => (
      <StatsBadge
        count={record.items.length}
        icon={<InboxOutlined />}
        tooltip="Liczba pozycji"
      />
    ),
  },
  {
    title: 'Dodano',
    render: record => formatDateTime(record.createdAt),
  },
  {
    title: 'Aktualizowano',
    render: record => formatDateTime(record.updatedAt),
  },
]

const SaleInvoicesListUnwrapped = ({ history }) => (
  <MainLayout header="Faktury sprzedaży" minWidth>
    <Row style={{ marginBottom: '3rem' }}>
      <Col md={24} style={{ textAlign: 'right' }}>
        <Button.Group>
          <Button
            type="primary"
            onClick={() => {
              history.push('/sale-invoices/create')
            }}
          >
            <PlusOutlined />
            Dodaj
          </Button>
        </Button.Group>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <ResourceProvider
          url="/sale-invoices"
          encodePageNumber
          pagination
          render={({ data, paginationParams, loading, onChange }) => (
            <Table
              columns={columns}
              dataSource={data}
              rowKey="id"
              pagination={{
                ...paginationParams,
                showSizeChanger: true,
              }}
              loading={loading}
              onChange={onChange}
            />
          )}
        />
      </Col>
    </Row>
  </MainLayout>
)

export const SaleInvoicesList = withRouter(SaleInvoicesListUnwrapped)
