import React from 'react'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { Table } from 'components/Table/Table'
import { Price } from 'components/Price/Price'
import { Align } from 'components/Align/Align'
import { formatDateTime } from 'utils/formatters'

export const PriceList = ({ prices, refresh, suppliers }) => (
  <Table>
    <Table.Body>
      <Table.Tr>
        <Table.Th style={{ width: '20%' }}>Cena netto</Table.Th>
        <Table.Th style={{ width: '30%' }}>Dostawca</Table.Th>
        <Table.Th style={{ width: '25%' }}>Data</Table.Th>
        <Table.Th style={{ width: '25%' }}>{''}</Table.Th>
      </Table.Tr>
      {prices.length === 0 && (
        <Table.Tr>
          <Table.Td colSpan={3} width="100%">
            <Align value="center">
              <small>
                <i>Brak cen</i>
              </small>
            </Align>
          </Table.Td>
        </Table.Tr>
      )}
      {prices.map((price, index) => (
        <Table.Tr key={index}>
          <Table.Td width="20%">
            <Price value={price.netto} currency={price.currency} />
          </Table.Td>
          <Table.Td width="30%">{price.supplierId}</Table.Td>
          <Table.Td>{formatDateTime(price.createdAt)}</Table.Td>
          <Table.Td>
            <Align value="right">
              <RequestButton
                content="Usuń"
                method="delete"
                url={`/products/purchase-prices/${price.id}`}
                type="link"
                onSuccessMessage="Pomyślnie usunięto!"
                onSuccess={refresh}
              />
            </Align>
          </Table.Td>
        </Table.Tr>
      ))}
    </Table.Body>
  </Table>
)
