import React from 'react'
import { Button, Modal, Typography, Divider } from 'antd'
import { Spacing, TextField } from 'components'
import { formatCurrency } from 'utils/formatters'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { RefreshPaymentStatusButton } from '../RefreshPaymentStatusButton/RefreshPaymentStatusButton'
import { CapturePayment } from './CapturePayment/CapturePayment'
import { RefundPayment } from './RefundPayment/RefundPayment'
import { VippsDetails } from './VippsDetails/VippsDetails'

export class VippsPaymentDetailsModal extends React.Component {
  state = {
    isOpen: false,
  }

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  render() {
    const {
      saleId,
      refresh,
      paymentId,
      type,
      status,
      value,
      currency,
      capturedAmount,
      refundedAmount,
    } = this.props
    const { isOpen } = this.state
    return (
      <>
        <Button onClick={this.toggleModal} style={{ padding: 0 }} type="link">
          {paymentId.slice(0, 6)}
        </Button>
        <Modal
          title="Szczegóły płatności"
          visible={isOpen}
          onCancel={this.toggleModal}
          footer={[]}
        >
          <TextField
            label="ID"
            id="payment_id"
            value={<Typography.Text copyable>{paymentId}</Typography.Text>}
          />
          <TextField
            label="Status"
            value={
              <>
                {status}{' '}
                {status && (
                  <RefreshPaymentStatusButton
                    saleId={saleId}
                    paymentId={paymentId}
                    paymentType={type}
                    refresh={refresh}
                  />
                )}
              </>
            }
          />
          <TextField label="Typ" value={type} />
          <TextField label="Wartość" value={formatCurrency(value, currency)} />
          <TextField
            label="Pobrano"
            value={formatCurrency(capturedAmount, currency)}
          />
          <TextField
            label="Zwrócono"
            value={formatCurrency(refundedAmount, currency)}
          />
          <TextField
            label="Ściągnij płatność"
            value={
              <CapturePayment
                refresh={refresh}
                saleId={saleId}
                paymentId={paymentId}
                capturedAmount={capturedAmount}
                value={value}
                refundedAmount={refundedAmount}
              />
            }
          />
          <TextField
            label="Zwróć płatność"
            value={
              <RefundPayment
                refresh={refresh}
                saleId={saleId}
                paymentId={paymentId}
                capturedAmount={capturedAmount}
                value={value}
                refundedAmount={refundedAmount}
              />
            }
          />
          {isOpen && (
            <TextField
              label="Dane z Vipps"
              value={<VippsDetails saleId={saleId} paymentId={paymentId} />}
            />
          )}
          <Divider />
          <Spacing mt="1">
            <RequestButton
              type="danger"
              method="post"
              url={`/payments/${saleId}/${paymentId}/vipps/cancel`}
              onSuccess={refresh}
              title={`Na pewno chcesz anulować płatność Vipps na ${formatCurrency(
                value,
                currency
              )}? Ta operacja jest nieodwracalna.`}
              content="Anuluj płatność"
            />
          </Spacing>
          {type !== 'vipps' && (
            <Spacing mt="1">
              <RequestButton
                type="danger"
                method="delete"
                url={`/payments/${saleId}/${paymentId}`}
                onSuccess={refresh}
                title={`Na pewno chcesz usunąć z bazy płatność ${paymentId} na ${formatCurrency(
                  value,
                  currency
                )}? Usunięcie płatności NIE spowoduje zwrotu pieniędzy.`}
                content="Usuń rekord płatności"
              />
            </Spacing>
          )}
        </Modal>
      </>
    )
  }
}
