import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, InputNumber, Button } from 'antd'
import { Table } from 'components/Table/Table'
import { AsyncSelect } from 'components/AsyncSelect/AsyncSelect'
import { SLabel } from 'components/SLabel/SLabel'
import { Spacing } from 'components/Spacing/Spacing'
import { Right, ProductNumber, SalesPrice } from 'components'
import { formatCurrency } from 'utils/formatters'
import { Align } from 'components/Align/Align'
import { toPriceList } from 'utils/prices'

class SaleItemsTableUnwrapped extends React.Component {
  state = {
    items: this.props.sale.items || [],
    sku: '',
    quantity: 1,
  }

  onChange = field => value => {
    this.setState({ [field]: value })
  }

  add = () => {
    const { sku, quantity } = this.state
    if (sku && quantity) {
      this.setState(state => ({
        items: [
          ...state.items.filter(item => item.sku !== sku),
          {
            sku,
            quantity,
          },
        ],
        sku: '',
        quantity: 1,
      }))
    }
  }

  remove = sku => () => {
    this.setState(state => ({
      items: state.items.filter(item => item.sku !== sku),
    }))
  }

  getItemsWithPrice = () =>
    this.state.items.map(item => ({
      ...item,
      ...this.props.prices[item.sku],
    }))

  handleSubmit = () => {
    this.props.handleSubmit(
      this.state.items.map(item => ({
        sku: item.sku,
        quantity: item.quantity,
      }))
    )
  }

  isChanged = () => {
    const { items } = this.state
    const propItems = this.props.sale.items || []
    if (items.length !== propItems.length) {
      return true
    }
    // eslint-disable-next-line no-unused-vars
    for (const item of items) {
      const propItem = propItems.find(i => i.sku === item.sku)
      if (!propItem) {
        return true
      }
      if (propItem.quantity !== item.quantity) {
        return true
      }
    }
    return false
  }

  render() {
    const { sale, posting } = this.props
    const { sku, quantity, items } = this.state
    const priceList = toPriceList(this.getItemsWithPrice())
    return (
      <>
        <Row id="sale-items-table">
          <Spacing mb="2rem">
            <Table>
              <Table.Body>
                <Table.Tr>
                  <Table.Th style={{ width: '25%' }}>SKU</Table.Th>
                  <Table.Th style={{ width: '25%' }}>Cena netto</Table.Th>
                  <Table.Th style={{ width: '25%' }}>Ilość</Table.Th>
                  <Table.Th style={{ width: '25%' }}>Razem Brutto</Table.Th>
                </Table.Tr>
                {items.length === 0 && (
                  <Table.Tr>
                    <Table.Td colSpan={4} width="100%">
                      <Align value="center">
                        <small>
                          <i>Brak</i>
                        </small>
                      </Align>
                    </Table.Td>
                  </Table.Tr>
                )}
                {items.map((item, index) => (
                  <Table.Tr key={index}>
                    <Table.Td width="25%">
                      <ProductNumber sku={item.sku} />
                      <Button
                        id="sale-items-table_delete-button"
                        type="link"
                        size="small"
                        onClick={this.remove(item.sku)}
                        disabled={posting}
                      >
                        Usuń
                      </Button>
                    </Table.Td>
                    <Table.Td>
                      <SalesPrice
                        sku={item.sku}
                        salesChannelCode={sale.salesChannelCode}
                        currency={sale.currency}
                        field="netto"
                      />
                    </Table.Td>
                    <Table.Td>{item.quantity}</Table.Td>
                    <Table.Td>
                      <SalesPrice
                        sku={item.sku}
                        salesChannelCode={sale.salesChannelCode}
                        currency={sale.currency}
                        quantity={item.quantity}
                        field="totalBrutto"
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
                {items.length > 0 && (
                  <Table.Tr>
                    <Table.Td colSpan={3} width="75%" />
                    <Table.Td colSpan={1} width="25%">
                      <strong>
                        {formatCurrency(priceList.total.brutto, sale.currency)}
                      </strong>
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Body>
            </Table>
          </Spacing>
        </Row>
        <Row gutter={12}>
          <Col md={8}>
            <Spacing ml="0.3rem">
              <SLabel>SKU</SLabel>
            </Spacing>
            <AsyncSelect
              value={sku}
              id="sale-items-table_sku-selector"
              url="/products"
              queryParam="sku"
              onChange={this.onChange('sku')}
              placeholder="SKU"
              responseNormalizer={item => ({
                text: item.sku,
                value: item.sku,
              })}
            />
          </Col>
          <Col md={4}>
            <Spacing ml="0.3rem">
              <SLabel>Ilość</SLabel>
            </Spacing>
            <InputNumber
              min={1}
              id="sale-items-table_quantity"
              placeholder="Ilość"
              value={quantity}
              onChange={this.onChange('quantity')}
            />
          </Col>
          <Col md={4}>
            <SLabel>&nbsp;</SLabel>
            <Button
              disabled={posting}
              onClick={this.add}
              id="sale-items-table_add-button"
            >
              Dodaj
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <Right>
              <Button
                type="primary"
                id="sale-items-table_submit"
                disabled={
                  posting ||
                  items.length === 0 ||
                  !this.isChanged() ||
                  priceList.items.some(item => !item.netto)
                }
                onClick={this.handleSubmit}
              >
                Zapisz {posting && '...'}
              </Button>
            </Right>
          </Col>
        </Row>
      </>
    )
  }
}

export const SaleItemsTable = connect(state => ({
  prices: state.prices.prices,
}))(SaleItemsTableUnwrapped)
