export const removeEmptyStringKeys = object =>
  Object.fromEntries(Object.entries(object).filter(entry => entry[1] !== ''))

export const createIntArray = count =>
  new Array(count).fill(0).map((_, idx) => idx + 1)

export const pad = (n, width, z = '0') => {
  const num = n.toString()
  return num.length >= width ? n : new Array(width - num.length + 1).join(z) + n
}
