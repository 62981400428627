import React from 'react'
import { Table } from 'antd'
import { RequestButton } from 'components/RequestButton/RequestButton'

const getColumns = (refresh, sku) => [
  {
    title: 'PL',
    dataIndex: 'pl',
  },
  {
    title: 'NO',
    dataIndex: 'no',
  },
  {
    title: 'ID',
    dataIndex: 'uuid',
  },
  {
    title: 'Drzwo',
    dataIndex: 'treeSlug',
  },
  {
    title: '',
    render: (_text, category) => (
      <RequestButton
        content="Usuń z kategorii"
        method="delete"
        url={`/products/${encodeURIComponent(sku)}/categories/${category.uuid}`}
        type="link"
        onSuccessMessage={'Pomyślnie usunięto z kategorii!'}
        onSuccess={() => {
          refresh()
        }}
      />
    ),
  },
]

export const ProductCategoriesTable = ({ categories, refresh, sku }) => (
  <Table
    rowKey="uuid"
    columns={getColumns(refresh, sku)}
    dataSource={categories}
  />
)

ProductCategoriesTable.defaultProps = {
  categories: [],
}
