export class StatusHelpers {
  constructor(status, sequence) {
    this.status = status
    this.sequence = sequence
    sequence.forEach(status => {
      this[status] = status
    })
  }

  isBefore = status =>
    this.sequence.indexOf(this.status) < this.sequence.indexOf(status)

  isAfter = status =>
    this.sequence.indexOf(this.status) > this.sequence.indexOf(status)

  isEqual = status => this.status === status

  isNot = status => this.status !== status

  isOneOf = statuses => statuses.includes(this.status)
}
