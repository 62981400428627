import React from 'react'
import { InputNumber } from 'antd'
import { TextField, Table, ProductNumber } from 'components'
import { Link } from 'react-router-dom'
import { formatCurrency } from 'utils/formatters'

export const BillingList = ({
  billings,
  getInvoicedItem,
  handleItemQuantityChange,
}) => (
  <Table>
    <Table.Body>
      <Table.Tr>
        <Table.Th>Zamówienie</Table.Th>
        <Table.Th>SKU</Table.Th>
        <Table.Th>Do zafakturowania</Table.Th>
        <Table.Th>Netto</Table.Th>
        <Table.Th>Stawka VAT</Table.Th>
        <Table.Th>Dodaj</Table.Th>
      </Table.Tr>
      {billings.map(billing =>
        billing.remainingToInvoice.map(item => {
          const invoicedItem = getInvoicedItem(billing.id, item.sku)
          return (
            <Table.Tr key={`${item.saleId}.${item.sku}`}>
              <Table.Td>
                <TextField
                  mb="0"
                  value={
                    <Link to={`/sales/${billing.id}`}>{billing.number}</Link>
                  }
                />
              </Table.Td>
              <Table.Td>
                <TextField mb="0" value={<ProductNumber sku={item.sku} />} />
              </Table.Td>
              <Table.Td>
                <TextField mb="0" value={item.quantity || '0'} />
              </Table.Td>
              <Table.Td>
                <TextField
                  mb="0"
                  value={formatCurrency(
                    invoicedItem.netto || 0,
                    billing.currency
                  )}
                />
              </Table.Td>
              <Table.Td>
                <TextField mb="0" value={`${invoicedItem.vatRate || '-'}%`} />
              </Table.Td>
              <Table.Td>
                <InputNumber
                  min={0}
                  max={item.quantity}
                  onChange={handleItemQuantityChange(billing.id, item.sku)}
                  value={invoicedItem.quantity}
                />
              </Table.Td>
            </Table.Tr>
          )
        })
      )}
    </Table.Body>
  </Table>
)
