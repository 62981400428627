import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Select, Button } from 'antd'
import { Form } from '@ant-design/compatible'
import { Paper, Right } from 'components'
import { withSalesChannels } from 'hocs/withSalesChannels'

const SaleCreateUnwrapped = ({ history, form, salesChannels }) => (
  <MainLayout header="Dodaj nową sprzedaż" backgroundColor="none" marginTop="0">
    <FormProvider
      url="/sales"
      form={form}
      onSuccessMessage="Pomyślnie dodano nową sprzedaż!"
      onSuccess={saleId => {
        history.push(`/sales/${saleId}`)
      }}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} id="create-sale-form">
          <Row>
            <Col xxl={12} xl={20} lg={24} xs={24}>
              <Row type="flex" justify="space-between">
                <Col lg={24} xs={24}>
                  <Paper title="Informacje podstawowe">
                    <Row>
                      <Col lg={12} xs={24}>
                        <Form.Item label="Kanał sprzedaży">
                          {form.getFieldDecorator('salesChannelCode')(
                            <Select placeholder="Kanał sprzedaży">
                              {salesChannels.map(salesChannel => (
                                <Select.Option
                                  key={salesChannel.code}
                                  value={salesChannel.code}
                                >
                                  {salesChannel.code}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} xs={24}>
                        <Form.Item label="Waluta">
                          {form.getFieldDecorator('currency')(
                            <Select placeholder="Waluta">
                              {['NOK'].map(code => (
                                <Select.Option key={code} value={code}>
                                  {code}
                                </Select.Option>
                              ))}
                            </Select>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Paper>
                  <Row>
                    <Form.Item>
                      <Right>
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </Right>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    />
  </MainLayout>
)

export const SaleCreate = compose(
  withRouter,
  withSalesChannels,
  Form.create({ name: 'sale-create-form' })
)(SaleCreateUnwrapped)
