import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { withOnErrorRedirect } from 'hocs/withOnErrorRedirect'
import { PurchaseOrderDetails } from './PurchaseOrderDetails/PurchaseOrderDetails'
import { PurchaseOrderDelivery } from './PurchaseOrderDelivery/PurchaseOrderDelivery'
import { PurchaseOrderTransport } from './PurchaseOrderTransport/PurchaseOrderTransport'
import { PurchaseOrderTimeline } from './PurchaseOrderTimeline/PurchaseOrderTimeline'
import { ProductSelector } from './ProductSelector/ProductSelector'
import { Comments, Attachments } from 'components'

const PurchaseOrderShowUnwrapped = (props) => (
  <ResourceProvider
    v2
    url={`/purchase-orders/${props.match.params.id}`}
    onError={props.onErrorRedirect}
    render={({ data, loading, refresh, refreshKey }) => (
      <MainLayout
        header={`Zlecenie Zaopatrzenia ${data && data.number ? data.number.toString().padStart(4, 0) : '...'}`}
        isLoading={!data && loading}
        transparent
      >
        {data && (
          <Row>
            <Col xxl={12} xl={20} lg={24} xs={24}>
              <Row type="flex" justify="space-between">
                <Col lg={24} xs={24}>
                  <PurchaseOrderDetails data={data} refresh={refresh} />
                </Col>
                <Col lg={24} xs={24}>
                  <PurchaseOrderDelivery data={data} refresh={refresh} />
                </Col>
                <Col lg={24} xs={24}>
                  <PurchaseOrderTransport data={data} refresh={refresh} />
                </Col>
                <Col lg={24} xs={24}>
                  <PurchaseOrderTimeline data={data} refresh={refresh} />
                </Col>
                <Col lg={24} xs={24}>
                  <ProductSelector
                    key={refreshKey}
                    data={data}
                    refresh={refresh}
                  />
                </Col>
                <Col lg={24} xs={24}>
                  <Comments targetId={data.id} label="Komentarz wewnętrzny" />
                </Col>
                <Col lg={24} xs={24}>
                  <Attachments targetId={data.id} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </MainLayout>
    )}
  />
)

export const PurchaseOrderShow = withOnErrorRedirect(PurchaseOrderShowUnwrapped)
