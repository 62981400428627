import React from 'react'
import { Link } from 'react-router-dom'
import { ProductNumber, Paper, Table, TextField } from 'components'
import { formatCurrency } from 'utils/formatters'

export const InvoiceItems = ({ invoice }) => (
  <Paper title="Pozycje na fakturze">
    <Table>
      <Table.Body>
        <Table.Tr>
          <Table.Th>SKU</Table.Th>
          <Table.Th>Sprzedaż</Table.Th>
          <Table.Th>Netto</Table.Th>
          <Table.Th>Stawka VAT</Table.Th>
          <Table.Th>VAT</Table.Th>
          <Table.Th>Ilość</Table.Th>
          <Table.Th>Razem Brutto</Table.Th>
        </Table.Tr>
        {invoice.items.map((item, index) => (
          <Table.Tr key={index}>
            <Table.Td>
              <ProductNumber sku={item.sku} />
            </Table.Td>
            <Table.Td>
              <Link to={`/sales/${item.saleId}`}>{item.saleId.slice(-8)}</Link>
            </Table.Td>
            <Table.Td>{formatCurrency(item.netto, invoice.currency)}</Table.Td>
            <Table.Td>{item.vatRate}%</Table.Td>
            <Table.Td>{formatCurrency(item.vat, invoice.currency)}</Table.Td>
            <Table.Td>{item.quantity}</Table.Td>
            <Table.Td>
              {formatCurrency(item.totalBrutto, invoice.currency)}
            </Table.Td>
          </Table.Tr>
        ))}
        <Table.Tr>
          <Table.Td colSpan={6} />
          <Table.Td>
            <TextField
              label="Netto"
              value={formatCurrency(invoice.summary.netto, invoice.currency)}
            />
            <TextField
              label="VAT"
              value={formatCurrency(invoice.summary.vat, invoice.currency)}
            />
            <TextField
              label="Brutto"
              value={
                <strong>
                  {formatCurrency(invoice.summary.brutto, invoice.currency)}
                </strong>
              }
            />
          </Table.Td>
        </Table.Tr>
      </Table.Body>
    </Table>
  </Paper>
)
