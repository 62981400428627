import React from 'react';
import { Avatar, Tooltip } from 'antd';

export const StatusIcon = ({ value, icon, marginRight, tooltip }) => (
  <Tooltip placement="bottomRight" title={tooltip}>
    <Avatar
      style={{
        backgroundColor: value ? '#87d068' : undefined,
        marginRight: marginRight ? '5px':'0',
      }}
      icon={icon}
    />
  </Tooltip>
)
