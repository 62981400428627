import React from 'react'
import styled from 'styled-components'
import { Table } from 'components'

const TableContainer = styled.div`
  overflow-y: auto;
  overflow-x: scroll;
`

const parseCsvString = string => string.split('\n').map(row => row.split(','))

export const CSVTable = ({ value, hasHeader }) => {
  const rows = parseCsvString(value || '')
  const header = hasHeader ? rows[0] : null
  const content = hasHeader ? rows.slice(1) : rows
  return (
    <TableContainer>
      <Table>
        <Table.Body>
          {header && (
            <Table.Tr>
              {header.map((cell, idx0) => (
                <Table.Th key={idx0}>{cell}</Table.Th>
              ))}
            </Table.Tr>
          )}
          {content.map((row, idx1) => (
            <Table.Tr key={idx1}>
              {row.map((cell, idx2) => (
                <Table.Td key={idx2}>{cell}</Table.Td>
              ))}
            </Table.Tr>
          ))}
        </Table.Body>
      </Table>
    </TableContainer>
  )
}
