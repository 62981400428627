import React from 'react'
import { Modal, Divider } from 'antd'
import { ProductImagesTable } from './ProductImagesTable/ProductImagesTable'

export const ProductImagesSelector = ({
  sku,
  onClose,
  isSelectorOpen,
  productImages,
  refresh,
}) => (
  <Modal
    width="80%"
    title="Zdjęcia towarów"
    visible={isSelectorOpen}
    onCancel={onClose}
    footer={[]}
  >
    <ProductImagesTable
      sku={sku}
      refresh={refresh}
      productImages={productImages}
    />
    <Divider />
  </Modal>
)
