import React from 'react'
import { connect } from 'react-redux'
import { API_BASE_URL_V1 } from 'config'
import { Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const getProps = (token, refresh) => ({
  name: 'file',
  multiple: true,
  action: `${API_BASE_URL_V1}/images`,
  headers: {
    Authorization: token,
  },
  onChange(info) {
    const { status } = info.file
    if (status === 'done') {
      message.success(`${info.file.name} uploadowano pomyślnie.`)
    } else if (status === 'error') {
      message.error(`${info.file.name} nie udało się uploadować.`)
    }
    refresh()
  },
})

const ProductImagesUploaderUnwrapped = ({ token, refresh }) => (
  <Upload.Dragger {...getProps(token, refresh)}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Dodaj nowe zdjęcia produktów</p>
    <p className="ant-upload-hint">
      Jedno zdjęcie może zostać dodane do wielu różnych produktów. Upewnij
      się czy Twoje zdjęcie nie zostało już wcześniej dodane do bazy.
    </p>
  </Upload.Dragger>
)

export const ProductImagesUploader = connect(
  state => ({ token: state.authReducer.token }),
  null
)(ProductImagesUploaderUnwrapped)
