import React, { useState } from 'react'
import { PrinterOutlined } from '@ant-design/icons'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { Input, InputNumber, Modal } from 'antd'
import { TextField } from 'components/TextField/TextField'

export const PrintQrLabel = ({ value }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [copies, setCopies] = useState(1)
  const [printerId, setPrinterId] = useState(
    localStorage.getItem('printer_36x89') || ''
  )
  const handlePrinterIdChange = e => {
    setPrinterId(e.target.value)
    localStorage.setItem('printer_36x89', e.target.value)
  }
  return (
    <>
      <PrinterOutlined
        onClick={() => setIsVisible(true)}
        style={{
          marginLeft: '0.4rem',
          color: '#1890ff',
          cursor: 'pointer',
        }}
      />
      <Modal
        title={`Drukuj ${value}`}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={[]}
      >
        <TextField
          label="Numer drukarki 36x89"
          value={
            <Input
              type="text"
              value={printerId || ''}
              onChange={handlePrinterIdChange}
            />
          }
        />
        <TextField
          label="Ilość kopii"
          value={
            <InputNumber
              min={1}
              max={100}
              value={copies}
              onChange={value => setCopies(value)}
            />
          }
        />
        <RequestButton
          v2
          url={`/print/${printerId}/qr-label/${encodeURIComponent(value)}?copies=${copies}`}
          method="post"
          content="Drukuj"
          unprotected
          type="primary"
          onSuccess={() => setIsVisible(false)}
        />
      </Modal>
    </>
  )
}
