import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Button, Input, Select, Typography } from 'antd'
import { Form } from '@ant-design/compatible'
import { EditOutlined } from '@ant-design/icons'
import { Paper, Right, TextField } from 'components'
import { PrintShippingLabel } from 'components/PrintShippingLabel/PrintShippingLabel'

export class SetShippingLabelUnwrapped extends React.Component {
  state = {
    editMode: false,
  }

  componentDidMount = () => { }

  toggleMode = () => {
    this.setState(
      state => ({ editMode: !state.editMode }),
      () => {
        const { shippingLabel } = this.props.parcel
        if (this.state.editMode && shippingLabel) {
          this.props.form.setFieldsValue(shippingLabel)
        }
      }
    )
  }

  render() {
    const { form, parcel, refresh } = this.props
    const { editMode } = this.state
    return (
      <Paper
        title="Etykieta pocztowa"
        extra={
          <Button
            icon={<EditOutlined />}
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        {!editMode && parcel.shippingLabel && (
          <>
            <TextField
              label="Link"
              value={
                <>
                  <Typography.Text copyable>
                    {parcel.shippingLabel.uri}
                  </Typography.Text>
                  {parcel.shippingLabel.uri && (<PrintShippingLabel uri={parcel.shippingLabel.uri} />)}
                </>
              }
            />
            <TextField
              label="Tracking ID"
              value={
                <Typography.Text copyable>
                  {parcel.shippingLabel.trackingId}
                </Typography.Text>
              }
            />
            <TextField
              label="Firma kurierska"
              value={
                <Typography.Text copyable>
                  {parcel.shippingLabel.provider}
                </Typography.Text>
              }
            />
          </>
        )}
        {(editMode || !parcel.shippingLabel) && (
          <>
            <FormProvider
              url={`/parcels/${parcel.id}/set-shipping-label`}
              form={form}
              onSuccessMessage="Pomyślnie dodano etykietę pocztową!"
              onSuccess={async () => {
                await refresh()
                this.toggleMode()
              }}
              render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row gutter={16}>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Tracking ID">
                        {form.getFieldDecorator('trackingId', {
                          rules: [
                            { required: true, message: 'Wpisz Tracking ID' },
                          ],
                        })(<Input placeholder="Tracking ID" />)}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Link">
                        {form.getFieldDecorator('uri', {
                          rules: [
                            {
                              required: true,
                              message: 'Wpisz link do naklejki PDF',
                            },
                          ],
                        })(<Input placeholder="Link" />)}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Firma kurierska">
                        {form.getFieldDecorator('provider', {
                          rules: [
                            { required: true, message: 'Wybierz dostawcę' },
                          ],
                        })(
                          <Select placeholder="Firma kurierska">
                            {['BRING', 'POSTEN', 'DHL', 'DPD'].map(code => (
                              <Select.Option key={code} value={code}>
                                {code}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Item>
                      <Right>
                        <Button type="primary" htmlType="submit">
                          Zapisz
                        </Button>
                      </Right>
                    </Form.Item>
                  </Row>
                </Form>
              )}
            />
          </>
        )}
      </Paper>
    )
  }
}

export const SetShippingLabel = compose(
  withRouter,
  Form.create({ name: 'parcel-set-shipping-label-form' })
)(SetShippingLabelUnwrapped)
