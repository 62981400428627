import React from 'react'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Paper } from 'components'
import { withResource } from 'hocs/withResource'
import { ProductCategoriesTable } from './ProductCategoriesTable/ProductCategoriesTable'
import { ProductCategorySelector } from './ProductCategorySelector/ProductCategorySelector'

class ProductCategoriesUnwrapped extends React.Component {
  state = {
    isSelectorOpen: false,
  }

  toggleSelector = () => {
    this.setState(state => ({ isSelectorOpen: !state.isSelectorOpen }))
  }

  render() {
    const { productCategories, sku } = this.props
    const { isSelectorOpen } = this.state
    return (
      <>
        <Paper
          title="Kategorie"
          extra={
            <Button
              icon={<EditOutlined />}
              type="dashed"
              onClick={this.toggleSelector}
            />
          }
        >
          <ProductCategoriesTable
            categories={productCategories.data || []}
            sku={sku}
            refresh={productCategories.refresh}
          />
        </Paper>
        <ProductCategorySelector
          isSelectorOpen={isSelectorOpen}
          productCategories={productCategories.data || []}
          sku={sku}
          onClose={this.toggleSelector}
          refresh={productCategories.refresh}
        />
      </>
    )
  }
}

export const ProductCategories = withResource({
  name: 'productCategories',
  url: props => `/products/${encodeURIComponent(props.sku)}/categories`,
})(ProductCategoriesUnwrapped)
