import React from 'react'
import { Grid, TextField, Paper } from 'components'
import { Typography } from 'antd'

export const EventsLog = ({ events }) => (
  <Paper title="Log zdarzeń" maxHeight="400px">
    {events.length === 0 && <Typography>Brak zdarzeń</Typography>}
    <Grid padding="0">
      {events.reverse().map(event => {
        const { uuid, name, version, timestamp, aggregateId, ...rest } = event
        return (
          <Grid.Row key={event.uuid}>
            <Grid.Item>
              <TextField label="UUID" value={event.uuid} />
              <TextField label="Nazwa" value={event.name} />
              <TextField label="Wersja" value={event.version} />
              <TextField label="Czas" value={event.timestamp} />
              <TextField
                label="Dane"
                value={<small>{JSON.stringify(rest, null, 2)}</small>}
              />
            </Grid.Item>
          </Grid.Row>
        )
      })}
    </Grid>
  </Paper>
)
