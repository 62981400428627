import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col, Button, Table } from 'antd'
import {
  PlusOutlined,
  ArrowsAltOutlined,
  BarcodeOutlined,
  MailOutlined,
  CheckOutlined,
  InboxOutlined,
} from '@ant-design/icons'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { StatsBadge, StatusIcon, ParcelStatus, RequestButton, FulfillmentsWidget } from 'components'
import { formatDateTime } from 'utils/formatters'
import { pathOr } from 'ramda'
import { ParcelCourierStatus } from './ParcelCourierStatus/ParcelCourierStatus'

const columns = [
  {
    title: 'Numer',
    dataIndex: 'id',
    key: 'id',
    render: id => <Link to={`/parcels/${id}`}>{id.slice(-8)}</Link>,
  },
  {
    title: 'Status pakowania',
    dataIndex: 'status',
    render: (_, parcel) => <ParcelStatus status={parcel.status} />,
  },
  {
    title: 'Status przesyłki',
    dataIndex: 'status',
    render: (_, parcel) => <ParcelCourierStatus parcel={parcel} />,
  },
  {
    title: 'Adresat',
    render: record => (
      <React.Fragment>
        <div>
          <strong>{pathOr('', ['deliveryAddress', 'name'], record)}</strong>
        </div>
        <div>
          <small>
            {pathOr('', ['deliveryAddress', 'addressLine1'], record)}{' '}
            {pathOr('', ['deliveryAddress', 'addressLine2'], record)},{' '}
            {pathOr('', ['deliveryAddress', 'postalCode'], record)}{' '}
            {pathOr('', ['deliveryAddress', 'city'], record)}
          </small>
        </div>
      </React.Fragment>
    ),
  },
  {
    title: "Zamówienia",
    render: record => <FulfillmentsWidget saleIds={record.saleIds} />
  },
  {
    render: record => (
      <StatsBadge
        count={record.items.length}
        icon={<InboxOutlined />}
        tooltip="Produktów w paczce"
      />
    ),
  },
  {
    render: parcel => (
      <React.Fragment>
        <StatusIcon
          value={!!parcel.measurements}
          icon={<ArrowsAltOutlined />}
          marginRight
          tooltip="Waga i wymiary"
        />
        <StatusIcon
          value={!!parcel.shippingLabel}
          icon={<BarcodeOutlined />}
          marginRight
          tooltip="Naklejka pocztowa"
        />
        <StatusIcon
          value={parcel.isSent}
          icon={<MailOutlined />}
          marginRight
          tooltip="Wysłana"
        />
        <StatusIcon
          value={parcel.isDelivered}
          icon={<CheckOutlined />}
          marginRight
          tooltip="Dostarczona"
        />
      </React.Fragment>
    ),
  },
  {
    title: 'Dodano',
    render: record => formatDateTime(record.createdAt),
  },
  {
    title: 'Aktualizowano',
    render: record => formatDateTime(record.updatedAt),
  },
]

const ParcelsListUnwrapped = ({ history }) => (
  <MainLayout header="Paczki" minWidth>
    <Row style={{ marginBottom: '3rem' }}>
      <Col md={24} style={{ textAlign: 'right' }}>
        <Button.Group>
          <RequestButton
            id="parcels-create-button"
            content={
              <>
                <PlusOutlined /> Dodaj
              </>
            }
            method="post"
            url="parcels"
            type="primary"
            onSuccessMessage="Utworzono nową paczkę!"
            onSuccess={parcel => {
              history.push(`/parcels/${parcel.id}`)
            }}
          />
        </Button.Group>
      </Col>
    </Row>
    <Row>
      <Col span={24}>
        <ResourceProvider
          url="/parcels"
          pagination
          encodePageNumber
          render={({ data, paginationParams, loading, onChange }) => (
            <Table
              columns={columns}
              dataSource={data}
              rowKey="id"
              pagination={{
                ...paginationParams,
                showSizeChanger: true,
              }}
              loading={loading}
              onChange={onChange}
            />
          )}
        />
      </Col>
    </Row>
  </MainLayout>
)

export const ParcelsList = withRouter(ParcelsListUnwrapped)
