import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col, Table, Tag, Divider } from 'antd'

const renderLegalEntity = entity => (
  <span>
    <b>{entity.email}</b>
    <div>
      {entity.address.addressLine1}{' '}
      {entity.address.addressLine2 && (
        <React.Fragment>
          <Divider type="vertical" />
          {entity.address.addressLine2}
        </React.Fragment>
      )}
    </div>
    <div>
      {entity.address.city || '-'}
      <Divider type="vertical" />
      {entity.address.postalCode || '-'}
    </div>
  </span>
)

const columns = [
  {
    title: 'Number',
    dataIndex: 'number',
    key: 'number',
    render: text => <Link to={`/customers/${text}`}>{text}</Link>,
  },
  {
    title: 'Nadawca',
    dataIndex: 'customer',
    key: 'customer',
    render: renderLegalEntity,
  },
  {
    title: 'L.P.',
    render: record => record.lineItems.length,
  },
  {
    title: 'Tagi',
    key: 'tags',
    dataIndex: 'tags',
    render: tags => (
      <span>
        {tags.map(tag => (
          <Tag color={tag.color || 'blue'} key={tag.value}>
            {tag.value}
          </Tag>
        ))}
      </span>
    ),
  },
  {
    render: document => (
      <Link to={`/documents/${document.number}`}>Zobacz</Link>
    ),
  },
]

const data = [
  {
    key: '1',
    number: '',
    email: 'name.surname@gmail.com',
    customer: {
      name: 'Name Surname',
      address: {
        addressLine1: 'ul. Niedźwiedziniec 10',
        addressLine2: '',
        city: 'Chorzów',
        postalCode: '41-506',
      },
    },
    tags: [
      {
        value: 'Rozliczone',
        color: 'red',
      },
    ],
    lineItems: [],
  },
]

class CustomerListUnwrapped extends React.Component {
  render() {
    return (
      <MainLayout header="Klienci" minWidth>
        <Row>
          <Col span={24}>
            <Table columns={columns} dataSource={data} />
          </Col>
        </Row>
      </MainLayout>
    )
  }
}

export const CustomerList = withRouter(CustomerListUnwrapped)
