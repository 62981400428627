import React from 'react'
import { Drawer } from 'antd'
import { Grid } from 'components'

export const StockLevelsDrawer = ({ onClose, visible, params }) => (
  <Drawer
    title={`Stany magazynowe - ${params.productNumber}`}
    placement="right"
    closable
    onClose={onClose}
    visible={visible}
    width={360}
  >
    <Grid.Row>
      <Grid.Header>Lokalizacja</Grid.Header>
      <Grid.Header>Stan</Grid.Header>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col>
        <Grid.Item>A01</Grid.Item>
      </Grid.Col>
      <Grid.Col>
        <Grid.Item>12szt</Grid.Item>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col>
        <Grid.Item>A02</Grid.Item>
      </Grid.Col>
      <Grid.Col>
        <Grid.Item>8szt</Grid.Item>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col>
        <Grid.Item>R01</Grid.Item>
      </Grid.Col>
      <Grid.Col>
        <Grid.Item>6szt</Grid.Item>
      </Grid.Col>
    </Grid.Row>
    <Grid.Row>
      <Grid.Col>
        <Grid.Item>EG-04</Grid.Item>
      </Grid.Col>
      <Grid.Col>
        <Grid.Item>3szt</Grid.Item>
      </Grid.Col>
    </Grid.Row>
  </Drawer>
)
