import React from 'react'
import { Row, Col, Table, Avatar } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { TableSearch } from 'components/TableSearch/TableSearch'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { ProductImagesUploader } from './ProductImagesUploader/ProductImagesUploader'
import { ProductImagesUrlUploader } from './ProductImagesUrlUploader/ProductImagesUrlUploader'

const getColumns = (selectedURIs, sku, refresh) => {
  const isSelected = uri => selectedURIs.includes(uri)
  return [
    {
      title: '',
      dataIndex: 'actions',
      render: (_text, image) =>
        isSelected(image.uri) ? (
          <RequestButton
            content="Odlinkuj"
            method="delete"
            url={`/products/${encodeURIComponent(sku)}/images/${image.uri}`}
            type="link"
            onSuccessMessage={'Pomyślnie odlinkowano zdjęcie!'}
            onSuccess={refresh}
          />
        ) : (
          <RequestButton
            content="Zalinkuj"
            method="post"
            url={`/products/${encodeURIComponent(sku)}/images/${image.uri}`}
            type="link"
            onSuccessMessage={'Pomyślnie przypisano zdjęcie do produktu!'}
            onSuccess={refresh}
          />
        ),
    },
    {
      title: 'Zdjęcie',
      dataIndex: 'publicUrl',
      render: url => <Avatar shape="square" size={100} src={url} />,
    },
    {
      title: 'URI',
      dataIndex: 'uri',
    },
    {
      title: 'Oryginalna nazwa pliku',
      dataIndex: 'originalFilename',
      sorter: true,
    },
    {
      title: '',
      render: (_text, image) => (
        <RequestButton
          content="Usuń z bazy"
          method="delete"
          url={`/images/${image.uri}`}
          type="link"
          onSuccessMessage={'Pomyślnie usunięto zdjęcie!'}
          onSuccess={refresh}
        />
      ),
    },
  ]
}

export const ProductImagesTable = ({ productImages, sku, refresh }) => {
  const selectedURIs = productImages.map(image => image.uri)
  return (
    <ResourceProvider
      url="/images"
      pagination
      render={({
        data,
        paginationParams,
        filterParams,
        loading,
        onChange,
        onFiltersChange,
        refresh: refreshImages,
      }) => (
        <>
          <Row style={{ marginBottom: '3rem' }}>
            <Col md={12}>
              <TableSearch
                onFiltersChange={onFiltersChange}
                filterParams={filterParams}
                options={{
                  originalFilename: 'Nazwa pliku',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={24} style={{ marginBottom: '2rem' }}>
              <Table
                rowKey="uri"
                columns={getColumns(selectedURIs, sku, () => {
                  refresh()
                  refreshImages()
                })}
                dataSource={data || []}
                pagination={{
                  ...paginationParams,
                  showSizeChanger: true,
                }}
                loading={loading}
                onChange={onChange}
              />
            </Col>
          </Row>
          <Row>
            <ProductImagesUrlUploader refresh={refreshImages} />
            <ProductImagesUploader refresh={refreshImages} />
          </Row>
        </>
      )}
    />
  )
}
