import React, { useState } from 'react'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Select, Button, Input, Modal } from 'antd'
import { Form } from '@ant-design/compatible'
import { countryCodes } from 'constants/countryCodes'
import { Paper, Right } from 'components'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

const CreateSupplierModalUnwrapped = ({ onSuccess, form }) => {
  const [isVisible, setIsVisible] = useState(false)
  return (
    <>
      <Button onClick={() => setIsVisible(true)}>Dodaj dostawcę</Button>
      <Modal
        width="80%"
        title="Dodaj dostawcę"
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
        footer={null}
      >
        <FormProvider
          v2
          url="/suppliers"
          form={form}
          onSuccessMessage="Pomyślnie dodano dostawcę!"
          onSuccess={(id) => {
            onSuccess(id)
            setIsVisible(false)
          }}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} id="supplier-create-form">
              <Row>
                <Col xxl={12} xl={20} lg={24} xs={24}>
                  <Row type="flex" justify="space-between">
                    <Col lg={24} xs={24}>
                      <Paper title="Informacje podstawowe">
                        <Row gutter={16}>
                          <Col lg={12} xs={24}>
                            <Form.Item label="Nazwa">
                              {form.getFieldDecorator('name', {
                                rules: [{ required: true }],
                              })(<Input placeholder="Nazwa" />)}
                            </Form.Item>
                          </Col>
                          <Col lg={12} xs={24}>
                            <Form.Item label="NIP / Nr VAT">
                              {form.getFieldDecorator('taxNumber',)(<Input placeholder="NIP / Nr VAT" />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Paper>
                      <Paper title="Adres">
                        <Row gutter={16}>
                          <Col lg={12} xs={24}>
                            <Form.Item label="Adres 1">
                              {form.getFieldDecorator('addressLine1', {
                                rules: [{ required: true, message: 'Wpisz adres' }],
                              })(<Input placeholder="Adres 1" />)}
                            </Form.Item>
                          </Col>
                          <Col lg={12} xs={24}>
                            <Form.Item label="Adres 2">
                              {form.getFieldDecorator('addressLine2')(
                                <Input placeholder="Adres 2" />
                              )}
                            </Form.Item>
                          </Col>
                          <Col lg={12} xs={24}>
                            <Form.Item label="Miasto">
                              {form.getFieldDecorator('city', {
                                rules: [
                                  { required: true, message: 'Wpisz miasto' },
                                ],
                              })(<Input placeholder="Miasto" />)}
                            </Form.Item>
                          </Col>
                          <Col lg={12} xs={24}>
                            <Form.Item label="Kod pocztowy">
                              {form.getFieldDecorator('postalCode', {
                                rules: [
                                  { required: true, message: 'Wpisz kod pocztowy' },
                                ],
                              })(<Input placeholder="Kod pocztowy" />)}
                            </Form.Item>
                          </Col>
                          <Col lg={12} xs={24}>
                            <Form.Item label="Kod kraju">
                              {form.getFieldDecorator('countryCode', {
                                rules: [
                                  { required: true, message: 'Wybierz kod kraju' },
                                ],
                              })(
                                <Select placeholder="Kod kraju">
                                  {countryCodes.map(code => (
                                    <Select.Option key={code} value={code}>
                                      {code}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Paper>
                      <Row>
                        <Form.Item>
                          <Right>
                            <Button type="primary" htmlType="submit">
                              Dodaj
                            </Button>
                          </Right>
                        </Form.Item>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        />
      </Modal>
    </>
  )
}

export const CreateSupplierModal = compose(
  withRouter,
  Form.create({ name: 'supplier-modal-create-form' })
)(CreateSupplierModalUnwrapped)
