import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { countryCodes } from 'constants/countryCodes'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Button, Input, Select, Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { Paper, Right, TextField, Copy } from 'components'
import { AddressPicker } from './AddressPicker/AddressPicker'

export class AddressUnwrapped extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(state => ({ editMode: !state.editMode }))
  }

  setAddress = address => {
    this.props.form.setFieldsValue(address)
  }

  getEtiquetteText = (deliveryAddress = {}) => {
    const {
      name,
      addressLine1,
      addressLine2,
      postalCode,
      city,
    } = deliveryAddress
    return `MOTTAKER:\n${name}\n${addressLine1} ${addressLine2}\n${postalCode} ${city}`
  }

  render() {
    const { form, parcel, refresh } = this.props
    const { editMode } = this.state
    return (
      <Paper
        title="Adres wysyłki"
        extra={
          <Button
            icon={<EditOutlined />}
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        {!editMode && parcel.deliveryAddress && (
          <Row>
            <Col md={12} sm={24}>
              <TextField
                label="Imię i nazwisko / Nazwa"
                value={
                  <Typography.Text copyable>
                    {parcel.deliveryAddress.name}
                  </Typography.Text>
                }
              />
              <TextField
                label="NIP (org.nr)"
                value={
                  <Typography.Text copyable>
                    {parcel.deliveryAddress.taxId}
                  </Typography.Text>
                }
              />
              <TextField
                label="Telefon"
                value={
                  <Typography.Text copyable>
                    {parcel.deliveryAddress.telephone}
                  </Typography.Text>
                }
              />
              <TextField
                label="Adres 1"
                value={
                  <Typography.Text copyable>
                    {parcel.deliveryAddress.addressLine1}
                  </Typography.Text>
                }
              />
              <TextField
                label="Adres 2"
                value={
                  <Typography.Text copyable>
                    {parcel.deliveryAddress.addressLine2}
                  </Typography.Text>
                }
              />
              <TextField
                label="Miasto"
                value={
                  <Typography.Text copyable>
                    {parcel.deliveryAddress.city}
                  </Typography.Text>
                }
              />
              <TextField
                label="Kod pocztowy"
                value={
                  <Typography.Text copyable>
                    {parcel.deliveryAddress.postalCode}
                  </Typography.Text>
                }
              />
              <TextField
                label="Kod kraju"
                value={
                  <Typography.Text copyable>
                    {parcel.deliveryAddress.countryCode}
                  </Typography.Text>
                }
              />
            </Col>
            <Col md={12} sm={24}>
              Kopiuj tekst etykiety Mottaker{' '}
              <Copy value={this.getEtiquetteText(parcel.deliveryAddress)} />
            </Col>
          </Row>
        )}
        {(editMode || !parcel.deliveryAddress) && (
          <>
            <AddressPicker parcel={parcel} setAddress={this.setAddress} />
            <FormProvider
              url={`/parcels/${parcel.id}/address`}
              form={form}
              onSuccessMessage="Pomyślnie zaadresowano paczkę!"
              onSuccess={async () => {
                await refresh()
                this.toggleMode()
              }}
              render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} id="parcel-address-form">
                  <Row gutter={16}>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Imie i nazwisko / Nazwa">
                        {form.getFieldDecorator('name', {
                          rules: [{ required: true, message: 'Wpisz adres' }],
                        })(
                          <Input placeholder="Imie i nazwisko lub nazwa firmy" />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label="NIP (org.nr.)">
                        {form.getFieldDecorator('taxId')(
                          <Input placeholder="NIP" />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Telefon">
                        {form.getFieldDecorator('telephone')(
                          <Input placeholder="Telefon" />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Adres 1">
                        {form.getFieldDecorator('addressLine1', {
                          rules: [{ required: true, message: 'Wpisz adres' }],
                        })(<Input placeholder="Adres 1" />)}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Adres 2">
                        {form.getFieldDecorator('addressLine2')(
                          <Input placeholder="Adres 2" />
                        )}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Miasto">
                        {form.getFieldDecorator('city', {
                          rules: [{ required: true, message: 'Wpisz miasto' }],
                        })(<Input placeholder="Miasto" />)}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Kod pocztowy">
                        {form.getFieldDecorator('postalCode', {
                          rules: [
                            { required: true, message: 'Wpisz kod pocztowy' },
                          ],
                        })(<Input placeholder="Kod pocztowy" />)}
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item label="Kod kraju">
                        {form.getFieldDecorator('countryCode', {
                          rules: [
                            { required: true, message: 'Wybierz kod kraju' },
                          ],
                        })(
                          <Select placeholder="Kod kraju">
                            {countryCodes.map(code => (
                              <Select.Option key={code} value={code}>
                                {code}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Item>
                      <Right>
                        <Button type="primary" htmlType="submit">
                          Zapisz
                        </Button>
                      </Right>
                    </Form.Item>
                  </Row>
                </Form>
              )}
            />
          </>
        )}
      </Paper>
    )
  }
}

export const Address = compose(
  withRouter,
  Form.create({ name: 'parcel-address-form' })
)(AddressUnwrapped)
