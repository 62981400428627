import React from 'react'
import styled from 'styled-components'
import * as api from 'api'
import { Tag, Timeline, Modal } from 'antd'

const Description = styled.div`
  max-width: 300px;
  font-size: 10x;
`

const StatusTag = ({ status, ...props }) => {
  switch (status) {
    case 'HANDED_IN':
      return (
        <Tag color="#108ee9" {...props}>
          Nadana
        </Tag>
      )
    case 'IN_TRANSIT':
      return (
        <Tag color="#722ed1" {...props}>
          W transporcie
        </Tag>
      )
    case 'UNKNOWN':
      return (
        <Tag color="#722ed1" {...props}>
          Nieznany
        </Tag>
      )
    case 'READY_FOR_PICKUP':
      return (
        <Tag color="#faad14" {...props}>
          Gotowa do odbioru
        </Tag>
      )
    case 'DELIVERED':
      return (
        <Tag color="#87d068" {...props}>
          Dostarczona do klienta
        </Tag>
      )
    default:
      return <Tag {...props}>{status || 'Nieznany'}</Tag>
  }
}

export class ParcelCourierStatus extends React.Component {
  state = {
    status: null,
    error: false,
    loading: true,
    description: '',
    isModalOpen: false,
  }

  componentDidMount = async () => {
    const { shippingLabel } = this.props.parcel
    if (
      !shippingLabel ||
      shippingLabel.provider !== 'POSTEN' ||
      !shippingLabel.trackingId
    ) {
      this.setState({ loading: false })
      return
    }

    try {
      const response = await api.fetchParcelCourierStatus(this.props.parcel.id)
      const { events, status, description } = response.data.data
      this.setState({
        events,
        status,
        description,
        loading: false,
      })
    } catch {
      this.setState({
        error: true,
        loading: false,
      })
    }
  }

  toggleModal = () => {
    this.setState(state => ({ isModalOpen: !state.isModalOpen }))
  }

  render() {
    const { status, error, loading, isModalOpen, events } = this.state
    if (loading) {
      return ''
    }
    if (error) {
      return <Tag color="#f50">Błąd</Tag>
    }
    if (!status) {
      return <Tag>Brak</Tag>
    }
    return (
      <>
        <Modal
          title={this.props.parcel.id}
          visible={isModalOpen}
          onCancel={this.toggleModal}
          footer={null}
        >
          <Timeline>
            {(events || []).map((event, i) => (
              <Timeline.Item key={i}>
                <StatusTag status={event.status} />
                <Description
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
                <div>
                  <small>
                    {event.displayDate} {event.displayTime}
                  </small>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
        </Modal>
        <StatusTag status={status} onClick={this.toggleModal} />
      </>
    )
  }
}
