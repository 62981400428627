import React, { useState } from 'react'
import { generateWarehouseStickersUri } from 'api'
import { API_BASE_URL_V2 } from 'config'
import { Button } from 'antd'
import { showError } from 'layouts/notifications'

export const GenerateLocationLabelsButton = ({ items }) => {
  const [isProcessing, setIsProcessing] = useState(false)

  const generate = async () => {
    setIsProcessing(true)
    try {
      const { data, error } = await generateWarehouseStickersUri(items)
      if (data) {
        const proxy = window.open(
          `${API_BASE_URL_V2}/stickers/warehouse-stickers/${data.data}`,
          '_blank',
        )
        if (proxy) {
          proxy.focus()
        }
      }
      if (error) {
        console.log(error)
        showError('Coś poszło nie tak')
      }
    } catch (err) {

    }
    setIsProcessing(false)
  }

  return (
    <Button disabled={!items.length} loading={isProcessing} onClick={generate}>
      Generuj ({items.length})
    </Button>
  )
}
