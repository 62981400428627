import React from 'react'
import { compose } from 'recompose'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Input, Button } from 'antd'
import { Form } from '@ant-design/compatible'

export const WarehouseFormUnwrapped = ({ form, refresh }) => (
  <FormProvider
    url="/warehouses"
    form={form}
    onSuccessMessage="Pomyślnie dodano magazyn!"
    onSuccess={refresh}
    render={({ handleSubmit }) => (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xxl={8} xl={8} lg={8} xs={24}>
            <Form.Item label="Kod magazynu">
              {form.getFieldDecorator('name', {
                rules: [{ required: true, message: 'Podaj nazwę magazynu' }],
              })(<Input placeholder="Nazwa" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Dodaj
            </Button>
          </Form.Item>
        </Row>
      </Form>
    )}
  />
)

export const WarehouseForm = compose(
  Form.create({ name: 'warehouse-create-form' })
)(WarehouseFormUnwrapped)
