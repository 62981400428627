import React from 'react'
import { Tabs } from 'antd'
import { Paper } from 'components'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { withSalesChannels } from 'hocs/withSalesChannels'
import { PricesList } from './PricesList/PricesList'

class ProductSalesPricesUnwrapped extends React.Component {
  state = {
    activeTabKey: '0',
  }

  handleTabChange = activeTabKey => {
    this.setState({ activeTabKey })
  }

  groupPricesBySalesChannel = prices =>
    prices.reduce((acc, price) => {
      if (!acc[price.salesChannelCode]) {
        acc[price.salesChannelCode] = [price]
      } else {
        acc[price.salesChannelCode].push(price)
      }
      return acc
    }, {})

  render() {
    const { activeTabKey } = this.state
    const { salesChannels, sku } = this.props
    return (
      <Paper title="Ceny sprzedaży">
        <ResourceProvider
          url={`/products/sales-prices?sku=${encodeURIComponent(sku)}`}
          responseNormalizer={this.groupPricesBySalesChannel}
          render={({ data, refresh }) =>
            data && (
              <Tabs
                activeKey={activeTabKey}
                onChange={this.handleTabChange}
                animated={false}
              >
                {salesChannels.map((salesChannel, index) => (
                  <Tabs.TabPane
                    tab={salesChannel.code.toUpperCase()}
                    key={`${index}`}
                  >
                    {activeTabKey === `${index}` && (
                      <PricesList
                        prices={data[salesChannel.code] || []}
                        refresh={refresh}
                        salesChannelCode={salesChannel.code}
                        sku={sku}
                      />
                    )}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            )
          }
        />
      </Paper>
    )
  }
}

export const ProductSalesPrices = withSalesChannels(ProductSalesPricesUnwrapped)
