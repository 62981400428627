import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Row, Col, Form, Button } from 'antd'
import { Paper, Right } from 'components'
import { BillingSelector } from './BillingSelector/BillingSelector'
import { BillingList } from './BillingList/BillingList'
import { RecipientAddress } from './RecipientAddress/RecipientAddress'
import { Recipient } from './Recipient/Recipient'
import { removeEmptyStringKeys } from 'utils'

class SaleInvoiceCreateUnwrapped extends React.Component {
  state = {
    billings: [],
    invoicedItems: [],
    recipientAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      countryCode: '',
    },
    recipient: {
      name: '',
      isCorporate: false,
      taxId: '',
      emailAddress: '',
      userId: '',
    },
    errors: {},
  }

  handleRecipientAddressChange = field => value => {
    this.setState(state => ({
      recipientAddress: {
        ...state.recipientAddress,
        [field]: value,
      },
      errors: {},
    }))
  }

  handleRecipientChange = field => value => {
    this.setState(state => ({
      recipient: {
        ...state.recipient,
        [field]: value,
      },
      errors: {},
    }))
  }

  addBilling = billing => {
    const { billings } = this.state
    const newBillings = [...billings.filter(f => f.id !== billing.id), billing]

    const invoicedItems = newBillings.reduce(
      (acc, billing) => [
        ...acc,
        ...billing.remainingToInvoice.map(item => {
          const orderItem = billing.items.find(oi => oi.sku === item.sku)
          return {
            ...item,
            saleId: billing.id,
            netto: orderItem.netto,
            vatRate: orderItem.vatRate,
          }
        }),
      ],
      []
    )

    const recipient = {
      name: billing.billingAddress.name,
      isCorporate: false,
      taxId: billing.billingAddress.taxId || '',
      emailAddress: billing.emailAddress || '',
      userId: billing.userId || '',
    }

    this.setState({
      billings: newBillings,
      invoicedItems,
      recipientAddress: billing.billingAddress,
      recipient,
      errors: {},
    })
  }

  getInvoicedItem = (saleId, sku) => {
    // eslint-disable-next-line no-unused-vars
    for (const item of this.state.invoicedItems) {
      if (item.saleId === saleId && item.sku === sku) {
        return item
      }
    }
    return {}
  }

  handleItemQuantityChange = (saleId, sku) => quantity => {
    this.setState(state => ({
      invoicedItems: state.invoicedItems.map(item => {
        if (item.sku === sku && item.saleId === saleId) {
          return {
            ...item,
            quantity,
          }
        }
        return item
      }),
    }))
  }

  onSubmit = handleSubmit => async () => {
    const { invoicedItems, recipient, recipientAddress } = this.state
    const errors = this.getErrors()
    if (Object.keys(errors).length === 0) {
      await handleSubmit({
        items: invoicedItems.filter(item => item.quantity > 0),
        recipient: removeEmptyStringKeys(recipient),
        recipientAddress: removeEmptyStringKeys(recipientAddress),
      })
    } else {
      this.setState({
        errors,
      })
    }
  }

  getErrors = () => {
    const { recipient, recipientAddress } = this.state
    let errors = {}

    if (!recipient.name) {
      errors.name = 'To pole jest wymagane'
    }
    // eslint-disable-next-line no-unused-vars
    for (const field of ['addressLine1', 'city', 'postalCode', 'countryCode']) {
      if (!recipientAddress[field]) {
        errors[field] = 'To pole jest wymagane'
      }
    }
    return errors
  }

  render() {
    const {
      billings,
      invoicedItems,
      recipient,
      recipientAddress,
      errors,
    } = this.state
    const { history } = this.props
    return (
      <MainLayout
        header="Dodaj nową fakturę sprzedaży"
        backgroundColor="none"
        marginTop="0"
      >
        <FormProvider
          url="/sale-invoices"
          onSuccessMessage="Pomyślnie dodano nową fakturę sprzedaży!"
          onSuccess={saleInvoice => {
            history.push(`/sale-invoices/${saleInvoice.id}`)
          }}
          render={({ handleSubmit }) => (
            <Row>
              <Col xxl={12} xl={20} lg={24} xs={24}>
                <Row type="flex" justify="space-between">
                  <Col lg={24} xs={24}>
                    <Paper title="Wybierz zamówienia">
                      <Row>
                        <Col lg={24} xs={24}>
                          <BillingSelector
                            billings={billings}
                            addBilling={this.addBilling}
                          />
                        </Col>
                      </Row>
                    </Paper>
                    {billings.length > 0 && (
                      <Row gutter={16}>
                        <Col lg={12} xs={24}>
                          <Paper title="Adresat">
                            <Recipient
                              recipient={recipient}
                              billings={billings}
                              handleRecipientChange={this.handleRecipientChange}
                              errors={errors}
                            />
                          </Paper>
                        </Col>
                        <Col lg={12} xs={24}>
                          <Paper title="Adres faktury">
                            <RecipientAddress
                              recipientAddress={recipientAddress}
                              billings={billings}
                              handleRecipientAddressChange={
                                this.handleRecipientAddressChange
                              }
                              errors={errors}
                            />
                          </Paper>
                        </Col>
                      </Row>
                    )}
                    <Paper title="Pozycje na fakturze">
                      <Row>
                        <Col lg={24} xs={24}>
                          <BillingList
                            billings={billings}
                            getInvoicedItem={this.getInvoicedItem}
                            handleItemQuantityChange={
                              this.handleItemQuantityChange
                            }
                          />
                        </Col>
                      </Row>
                    </Paper>
                    <Row>
                      <Form.Item>
                        <Right>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={invoicedItems.length === 0}
                            onClick={this.onSubmit(handleSubmit)}
                          >
                            Stwórz
                          </Button>
                        </Right>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        />
      </MainLayout>
    )
  }
}

export const SaleInvoiceCreate = compose(withRouter)(SaleInvoiceCreateUnwrapped)
