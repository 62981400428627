import React from 'react'
import { Link } from 'react-router-dom'
import { ProductNumber, Paper, Table } from 'components'
import { UnpackItemModal } from './UnpackItemModal/UnpackItemModal'

export const ParcelItems = ({ parcel, refresh, disabled }) => (
  <Paper title="Zawartość paczki">
    <Table>
      <Table.Body>
        <Table.Tr>
          <Table.Th>SKU</Table.Th>
          <Table.Th>Zamówienie</Table.Th>
          <Table.Th>Zapakowano</Table.Th>
          <Table.Th />
        </Table.Tr>
        {parcel.items.length === 0 && (
          <Table.Tr>
            <Table.Td colSpan={3}>
              <small>Brak</small>
            </Table.Td>
          </Table.Tr>
        )}
        {parcel.items.length > 0 &&
          parcel.items.map(item => (
            <Table.Tr key={item.uuid}>
              <Table.Td>
                <ProductNumber sku={item.sku} />
              </Table.Td>
              <Table.Td>
                <Link to={`/sales/${item.saleId}`}>
                  {item.saleId.slice(-8)}
                </Link>
              </Table.Td>
              <Table.Td>{item.quantity}</Table.Td>
              <Table.Td>
                <UnpackItemModal
                  disabled={disabled || item.packedQuantity === 0}
                  sku={item.sku}
                  parcelId={parcel.id}
                  refresh={refresh}
                  uuid={item.uuid}
                />
              </Table.Td>
            </Table.Tr>
          ))}
      </Table.Body>
    </Table>
  </Paper>
)
