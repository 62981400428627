import React from 'react'
import { Header } from './styled/Header'
import { Typography } from 'antd'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { blue } from '@ant-design/colors'

const getType = (isSelected, isParentSelected) => {
  if (isSelected) {
    return 'warning'
  }
  if (isParentSelected) {
    return 'secondary'
  }
}

const Label = ({ children, isSelected, isParentSelected }) => (
  <Typography.Text
    strong={isSelected}
    type={getType(isSelected, isParentSelected)}
    style={{ color: isSelected ? blue.primary : undefined }}
  >
    {children}
  </Typography.Text>
)

const CategoryLabel = ({ category, isSelected, isParentSelected }) => (
  <>
    <Label isSelected={isSelected} isParentSelected={isParentSelected}>
      {category.pl} {category.no && '/'}
    </Label>
    <Label isSelected={isSelected} isParentSelected={isParentSelected}>
      {' '}
      {category.no} {category.en && '/'}
    </Label>
    <Label isSelected={isSelected} isParentSelected={isParentSelected}>
      {' '}
      {category.en}
    </Label>
  </>
)

export const CategoryTree = ({
  selectedIDs,
  category,
  level,
  categories,
  isParentSelected,
  sku,
  refresh,
}) => {
  const isSelected = category && selectedIDs.includes(category.uuid)
  return (
    <>
      {category && (
        <Header level={level} isParentSelected={isParentSelected}>
          <div>
            <CategoryLabel
              category={category}
              isSelected={isSelected}
              isParentSelected={isParentSelected}
            />
          </div>
          {!isParentSelected && (
            <RequestButton
              method={isSelected ? 'delete' : 'post'}
              url={`products/${encodeURIComponent(sku)}/categories/${
                category.uuid
              }`}
              content={isSelected ? 'Usuń' : 'Dodaj'}
              onSuccessMessage={
                isSelected ? 'Usunięto pomyślnie!' : 'Dodano pomyślnie!'
              }
              onSuccess={refresh}
            />
          )}
        </Header>
      )}
      {categories.map(
        category =>
          category.children && (
            <CategoryTree
              key={category.uuid}
              level={level + 1}
              sku={sku}
              categories={category.children}
              category={category}
              selectedIDs={selectedIDs}
              isParentSelected={isParentSelected || isSelected}
              refresh={refresh}
            />
          )
      )}
    </>
  )
}

CategoryTree.defaultProps = {
  level: -1,
  isParentSelected: false,
}
