import React from 'react'
import { TextField, Paper, RequestButton } from 'components'
import { formatDateTime } from 'utils/formatters'

export const BookReference = ({ invoice }) => (
  <Paper title="Wpis">
    <TextField
      label="Program księgowy"
      value={invoice.bookReference.provider}
    />
    <TextField label="Numer faktury" value={invoice.bookReference.number} />
    <TextField
      label="Link"
      value={
        <RequestButton
          content={`Fiken ${invoice.bookReference.number}`}
          method="get"
          url={`/sale-invoices/pdf/${invoice.bookReference.uri}`}
          onSuccess={data => {
            const tab = window.open(data.url, '_blank')
            if (tab) {
              tab.focus()
            }
          }}
        />
      }
    />
    <TextField
      label="Zaksięgowano"
      value={formatDateTime(invoice.bookReference.bookedAt)}
    />
  </Paper>
)
