import React, { useState } from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { SizedBox, Spacing, Table, TextField } from 'components'
import { Input } from 'antd'
import { GenerateLocationLabelsButton } from './GenerateLocationLabelsButton/GenerateLocationLabelButton'

const displayLocation = (zone, location) =>
  [
    zone.toString(),
    location[0].toString().padStart(2, '0'),
    location[1].toString().padStart(2, '0'),
    location[2].toString().padStart(2, '0'),
    location[3].toString().padStart(3, '0'),
  ].join('-')

export const GenerateWarehouseStickers = () => {
  const [zone, setZone] = useState('NO1')
  const [tsv, setTsv] = useState('')
  const [locations, setLocations] = useState([])

  const updateTsv = (value) => {
    setTsv(value)
    try {
      const locationsData = value.split('\n').map((row) => row.split('\t'))
      setLocations(
        locationsData.map((item) => [
          Number(item[0]) || 0,
          Number(item[1]) || 0,
          Number(item[2]) || 0,
          Number(item[3]) || 0,
        ]),
      )
    } catch (err) { }
  }
  // const [row, bay, level, bin] = value.split('-')
  return (
    <MainLayout>
      <SizedBox width="15">
        <TextField
          label="Strefa"
          value={<Input value={zone} onChange={(e) => setZone(e.target.value)} />}
        />
      </SizedBox>
      <SizedBox height="2" />
      <Table>
        <thead>
          <Table.Tr>
            <Table.Th>Alejka</Table.Th>
            <Table.Th>Regał / Rząd</Table.Th>
            <Table.Th>Poziom</Table.Th>
            <Table.Th>Kosz</Table.Th>
            <Table.Th>Podgląd</Table.Th>
          </Table.Tr>
        </thead>
        <Table.Body>
          {locations.map((location, idx) => (
            <Table.Tr key={idx}>
              <Table.Td>{location[0].toString().padStart(2, '0')}</Table.Td>
              <Table.Td>{location[1].toString().padStart(2, '0')}</Table.Td>
              <Table.Td>{location[2].toString().padStart(2, '0')}</Table.Td>
              <Table.Td>{location[3].toString().padStart(3, '0')}</Table.Td>
              <Table.Td>{displayLocation(zone, location)}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Body>
      </Table>
      <Spacing right>
        <GenerateLocationLabelsButton
          items={locations.map((location) => displayLocation(zone, location))}
        />
      </Spacing>
      <SizedBox height="2" />
      <TextField
        label="TSV"
        value={
          <Input.TextArea
            value={tsv}
            onChange={(e) => updateTsv(e.target.value)}
          />
        } />
    </MainLayout>
  )
}
