import { request, requestV2 } from './request'
import { paths } from './paths'

export const authenticate = ({ emailAddress, password }) =>
  request.post(paths.authentication, {
    emailAddress,
    password,
  })

export const fetchProduct = sku =>
  request.get(`/products/sku/${encodeURIComponent(sku)}`)

export const fetchBrands = (tree, classification) =>
  request.get(`/car-catalogue/${tree}/brands/${classification}`)

export const fetchModels = (tree, classification, brandId) =>
  request.get(`/car-catalogue/${tree}/models/${classification}/${brandId}`)

export const fetchTypes = (tree, classification, brandId, modelId) =>
  request.get(
    `/car-catalogue/${tree}/types/${classification}/${brandId}/${modelId}`
  )

export const fetchExportInvoice = data =>
  request.post('/pdf/export-invoice', {
    data,
  })

export const fetchStockLevels = sku =>
  request.get(`/stock?sku=${encodeURIComponent(sku)}`)

export const fetchParcelCourierStatus = parcelId =>
  request.get(`/parcels/${parcelId}/courier-status`)

export const fetchComments = (targetId, limit, offset) =>
  requestV2.get(`/comments/${targetId}?limit=${limit}&offset=${offset}`)

export const addComment = (targetId, body) => requestV2.post(`/comments`, { body, targetId })

export const deleteComment = id => requestV2.delete(`/comments/${id}`)

export const generateWarehouseStickersUri = (items) =>
  requestV2.post(`/stickers/warehouse-stickers`, {
    items,
  })
