import React from 'react'
import { compose } from 'recompose'
import { withManufacturers } from 'hocs/withManufacturers'
import { Col, Button, Select, Input, Row } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { TextField, Paper, PrintQrLabel } from 'components'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'

export class ProductDetailsUnwrapped extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(
      state => ({ editMode: !state.editMode }),
      () => {
        if (this.state.editMode) {
          this.props.form.setFieldsValue({
            manufacturerCode: this.props.product.manufacturerCode,
            tecDocId: this.props.product.tecDocId || '',
            ean: this.props.product.ean || '',
            cnCode: this.props.product.cnCode || '',
            note: this.props.product.note || '',
            finnId: this.props.product.finnId || '',
          })
        }
      }
    )
  }

  onSuccess = () => {
    this.toggleMode()
    this.props.refresh()
  }

  render() {
    const { editMode } = this.state
    const { form, product, manufacturers } = this.props
    return (
      <Paper
        title="Informacje"
        extra={
          <Button
            icon={<EditOutlined />}
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        <TextField label="SKU" value={<>{product.sku} <PrintQrLabel value={product.sku} /></>} />
        {editMode && (
          <FormProvider
            url={`/products/sku/${encodeURIComponent(product.sku)}`}
            method="patch"
            form={form}
            onSuccessMessage="Pomyślnie dodano nowy produkt!"
            onSuccess={this.onSuccess}
            render={({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} layout="inline">
                <Row gutter={{ md: 16 }}>
                  <Col md={12}>
                    <Form.Item label="Producent">
                      {form.getFieldDecorator('manufacturerCode')(
                        <Select placeholder="Producent" disabled={!editMode}>
                          {manufacturers.map(manufacturer => (
                            <Select.Option
                              key={manufacturer.code}
                              value={manufacturer.code}
                            >
                              {manufacturer.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Numer TecDoc">
                      {form.getFieldDecorator('tecDocId')(
                        <Input placeholder="Numer TecDoc" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Numer EAN">
                      {form.getFieldDecorator('ean')(
                        <Input placeholder="Numer EAN" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Kod CN">
                      {form.getFieldDecorator('cnCode')(
                        <Input placeholder="Kod CN" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item label="Finn ID">
                      {form.getFieldDecorator('finnId')(
                        <Input placeholder="Kod aukcji Finn" />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Notatka wewnętrzna">
                      {form.getFieldDecorator('note')(
                        <Input.TextArea
                          rows={4}
                          placeholder="Notatka widoczna tylko dla administratorów"
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Zapisz
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          />
        )}
        {!editMode && (
          <Row gutter={{ md: 16 }}>
            <Col md={12}>
              <TextField label="Producent" value={product.manufacturerCode} />
            </Col>
            <Col md={12}>
              <TextField
                label="Numer TecDoc"
                value={
                  <span>
                    {product.tecDocId || '-'}{' '}
                    {product.tecDocId && (
                      <a href="/">
                        <small>Szukaj</small>
                      </a>
                    )}
                  </span>
                }
              />
            </Col>
            <Col md={12}>
              <TextField label="Kod EAN" value={product.ean} />
            </Col>
            <Col md={12}>
              <TextField label="Kod CN" value={product.cnCode} />
            </Col>
            <Col md={12}>
              <TextField label="Finn ID" value={product.finnId} />
            </Col>
            <Col md={24}>
              <TextField label="Notatka wewnętrzna" value={product.note} />
            </Col>
          </Row>
        )}
      </Paper>
    )
  }
}

export const ProductDetails = compose(
  withManufacturers,
  Form.create({ name: 'product-details-edit-form' })
)(ProductDetailsUnwrapped)
