import React from 'react'
import { compose } from 'recompose'
import { pathOr } from 'ramda'
import { Paper, Right } from 'components'
import { Typography, Button, Row, Col, Input } from 'antd'
import { SLabel } from 'components/SLabel/SLabel'
import { Spacing } from 'components/Spacing/Spacing'
import { TextField } from 'components'
import { AddressForm } from './AddressForm/AddressForm'
import * as saleStatus from '../saleStatus'
import { withResource } from 'hocs/withResource'
import { withFormProvider } from 'hocs/withFormProvider'
import { formatCurrency } from 'utils/formatters'
import { getBrutto } from 'utils/prices'

export class PlaceOrderUnwrapped extends React.Component {
  state = {
    emailAddress: '',
    deliveryAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      countryCode: 'NO',
    },
    billingAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      countryCode: 'NO',
    },
  }

  onEmailAddressChange = e => {
    this.setState({ emailAddress: e.target.value })
  }

  onDeliveryAddressChange = field => e => {
    const value = typeof e === 'string' ? e : e.target.value
    this.setState(state => ({
      deliveryAddress: {
        ...state.deliveryAddress,
        [field]: value,
      },
    }))
  }

  onBillingAddressChange = field => e => {
    const value = typeof e === 'string' ? e : e.target.value
    this.setState(state => ({
      billingAddress: {
        ...state.billingAddress,
        [field]: value,
      },
    }))
  }

  copyAddress = () => {
    this.setState(state => ({
      deliveryAddress: state.billingAddress,
    }))
  }

  isDisabled = () => {
    const { sale, orderForm } = this.props
    if (orderForm.updating) return true
    if (sale.status !== saleStatus.ACTIVE) return true
    const requiredFields = ['addressLine1', 'city', 'postalCode', 'countryCode']
    const { deliveryAddress, billingAddress } = this.state
    // eslint-disable-next-line no-unused-vars
    for (const field of requiredFields) {
      if (!deliveryAddress[field] || !billingAddress[field]) {
        return true
      }
    }
    return false
  }

  onSubmit = async () => {
    const { items, summary } = this.props.cart.data
    const { deliveryAddress, billingAddress, emailAddress } = this.state
    await this.props.orderForm.handleSubmit({
      items,
      deliveryAddress,
      billingAddress,
      emailAddress: emailAddress || undefined,
      totalBrutto: summary.brutto,
    })
    this.props.refresh()
  }

  getDeliveryCost = () =>
    getBrutto(
      pathOr(0, ['data', 'deliveryCost', 'netto'], this.props.cart),
      pathOr(0, ['data', 'deliveryCost', 'vatRate'], this.props.cart)
    )

  render() {
    const { sale, cart } = this.props
    const { deliveryAddress, billingAddress, emailAddress } = this.state
    const totalBrutto = pathOr(0, ['data', 'summary', 'brutto'], cart)
    const deliveryCost = this.getDeliveryCost()
    return (
      <>
        <Paper title="Zamówienie" id="place-order-section">
          <Row gutter={12}>
            <Col md={24} sm={24}>
              <Spacing ml="0.3rem" mb="0.5rem">
                <SLabel>Adres e-mail</SLabel>
                <Input
                  id="place-order-form_emailAddress"
                  disabled={sale.status !== saleStatus.ACTIVE}
                  placeholder="Adres e-mail"
                  value={emailAddress}
                  onChange={this.onEmailAddressChange}
                />
              </Spacing>
            </Col>
            <Col md={12} sm={24}>
              <Spacing mb="1rem">
                <Typography>Adres rachunku</Typography>
              </Spacing>
              <AddressForm
                id="billing-address"
                address={billingAddress}
                onChange={this.onBillingAddressChange}
                disabled={sale.status !== saleStatus.ACTIVE}
              />
            </Col>
            <Col md={12} sm={24}>
              <Spacing mb="1rem">
                <Typography>
                  Adres wysyłki
                  <Button size="small" type="link" onClick={this.copyAddress}>
                    <small>Kopiuj</small>
                  </Button>
                </Typography>
              </Spacing>
              <AddressForm
                id="delivery-address"
                address={deliveryAddress}
                onChange={this.onDeliveryAddressChange}
                disabled={sale.status !== saleStatus.ACTIVE}
              />
            </Col>
          </Row>
          {sale.status === saleStatus.ACTIVE && (
            <Row gutter={12}>
              <Right>
                <Button
                  disabled={this.isDisabled()}
                  onClick={this.onSubmit}
                  type="danger"
                  id="place-order-form_submit"
                >
                  Złóż zamówienie {formatCurrency(totalBrutto, sale.currency)}
                </Button>
              </Right>
            </Row>
          )}
        </Paper>
        {cart.data && (
          <Paper title="Koszt wysyłki">
            <Row gutter={12}>
              <Col sm={24} md={12}>
                <TextField
                  label="Strategia kosztów wysyłki"
                  value={pathOr(
                    '-',
                    ['data', 'deliveryCost', 'strategy'],
                    cart
                  )}
                />
              </Col>
              <Col sm={24} md={12}>
                <TextField
                  label="Koszt wysyłki"
                  value={formatCurrency(
                    deliveryCost,
                    pathOr(
                      sale.currency,
                      ['data', 'deliveryCost', 'currency'],
                      cart
                    )
                  )}
                />
              </Col>
            </Row>
          </Paper>
        )}
      </>
    )
  }
}

export const PlaceOrder = compose(
  withResource({
    name: 'cart',
    url: props => `/carts/${props.sale.id}`,
  }),
  withFormProvider({
    name: 'orderForm',
    url: props => `/orders/${props.sale.id}`,
  })
)(PlaceOrderUnwrapped)
