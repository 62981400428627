import React from 'react'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'

export const withResource = options => Component => props => {
  const { name, ...providerProps } = options
  if (!name) {
    throw new Error(
      'Higher order component withResource options.name is required.'
    )
  }
  return (
    <ResourceProvider
      {...providerProps}
      url={
        typeof providerProps.url === 'function'
          ? providerProps.url(props)
          : providerProps.url
      }
      render={providerData => (
        <Component {...props} {...{ [name]: providerData }} />
      )}
    />
  )
}
