import React from 'react'
import { notification } from 'antd'
import { RED } from 'layouts/colors'
import { MehOutlined } from '@ant-design/icons'

export const showError = (message = 'Coś poszło nie tak.', code = '') => {
  notification.open({
    message: `Błąd! ${code}`,
    description: message,
    icon: <MehOutlined style={{ color: RED }} />,
  })
}
