import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { debounce } from 'lodash'
import { path, pick } from 'ramda'
import { request, requestV2 } from 'api/request'
import { withIsMounted } from 'utils/withIsMounted'

class ResourceProviderUnwrapped extends React.Component {
  subscribers = []

  state = {
    data: null,
    meta: null,
    loading: true,
    sortingParams: {
      field: null,
      order: null,
    },
    paginationParams: this.props.paginationParams,
    filterParams: this.props.filterParams,
    error: null,
    refreshKey: 0,
  }

  componentDidMount = async () => {
    const { page, ...filterParams } = this.decodeQueryParams()
    const defaultFilterParams = this.getDefaultFilterParams(filterParams)
    this.onFiltersChange(defaultFilterParams)
    const current = this.parsePage(page)
    this.onPaginationChange({ current })
  }

  decodeQueryParams = () => {
    const params = Object.keys(this.props.filterParams)
    if (this.props.encodePageNumber) {
      params.push('page')
    }
    return pick(params, queryString.parse(this.props.location.search))
  }

  getDefaultFilterParams = queryParams => {
    return {
      ...pick(
        Object.keys(this.props.filterParams),
        queryString.parse(this.props.location.search)
      ),
      ...this.props.filterParams,
      ...queryParams,
    }
  }

  parsePage = pageString => {
    if (!isNaN(pageString) && pageString > 0) {
      return Number(pageString)
    }
    return 1
  }

  refresh = async () => {
    await this.fetchData()
    this.subscribers.forEach(cb => cb())
  }

  buildSortingParams = () => {
    const { field, order } = this.state.sortingParams
    if (!field || !order) return {}
    return {
      sortBy: field,
      sortOrder: order === 'ascend' ? 'ASC' : 'DESC',
    }
  }

  buildPaginationParams = () => {
    const { current, pageSize } = this.state.paginationParams
    return {
      page: current,
      perPage: pageSize,
    }
  }

  buildParams = () => {
    let params = {}
    if (this.props.sorting) {
      params = {
        ...params,
        ...this.buildSortingParams(),
      }
    }
    if (this.props.pagination) {
      params = {
        ...params,
        ...this.buildPaginationParams(),
      }
    }
    if (this.state.filterParams) {
      params = {
        ...params,
        ...this.state.filterParams,
      }
    }
    return params
  }

  fetchData = async () => {
    this.setStateSafe({ loading: true })
    const params = this.buildParams()
    try {
      const { data } = await this.makeRequest(params)
      this.handleResponse(data)
    } catch (err) {
      this.setStateSafe(
        {
          error: err.response,
          loading: false,
        },
        () => {
          this.props.onError(err)
        }
      )
    }
  }

  setStateSafe = (obj, callback) => {
    if (this.props.isMounted()) {
      this.setState(obj, callback)
    }
  }

  debouncedFetchData = debounce(this.fetchData, 200)

  fetchDataDebounced = () => {
    this.setStateSafe({ loading: true, error: null })
    this.debouncedFetchData()
  }

  makeRequest = (params = {}) =>
    this.props.v2
      ? requestV2.get(this.props.url, {
        params,
      })
      : request.get(this.props.url, {
        params,
      })

  handleResponse = responseData => {
    const data = path(this.props.dataPath, responseData)
    this.setStateSafe(
      state => ({
        loading: false,
        data: this.props.responseNormalizer(data),
        meta: path(this.props.metaPath, responseData),
        paginationParams: {
          ...state.paginationParams,
          total: path(this.props.totalCountPath, responseData),
        },
        refreshKey: state.refreshKey + 1,
      }),
      () => {
        this.props.onSuccess(this.state.data)
      }
    )
  }

  onSortChange = sortingParams =>
    this.setState(
      {
        sortingParams: {
          field: sortingParams.field,
          order: sortingParams.order,
        },
      },
      this.fetchDataDebounced
    )

  onPaginationChange = paginationParams => {
    this.setState(
      state => ({
        paginationParams: {
          ...state.paginationParams,
          ...paginationParams,
        },
      }),
      () => {
        this.encodeQueryParams()
        this.fetchDataDebounced()
      }
    )
  }

  onFiltersChange = filterParams => {
    this.setState(
      state => ({
        filterParams,
        paginationParams: {
          ...state.paginationParams,
          current: 1,
        },
      }),
      () => {
        this.encodeQueryParams()
        this.fetchDataDebounced()
      }
    )
  }

  encodeQueryParams = () => {
    let queryStringChunks = []
    if (this.props.encodeFilters) {
      const filters = pick(
        Object.keys(this.props.filterParams),
        this.state.filterParams
      )
      queryStringChunks = [
        ...queryStringChunks,
        ...Object.entries(filters).map(entry => `${entry[0]}=${entry[1]}`),
      ]
    }
    if (this.props.encodePageNumber) {
      const { current } = this.state.paginationParams
      queryStringChunks.push(`page=${current}`)
    }
    if (queryStringChunks.length > 0) {
      this.props.history.push(
        `${this.props.match.url}?${queryStringChunks.join('&')}`
      )
    }
  }

  onChange = (pagination, _filters, sorter) => {
    this.onPaginationChange(pagination)
    this.onSortChange(sorter)
  }

  onRefresh = callback => {
    this.subscribers.push(callback)
  }

  render() {
    const {
      loading,
      error,
      data,
      meta,
      paginationParams,
      filterParams,
      refreshKey,
    } = this.state
    return this.props.render({
      loading,
      error,
      data,
      meta,
      paginationParams,
      onChange: this.onChange,
      onFiltersChange: this.onFiltersChange,
      refresh: this.refresh,
      filterParams,
      refreshKey,
      onRefresh: this.onRefresh,
    })
  }
}

ResourceProviderUnwrapped.defaultProps = {
  dataPath: ['data'],
  metaPath: ['meta'],
  totalCountPath: ['meta', 'total'],
  responseNormalizer: data => data,
  onSuccess: () => { },
  onError: () => { },
  filters: false,
  pagination: false,
  sorting: false,
  encodePageNumber: false,
  encodeFilters: false,
  filterParams: {},
  paginationParams: {
    current: 1,
    pageSize: 10,
    total: null,
  },
  v2: false,
}

ResourceProviderUnwrapped.propTypes = {
  url: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  isMounted: PropTypes.func.isRequired,
  dataPath: PropTypes.arrayOf(PropTypes.string),
  metaPath: PropTypes.arrayOf(PropTypes.string),
  totalCountPath: PropTypes.arrayOf(PropTypes.string),
  responseNormalizer: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  deserialize: PropTypes.bool,
  filterParams: PropTypes.object,
  filters: PropTypes.bool,
  pagination: PropTypes.bool,
  sorting: PropTypes.bool,
  v2: PropTypes.bool,
}

export const ResourceProvider = compose(
  withRouter,
  withIsMounted
)(ResourceProviderUnwrapped)
