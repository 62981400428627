import styled from 'styled-components'
import React from 'react'
import { Spin } from 'antd'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoadingScreen = () => (
  <Container>
    <Spin size="large" />
  </Container>
)
