import React from 'react'
import { Typography } from 'antd'
import { LinkButton } from 'components/LinkButton/LinkButton'

export class Prefixer extends React.Component {
  state = {
    loading: false,
    error: '',
  }

  handleClick = async () => {
    if (!this.props.manufacturer) {
      return this.setState({
        error: 'Aby wygenerować SKU wybierz producenta.',
      })
    }
    this.setState({
      error: '',
    })
    this.props.handleChange(
      `${this.props.manufacturer.skuPrefix}${this.props.sku ||
        ''}`.toUpperCase().trim()
    )
  }

  render() {
    const { error } = this.state
    return (
      <>
        <LinkButton onClick={this.handleClick} type="button">
          Dodaj prefix
        </LinkButton>
        {error && (
          <Typography.Text type="danger">
            {'   '}
            <small>{error}</small>
          </Typography.Text>
        )}
      </>
    )
  }
}
