import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Popover } from 'antd'
import { LinkButton, TextField } from 'components'
import { AsyncProductDescription } from 'components/AsyncProductDescription/AsyncProductDescription'
import { Copy } from 'components/Copy/Copy'

const Container = styled.div`
  max-width: 200px;
`

const getContent = sku => (
  <AsyncProductDescription
    sku={sku}
    render={product => (
      <Container>
        {product.manufacturerCode && (
          <TextField label="Producent" value={product.manufacturerCode} />
        )}
        {product.namePL && (
          <TextField label="Nazwa PL" value={product.namePL} />
        )}
        {product.nameNO && (
          <TextField label="Nazwa NO" value={product.nameNO} />
        )}
        {product.note && <TextField label="Notatka" value={product.note} />}
      </Container>
    )}
  />
)

export const ProductNumber = ({ sku }) => (
  <>
    <Popover
      content={getContent(sku)}
      title={<Link to={`/products/${encodeURIComponent(sku)}`}>{sku}</Link>}
      trigger="click"
    >
      <LinkButton>{sku}</LinkButton>
    </Popover>
    <Copy value={sku} />
  </>
)

ProductNumber.propTypes = {
  sku: PropTypes.string.isRequired,
}
