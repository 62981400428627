import React from 'react'
import { pathOr } from 'ramda'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { Paper, Table } from 'components'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { paths } from 'api/paths'

const normalizeStockLevels = stockLevels =>
  stockLevels.map(stockLevel => ({
    sku: pathOr('', ['sku'], stockLevel),
    quantity: pathOr('', ['quantity'], stockLevel),
    zone: pathOr('', ['zone', 'name'], stockLevel),
    zoneId: pathOr('', ['zone', 'id'], stockLevel),
    warehouse: pathOr('', ['zone', 'warehouse', 'name'], stockLevel),
  }))

export const StockLevelList = withRouter(({ sku, history }) => (
  <ResourceProvider
    url={`/stock?sku=${encodeURIComponent(sku)}`}
    responseNormalizer={normalizeStockLevels}
    render={({ data, loading }) =>
      !loading && (
        <Paper
          title="Stany magazynowe"
          extra={
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                history.push(paths.stockLevels)
              }}
            />
          }
        >
          <Table>
            <Table.Body>
              <Table.Tr>
                <Table.Th style={{ width: '33%' }}>Magazyn</Table.Th>
                <Table.Th style={{ width: '33%' }}>Strefa</Table.Th>
                <Table.Th style={{ width: '33%' }}>Ilość</Table.Th>
              </Table.Tr>
              {data.map((stockLevel, index) => (
                <Table.Tr key={index}>
                  <Table.Td width="33%">{stockLevel.warehouse}</Table.Td>
                  <Table.Td width="33%">{stockLevel.zone}</Table.Td>
                  <Table.Td width="33%">{stockLevel.quantity}</Table.Td>
                </Table.Tr>
              ))}
              {data.length > 1 && (
                <Table.Tr>
                  <Table.Td width="66%" colSpan={2}>
                    <strong>Suma</strong>
                  </Table.Td>
                  <Table.Td width="33%">
                    <strong>
                      {data.reduce((acc, curr) => acc + curr.quantity, 0)}
                    </strong>
                  </Table.Td>
                </Table.Tr>
              )}
            </Table.Body>
          </Table>
        </Paper>
      )
    }
  />
))
