import styled from 'styled-components'

export const STile = styled.div`
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 0 1px 1px 0;
  background-size: cover;
  background-image: url(${props => props.url});
`
