import {
  DrawerTypes,
  OPEN_STOCK_LEVELS_DRAWER,
  CLOSE_DRAWER,
} from 'redux/actions/constants'

const defaultState = {
  drawerType: null,
  params: {},
}

export const drawerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_STOCK_LEVELS_DRAWER:
      return {
        drawerType: DrawerTypes.StockLevels,
        params: action.params,
      }
    case CLOSE_DRAWER:
      return {
        ...state,
        drawerType: null,
      }
    default:
      return state
  }
}
