import React from 'react'
import { MainLayout } from 'layouts/MainLayout/MainLayout'
import { Row, Col } from 'antd'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { withOnErrorRedirect } from 'hocs/withOnErrorRedirect'
import { TransportHeader } from './TransportHeader/TransportHeader'
import { Attachments, Comments } from 'components'

class TransportShowUnwrapped extends React.Component {
  render() {
    const { id } = this.props.match.params
    return (
      <ResourceProvider
        v2
        url={`/transports/${id}`}
        onError={this.props.onErrorRedirect}
        render={({ data: transport, loading, refresh, refreshKey }) => {
          return (
            <MainLayout
              header={`Transport ${transport ? transport.number.toString().padStart(4, '0') : ''}`}
              isLoading={!transport && loading}
              transparent
            >
              {transport && (
                <Row>
                  <Col xxl={12} xl={20} lg={24} xs={24}>
                    <Row type="flex" justify="space-between">
                      <Col lg={24} xs={24}>
                        <TransportHeader
                          key={refreshKey}
                          transport={transport}
                          refresh={refresh}
                        />
                      </Col>
                      <Col lg={24} xs={24}>
                        <Comments label="" targetId={transport.id} />
                      </Col>
                      <Col lg={24} xs={24}>
                        <Attachments targetId={transport.id} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </MainLayout>
          )
        }}
      />
    )
  }
}

export const TransportShow = withOnErrorRedirect(TransportShowUnwrapped)
