import React from 'react'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { ReloadOutlined } from '@ant-design/icons'

export const RefreshPaymentStatusButton = ({
  saleId,
  paymentType,
  paymentId,
  refresh,
}) => (
  <RequestButton
    onSuccess={refresh}
    url={`/payments/${saleId}/${paymentId}/${paymentType}/sync`}
    method="post"
    type="link"
    onSuccessMessage="Pomyślnie zaktualizowano status tej płatności"
    content={<ReloadOutlined />}
  />
)
