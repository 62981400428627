import React from 'react'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { Table } from 'components/Table/Table'
import { Price } from 'components/Price/Price'
import { Align } from 'components/Align/Align'
import { AddSalesPrice } from './AddSalesPrice/AddSalesPrice'
import { formatDateTime } from 'utils/formatters'
import { Spacing } from 'components/Spacing/Spacing'

export const PricesList = ({ sku, prices, refresh, salesChannelCode }) => (
  <>
    <Table>
      <Table.Body>
        <Table.Tr>
          <Table.Th style={{ width: '33%' }}>Cena netto</Table.Th>
          <Table.Th style={{ width: '33%' }}>Data</Table.Th>
          <Table.Th style={{ width: '33%' }}>{''}</Table.Th>
        </Table.Tr>
        {prices.length === 0 && (
          <Table.Tr>
            <Table.Td colSpan={3} width="100%">
              <Align value="center">
                <small>
                  <i>Brak cen</i>
                </small>
              </Align>
            </Table.Td>
          </Table.Tr>
        )}
        {prices.map((price, index) => (
          <Table.Tr key={index}>
            <Table.Td width="25%">
              <Price value={price.netto} currency={price.currency} />
            </Table.Td>
            <Table.Td>{formatDateTime(price.createdAt)}</Table.Td>
            <Table.Td>
              <Align value="right">
                <RequestButton
                  content="Usuń"
                  method="delete"
                  url={`/products/sales-prices/${price.id}`}
                  type="link"
                  onSuccessMessage="Pomyślnie usunięto!"
                  onSuccess={refresh}
                />
              </Align>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Body>
    </Table>
    <Spacing mt="1rem">
      <AddSalesPrice
        sku={sku}
        salesChannelCode={salesChannelCode}
        refresh={refresh}
      />
    </Spacing>
  </>
)
