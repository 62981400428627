import styled from 'styled-components'
import { blue } from '@ant-design/colors'
import { GRAY } from 'layouts/colors'

export const Header = styled.div`
  margin: 0;
  ${({ level }) => `margin-left: ${level * 2}rem;`}
  display: flex;
  justify-content: space-between;
  border-left: 2px solid
    ${({ isParentSelected }) => (isParentSelected ? GRAY : blue.primary)};
  padding: 0.8rem 1.2rem;
  border-bottom: 1px dashed #f7f7f7;
`
