import React from 'react'
import { SaleItemsTable } from '../../SaleItemsTable/SaleItemsTable'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'

export const EditCartForm = ({ sale, handleSubmit, posting }) => (
  <ResourceProvider
    url={`/carts/${sale.id}`}
    render={({ loading, data: sale }) => {
      if (loading) return 'Loading...'
      if (!sale) return 'Błąd'
      return (
        <SaleItemsTable
          sale={sale}
          handleSubmit={handleSubmit}
          posting={posting}
        />
      )
    }}
  />
)
