import React from 'react'
import { Input, Col, Row } from 'antd'
import { request } from 'api/request'
import { TextField, Table } from 'components'
import { Spacing } from 'components/Spacing/Spacing'
import { Link } from 'react-router-dom'

export class FulfillmentSelector extends React.Component {
  state = {
    saleId: '',
  }

  handleChange = e => {
    this.setState({ saleId: e.target.value })
  }

  handleSearch = async saleId => {
    try {
      const response = await request.get(`/fulfillments/${saleId}`)
      this.props.addFulfillment(response.data.data)
      this.setState({ saleId: '' })
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const { fulfillments } = this.props
    return (
      <div>
        <Spacing mb="1rem">
          <Row>
            <Col sm={12}>
              <Input.Search
                id="fulfillment-selector_sale-id"
                placeholder="Wpisz numer sprzedaży"
                onSearch={this.handleSearch}
                enterButton
                value={this.state.saleId}
                onChange={this.handleChange}
              />
            </Col>
          </Row>
        </Spacing>
        {fulfillments.length > 0 && (
          <Table>
            <Table.Body>
              <Table.Tr>
                <Table.Th>Numer</Table.Th>
                <Table.Th>Status kompletacji</Table.Th>
                <Table.Th>Paczki</Table.Th>
                <Table.Th>Zamówionych sku</Table.Th>
              </Table.Tr>
              {fulfillments.map(fulfillment => (
                <Table.Tr key={fulfillment.id}>
                  <Table.Td>
                    <Link to={`/sales/${fulfillment.id}`}>
                      {fulfillment.number}
                    </Link>
                  </Table.Td>
                  <Table.Td>
                    <TextField mb="0" value={fulfillment.status} />
                  </Table.Td>
                  <Table.Td>
                    <TextField
                      mb="0"
                      value={fulfillment.parcels.map(parcel => (
                        <Spacing
                          key={parcel.id}
                          display="inline-block"
                          mr="3px"
                        >
                          <Link to={`/parcels/${parcel.id}`}>
                            {parcel.id.slice(-8)}
                          </Link>
                        </Spacing>
                      ))}
                    />
                  </Table.Td>
                  <Table.Td>
                    <TextField mb="0" value={fulfillment.items.length || '0'} />
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    )
  }
}
