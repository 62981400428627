import React from 'react'
import { Paper } from 'components'
import { RequestButton } from 'components/RequestButton/RequestButton'

export const MarkAsPacked = ({ parcel, refresh }) => (
  <Paper title="Zakończ pakowanie">
    <RequestButton
      id="parcels_mark-as-packed_submit"
      onSuccess={refresh}
      url={
        parcel.status === 'PACKED'
          ? `/parcels/${parcel.id}/unmark-as-packed`
          : `/parcels/${parcel.id}/mark-as-packed`
      }
      method="post"
      type={parcel.status === 'PACKED' ? '' : 'danger'}
      content={
        parcel.status === 'PACKED'
          ? 'Cofnij zamknięcie paczki'
          : 'Zakończ pakowanie'
      }
      title={
        parcel.status === 'PACKED'
          ? 'Czy na pewno chcesz otworzyć tą paczkę aby zmienić jej zawartość?'
          : 'Czy na pewno paczka zawiera już wszystkie produkty?'
      }
    />
  </Paper>
)
