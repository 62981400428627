import React from 'react'
import { AsyncSelect, SLabel } from 'components'

export const AddItemForm = ({ onSubmit, disabled }) => (
  <>
    <SLabel>Wybierz SKU</SLabel>
    <AsyncSelect
      value=""
      disabled={disabled}
      id="add-item-form_sku-selector"
      url="/products"
      queryParam="sku"
      onChange={onSubmit}
      placeholder="SKU"
      responseNormalizer={item => ({
        text: item.sku,
        value: item.sku,
      })}
    />
  </>
)
