import React from 'react'
import moment from 'moment'
import { compose } from 'recompose'
import { Col, Button, Row, DatePicker } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { TextField, Paper } from 'components'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { formatDateTime } from 'utils/formatters'

export class PurchaseOrderTimelineUnwrapped extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(
      state => ({ editMode: !state.editMode }),
      () => {
        if (this.state.editMode) {
          this.props.form.setFieldsValue({
            orderedAt: this.props.data.orderedAt ? moment(this.props.data.orderedAt) : null,
            deliveredAt: this.props.data.deliveredAt ? moment(this.props.data.deliveredAt) : null,
            unpackedAt: this.props.data.unpackedAt ? moment(this.props.data.unpackedAt) : null,
          })
        }
      }
    )
  }

  onSuccess = () => {
    this.toggleMode()
    this.props.refresh()
  }

  render() {
    const { editMode } = this.state
    const { form, data } = this.props
    return (
      <Paper
        title="Daty"
        extra={
          <Button
            icon={<EditOutlined />}
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        {editMode && (
          <FormProvider
            v2
            url={`/purchase-orders/${data.id}`}
            method="put"
            form={form}
            onSuccessMessage="Pomyślnie edytowano zamówienie!"
            onSuccess={this.onSuccess}
            render={({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row gutter={{ md: 16 }}>
                  <Col md={24}>
                    <Form.Item label="Wysłano zamówienie">
                      {form.getFieldDecorator('orderedAt')(<DatePicker showTime placeholder="Wysłano zamówienie" />)}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Otrzymano przesyłkę">
                      {form.getFieldDecorator('deliveredAt')(<DatePicker showTime placeholder="Otrzymano przesyłkę" />)}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item label="Rozpakowano towar">
                      {form.getFieldDecorator('unpackedAt')(<DatePicker showTime placeholder="Rozpakowano" />)}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Zapisz
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          />
        )}
        {!editMode && (
          <>
            <Row gutter={{ md: 16 }}>
              <Col md={24}>
                <TextField label="Wysłano zamówienie" value={formatDateTime(data.orderedAt)} />
              </Col>
              <Col md={24}>
                <TextField label="Otrzymano przesyłkę" value={formatDateTime(data.deliveredAt)} />
              </Col>
              <Col md={24}>
                <TextField label="Rozpakowano towar" value={formatDateTime(data.unpackedAt)} />
              </Col>
            </Row>
          </>
        )}
      </Paper>
    )
  }
}

export const PurchaseOrderTimeline = compose(
  Form.create({ name: 'purchase-order-timeline-edit-form' })
)(PurchaseOrderTimelineUnwrapped)
