import React, { useState } from 'react'
import { Button, Modal, InputNumber, Input } from 'antd'
import { TextField, RequestButton } from 'components'

export const CreateReplenishmentModal = ({ sku, refresh }) => {
  const [isVisible, handleChange] = useState(false)
  const toggleModal = () => handleChange(!isVisible)

  const [form, onChange] = useState({
    quantity: 1,
    comment: '',
  })

  const handleFormChange = (field, value) => {
    onChange({
      ...form,
      [field]: value,
    })
  }

  return (
    <>
      <Button size="small" onClick={toggleModal}>
        Zamów
      </Button>
      <Modal
        title={sku}
        visible={isVisible}
        onCancel={toggleModal}
        footer={null}
      >
        <p>Potwierdź złożenie zlecenia zaopatrzenia produktu {sku}</p>
        <TextField
          label="Zamówiona ilość"
          value={
            <InputNumber
              value={form.quantity}
              min={1}
              step={1}
              onChange={value => handleFormChange('quantity', value)}
            />
          }
        />
        <TextField
          label="Komentarz"
          value={
            <Input.TextArea
              value={form.comment}
              rows={2}
              onChange={e => handleFormChange('comment', e.target.value)}
            />
          }
        />
        <p>
          <RequestButton
            onSuccess={() => {
              toggleModal()
              onChange({
                quantity: 1,
                comment: '',
              })
              refresh()
            }}
            url="/replenishments"
            method="post"
            type="danger"
            content="Zapisz"
            unprotected
            payload={{
              ...form,
              sku,
            }}
          />
        </p>
      </Modal>
    </>
  )
}
