import React from 'react'
import { compose } from 'recompose'
import { withManufacturers } from 'hocs/withManufacturers'
import { Col, Button, InputNumber, Row, Typography } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { Form } from '@ant-design/compatible'
import { TextField, Paper, Spacing } from 'components'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'

export class VirtualAvailabilityUnwrapped extends React.Component {
  state = {
    editMode: false,
  }

  toggleMode = () => {
    this.setState(
      state => ({ editMode: !state.editMode }),
      () => {
        if (this.state.editMode) {
          this.props.form.setFieldsValue({
            virtualAvailability: this.props.product.virtualAvailability,
          })
        }
      }
    )
  }

  onSuccess = () => {
    this.toggleMode()
    this.props.refresh()
  }

  render() {
    const { editMode } = this.state
    const { form, product } = this.props
    return (
      <Paper
        title="Wirtualna dostępność"
        extra={
          <Button
            icon={<EditOutlined />}
            type={editMode ? 'primary' : 'dashed'}
            onClick={this.toggleMode}
          />
        }
      >
        <Spacing mb="2rem">
          <Typography.Text>
            Zmień wirtualną dostępność jeśli chcesz aby produkt był zawsze
            widoczny jako dostępny. Dostępność jest kalkulowana jako suma stanów
            magazynowych minus ilość rezerwacji plus wirtualna dostępność.
            <br />
            Np. jeśli wpiszesz 10 i nie będzie produktu na magazynie, będzie
            widniał na sklepie jako dostępny do momentu aż zostanie
            zarezerwowanych 10 sztuk.
          </Typography.Text>
        </Spacing>
        {editMode && (
          <FormProvider
            url={`/products/sku/${encodeURIComponent(product.sku)}`}
            method="patch"
            form={form}
            onSuccessMessage="Pomyślnie zmieniono wirtualną dostępność"
            onSuccess={this.onSuccess}
            render={({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row gutter={{ md: 16 }}>
                  <Col md={12}>
                    <Form.Item label="Stała dostępność">
                      {form.getFieldDecorator('virtualAvailability')(
                        <InputNumber
                          placeholder="Stała dostępność"
                          defaultValue={0}
                          min={-9999}
                          max={9999}
                          parser={val => (!val ? 0 : val)}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          />
        )}
        {!editMode && (
          <Row gutter={{ md: 16 }}>
            <Col md={12}>
              <TextField
                label="Stała dostępność"
                value={product.virtualAvailability || '0'}
              />
            </Col>
          </Row>
        )}
      </Paper>
    )
  }
}

export const VirtualAvailability = compose(
  withManufacturers,
  Form.create({ name: 'product-virtual-availability-edit-form' })
)(VirtualAvailabilityUnwrapped)
