import React from 'react'
import { pathOr } from 'ramda'
import { withResource } from 'hocs/withResource'
import {
  TextField,
  Paper,
  Table,
  ProductNumber,
  SLabel,
  PaymentStatus,
} from 'components'
import { formatDateTime, formatCurrency } from 'utils/formatters'
import { Row, Col } from 'antd'
import { displayPrice, getBrutto } from 'utils/prices'
import { Spacing } from 'components/Spacing/Spacing'
import { VippsPaymentDetailsModal } from './VippsPaymentDetailsModal/VippsPaymentDetailsModal'
import { StripePaymentDetailsModal } from './StripePaymentDetailsModal/StripePaymentDetailsModal'
import { ManualPaymentDetailsModal } from './ManualPaymentDetailsModal/ManualPaymentDetailsModal'
import { Address } from './Address/Address'

const OrderUnwrapped = ({ order, refresh }) =>
  order.data && (
    <>
      <Paper title="Płatności" id="sale_payments">
        <TextField
          label="Status płatności"
          id="sale_payments-status"
          value={<PaymentStatus status={order.data.paymentStatus} />}
        />
        <TextField
          label="Zapłacono"
          value={formatCurrency(order.data.paymentTotal, order.data.currency)}
        />
        <TextField
          label="Pozostało do zapłaty"
          id="sale_payments_pending-amount"
          data-payment-pending-ammount={order.data.paymentPendingAmount}
          value={formatCurrency(
            order.data.paymentPendingAmount,
            order.data.currency
          )}
        />
        <TextField
          label="Transakcje płatnicze"
          value={
            <>
              {order.data.payments.length === 0 && 'Brak'}
              {order.data.payments.length > 0 && (
                <Spacing mt="1rem">
                  <Table>
                    <Table.Body>
                      <Table.Tr>
                        <Table.Th>ID</Table.Th>
                        <Table.Th>Wartość</Table.Th>
                        <Table.Th>Pobrano</Table.Th>
                        <Table.Th>Zwrócono</Table.Th>
                        <Table.Th>Typ</Table.Th>
                        <Table.Th>Status</Table.Th>
                        <Table.Th>Data</Table.Th>
                      </Table.Tr>
                      {order.data.payments.map(payment => (
                        <Table.Tr key={payment.timestamp}>
                          <Table.Td>
                            {payment.type === 'vipps' && (
                              <VippsPaymentDetailsModal
                                {...payment}
                                saleId={order.data.id}
                                refresh={refresh}
                                currency={order.data.currency}
                              />
                            )}
                            {payment.type === 'stripe' && (
                              <StripePaymentDetailsModal
                                {...payment}
                                saleId={order.data.id}
                                refresh={refresh}
                                currency={order.data.currency}
                              />
                            )}
                            {payment.type === 'klarna' &&
                              payment.paymentId.slice(0, 6)}
                            {['rounding', 'cash', 'transfer'].includes(
                              payment.type
                            ) && (
                              <ManualPaymentDetailsModal
                                {...payment}
                                saleId={order.data.id}
                                refresh={refresh}
                                currency={order.data.currency}
                              />
                            )}
                          </Table.Td>
                          <Table.Td>
                            {formatCurrency(payment.value, order.data.currency)}
                          </Table.Td>
                          <Table.Td>
                            {displayPrice(payment.capturedAmount)}
                          </Table.Td>
                          <Table.Td>
                            {displayPrice(payment.refundedAmount)}
                          </Table.Td>
                          <Table.Td>{payment.type}</Table.Td>
                          <Table.Td>{payment.status || '-'}</Table.Td>
                          <Table.Td>
                            {formatDateTime(payment.timestamp)}
                          </Table.Td>
                        </Table.Tr>
                      ))}
                    </Table.Body>
                  </Table>
                </Spacing>
              )}
            </>
          }
        />
      </Paper>
      <Paper title="Zamówienie">
        <TextField
          label="Zamówiono"
          value={formatDateTime(order.data.createdAt)}
        />
        <TextField label="Status" value={order.data.status} />
        <TextField
          label="Razem brutto"
          value={formatCurrency(order.data.summary.netto, order.data.currency)}
        />
        <TextField
          label="Razem VAT"
          value={formatCurrency(order.data.summary.vat, order.data.currency)}
        />
        <TextField
          label="Razem netto"
          value={formatCurrency(order.data.summary.brutto, order.data.currency)}
        />
      </Paper>
      <Paper title="Dane adresowe">
        <Row gutter={12}>
          <Col sm={24} md={12}>
            <Spacing mb="1rem">
              <SLabel>Adres wysyłki</SLabel>
            </Spacing>
            <Address address={order.data.deliveryAddress} />
          </Col>
          <Col sm={24} md={12}>
            <Spacing mb="1rem">
              <SLabel>Adres faktury</SLabel>
            </Spacing>
            <Address address={order.data.billingAddress} />
          </Col>
        </Row>
      </Paper>
      <Paper title="Koszt wysyłki">
        <Row gutter={12}>
          <Col sm={24} md={12}>
            <TextField
              label="Strategia kosztów wysyłki"
              value={pathOr('-', ['data', 'deliveryCost', 'strategy'], order)}
            />
          </Col>
          <Col sm={24} md={12}>
            <TextField
              label="Koszt wysyłki"
              value={formatCurrency(
                getBrutto(
                  pathOr(0, ['data', 'deliveryCost', 'netto'], order),
                  pathOr(0, ['data', 'deliveryCost', 'vatRate'], order)
                ),
                pathOr(
                  order.currency,
                  ['data', 'deliveryCost', 'currency'],
                  order
                )
              )}
            />
          </Col>
        </Row>
      </Paper>
      <Paper title="Lista zamówienia">
        <Table>
          <Table.Body>
            <Table.Tr>
              <Table.Th style={{ width: '30%' }}>SKU</Table.Th>
              <Table.Th style={{ width: '20%' }}>Cena netto</Table.Th>
              <Table.Th style={{ width: '10%' }}>Rabat</Table.Th>
              <Table.Th style={{ width: '10%' }}>Ilość</Table.Th>
              <Table.Th style={{ width: '10%' }}>Dostępność</Table.Th>
              <Table.Th style={{ width: '20%' }}>Razem Brutto</Table.Th>
            </Table.Tr>
            {order.data.items.map((item, index) => (
              <Table.Tr key={index}>
                <Table.Td width="25%">
                  <ProductNumber sku={item.sku} />
                </Table.Td>
                <Table.Td>
                  {formatCurrency(item.netto, order.data.currency)}
                </Table.Td>
                <Table.Td>{item.discountRate || '0'}%</Table.Td>
                <Table.Td>{item.quantity}</Table.Td>
                <Table.Td>{item.availability}</Table.Td>
                <Table.Td>
                  {formatCurrency(item.totalBrutto, order.data.currency)}
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Body>
        </Table>
      </Paper>
    </>
  )

export const Order = withResource({
  name: 'order',
  url: props => `/orders/${props.sale.id}`,
})(OrderUnwrapped)
