import React from 'react'
import { Button, Modal, Col, Row, Typography } from 'antd'
import { SLabel, AsyncSelect, Spacing, RequestButton } from 'components'

export class UnpackItemModal extends React.Component {
  state = {
    showModal: false,
    warehouseId: 2,
    zoneId: 2,
  }

  onChange = key => value => {
    this.setState({ [key]: value })
    if (key === 'warehouseId') {
      this.setState({ zoneId: 2 })
    }
  }

  toggleModal = () => {
    this.setState(state => ({ showModal: !state.showModal }))
  }

  render() {
    const { sku, parcelId, refresh, uuid, disabled } = this.props
    const { showModal, zoneId, warehouseId } = this.state
    return (
      <>
        <Button
          onClick={this.toggleModal}
          disabled={disabled}
          id="parcels_unpack-item_open-modal"
        >
          Rozpakuj
        </Button>
        <Modal
          title={`Wypakuj produkt ${sku}`}
          visible={showModal}
          onCancel={this.toggleModal}
          footer={[]}
        >
          <Spacing mb="2rem">
            <Row gutter={16}>
              <Col sm={24}>
                <Spacing mb="1rem">
                  <Typography.Text>
                    Gdzie i ile chcesz wypakować?
                  </Typography.Text>
                </Spacing>
              </Col>
              <Col md={6} sm={12}>
                <SLabel>Magazyn</SLabel>
                <AsyncSelect
                  value={warehouseId}
                  id="parcels_unpack-item_warehouseId"
                  url="/warehouses/"
                  fullList
                  responseNormalizer={item => ({
                    text: item.name,
                    value: item.id,
                  })}
                  onChange={this.onChange('warehouseId')}
                />
              </Col>
              <Col md={6} sm={12}>
                <SLabel>Strefa</SLabel>
                <AsyncSelect
                  value={zoneId}
                  id="parcels_unpack-item_zoneId"
                  url={`/warehouses/${warehouseId}/zones`}
                  fullList
                  disabled={!warehouseId}
                  responseNormalizer={item => ({
                    text: item.name,
                    value: item.id,
                  })}
                  onChange={this.onChange('zoneId')}
                />
              </Col>
              <Col md={6} sm={12}>
                <SLabel>_</SLabel>
                <RequestButton
                  disabled={!zoneId}
                  onSuccess={refresh}
                  id="parcels_unpack-item_submit"
                  url={`/parcels/${parcelId}/items/${uuid}`}
                  method="delete"
                  content="Rozpakuj"
                  payload={{
                    zoneId,
                  }}
                />
              </Col>
            </Row>
          </Spacing>
        </Modal>
      </>
    )
  }
}
