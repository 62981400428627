import React from 'react'
import { Paper, Right } from 'components'
import { Typography } from 'antd'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { Spacing } from 'components/Spacing/Spacing'

export const CompleteOrder = ({ sale, refresh }) => (
  <Paper title="Oznacz jako zrealizowane">
    <Spacing mb="1rem">
      <Typography.Text type="warning">
        Oznacz zmaówienie jako zrealizowane aby wyświetlić status "Zrealizowane"
        w panelu klienta. Wyknonanie tej akcji spowoduje wysłanie maila
        klientowi z podziękowaniem za zamówienie.
      </Typography.Text>
    </Spacing>
    <Right>
      <RequestButton
        onSuccess={refresh}
        url={`/orders/${sale.id}/mark-as-completed`}
        title="Czy na pewno wszystkie paczki w tym zamówieniu są dostarczone?"
        method="post"
        type="danger"
        content="Zamówienie jest zrealizowane"
      />
    </Right>
  </Paper>
)
