import React from 'react'
import { AsyncSelect, Spacing, SLabel, RequestButton } from 'components'
import { Row, Col, InputNumber, Input } from 'antd'

export class AddItemForm extends React.Component {
  state = {
    sku: '',
    supplierCode: '',
    quantity: 1,
    brutto: 0,
  }

  handleSkuChange = (sku, el) => {
    this.setState({ sku, supplierCode: el.props.code })
  }

  onChange = field => value => {
    this.setState({ [field]: value })
  }

  reset = () => {
    this.setState({
      sku: '',
      supplierCode: '',
      quantity: 1,
      brutto: 0,
    })
  }

  render() {
    const { sku, quantity, supplierCode, brutto } = this.state
    return (
      <Row gutter={24}>
        <Col md={6}>
          <Spacing ml="0.3rem">
            <SLabel>SKU</SLabel>
          </Spacing>
          <AsyncSelect
            value={sku}
            url="/products"
            queryParam="sku"
            onChange={this.handleSkuChange}
            placeholder="SKU"
            responseNormalizer={item => ({
              text: item.sku,
              value: item.sku,
            })}
          />
        </Col>
        <Col md={6}>
          <Spacing ml="0.3rem">
            <SLabel>Kod dostawcy</SLabel>
          </Spacing>
          <Input
            placeholder="Kod dostawcy"
            value={supplierCode}
            onChange={e => this.onChange('supplierCode')(e.target.value)}
          />
        </Col>
        <Col md={4}>
          <Spacing ml="0.3rem">
            <SLabel>Cena brutto</SLabel>
          </Spacing>
          <InputNumber
            style={{ width: '100%' }}
            placeholder="PLN"
            min={0}
            value={brutto}
            onChange={this.onChange('brutto')}
            formatter={value => value.replace(/\B(?=(\d{2}$))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Col>
        <Col md={4}>
          <Spacing ml="0.3rem">
            <SLabel>Ilość</SLabel>
          </Spacing>
          <InputNumber
            min={1}
            placeholder="Ilość"
            value={quantity}
            onChange={this.onChange('quantity')}
          />
        </Col>
        <Col md={4}>
          <SLabel>&nbsp;</SLabel>
          <RequestButton
            content="Dodaj"
            method="post"
            unprotected
            disabled={!sku || !supplierCode || !brutto}
            v2
            payload={{
              sku,
              supplierCode,
              quantity,
              brutto,
              vatRate: 23,
            }}
            url={`/purchase-orders/${this.props.purchaseOrderId}/items`}
            type="default"
            onSuccessMessage="Pomyślnie dodano"
            onSuccess={() => {
              this.props.refresh()
            }}
          />
        </Col>
      </Row>
    )
  }
}
