import React from 'react'
import { Paper } from 'components'
import { RequestButton } from 'components/RequestButton/RequestButton'

export const MarkAsDelivered = ({ parcel, refresh, disabled }) => (
  <Paper title="Oznacz jako dostarczoną">
    <RequestButton
      onSuccess={refresh}
      url={`/parcels/${parcel.id}/mark-as-delivered`}
      method="post"
      type="danger"
      content="Potwierdzam że paczka jest dostarczona"
      disabled={disabled}
      title="Czy na pewno chcesz oznaczyć to paczkę jako dostarczoną?"
    />
  </Paper>
)
