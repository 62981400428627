import React from 'react'
import { SLinkButton } from './SLinkButton'

export const LinkButton = ({ onClick, children, disabled, mr, ml, type }) => (
  <SLinkButton
    onClick={onClick}
    disabled={disabled}
    mr={mr}
    ml={ml}
    type={type}
  >
    {children}
  </SLinkButton>
)
