import React from 'react'
import { Row, Col, Input, Select } from 'antd'
import { SLabel } from 'components/SLabel/SLabel'
import { Spacing } from 'components/Spacing/Spacing'
import { countryCodes } from 'constants/countryCodes'

export const AddressForm = ({ id, disabled, address, onChange }) => (
  <Row gutter={12}>
    <Col md={24}>
      <Spacing ml="0.3rem" mb="0.5rem">
        <SLabel>Imię i nazwisko / nazwa firmy</SLabel>
        <Input
          disabled={disabled}
          placeholder="Imię i nazwisko lub nazwa firmy"
          value={address.name}
          id={`${id}_name`}
          onChange={onChange('name')}
        />
      </Spacing>
    </Col>
    <Col md={24}>
      <Spacing ml="0.3rem" mb="0.5rem">
        <SLabel>NIP (org.nr)</SLabel>
        <Input
          disabled={disabled}
          placeholder="NIP"
          value={address.taxId}
          id={`${id}_taxId`}
          onChange={onChange('taxId')}
        />
      </Spacing>
    </Col>
    <Col md={24}>
      <Spacing ml="0.3rem" mb="0.5rem">
        <SLabel>Telefon</SLabel>
        <Input
          disabled={disabled}
          placeholder="Telefon"
          value={address.telephone}
          id={`${id}telephone`}
          onChange={onChange('telephone')}
        />
      </Spacing>
    </Col>
    <Col md={24}>
      <Spacing ml="0.3rem" mb="0.5rem">
        <SLabel>Adres 1</SLabel>
        <Input
          disabled={disabled}
          placeholder="Adres 1"
          value={address.addressLine1}
          id={`${id}_addressLine1`}
          onChange={onChange('addressLine1')}
        />
      </Spacing>
    </Col>
    <Col md={24}>
      <Spacing ml="0.3rem" mb="0.5rem">
        <SLabel>Adres 2</SLabel>
        <Input
          disabled={disabled}
          placeholder="Adres 2"
          value={address.addressLine2}
          id={`${id}_addressLine2`}
          onChange={onChange('addressLine2')}
        />
      </Spacing>
    </Col>
    <Col md={24}>
      <Spacing ml="0.3rem" mb="0.5rem">
        <SLabel>Miasto</SLabel>
        <Input
          disabled={disabled}
          placeholder="Miasto"
          value={address.city}
          id={`${id}_city`}
          onChange={onChange('city')}
        />
      </Spacing>
    </Col>
    <Col md={24}>
      <Spacing ml="0.3rem" mb="0.5rem">
        <SLabel>Kod pocztowy</SLabel>
        <Input
          disabled={disabled}
          placeholder="Kod pocztowy"
          value={address.postalCode}
          id={`${id}_postalCode`}
          onChange={onChange('postalCode')}
        />
      </Spacing>
    </Col>
    <Col md={24}>
      <Spacing ml="0.3rem" mb="0.5rem">
        <SLabel>Kraj</SLabel>
        <Select
          disabled={disabled}
          value={address.countryCode}
          id={`${id}_countryCode`}
          onChange={onChange('countryCode')}
        >
          {countryCodes.map(code => (
            <Select.Option value={code} key={code}>
              {code}
            </Select.Option>
          ))}
        </Select>
      </Spacing>
    </Col>
  </Row>
)
