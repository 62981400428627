import React from 'react'
import { FormProvider } from 'components/providers/FormProvider/FormProvider'
import { Input, Button, Typography } from 'antd'
import { Form } from '@ant-design/compatible'
import { Spacing } from 'components'

export const ProductImagesUrlUploaderUnwrapped = ({ form, refresh }) => (
  <Spacing mb="1.6rem">
    <Typography.Text>Pobierz obrazek z URL</Typography.Text>
    <FormProvider
      url="/images/url-import"
      form={form}
      onSuccessMessage="Pomyślnie zaimportowano obrazek!"
      onSuccess={() => {
        form.resetFields()
        refresh()
      }}
      render={({ handleSubmit, updating }) => (
        <Form layout="inline" onSubmit={handleSubmit}>
          <Input.Group compact>
            <Form.Item>
              {form.getFieldDecorator('url', {
                rules: [{ required: true, message: 'Podaj url obrazka' }],
              })(<Input style={{ width: 480 }} placeholder="URL" />)}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={updating}>
                Pobierz
              </Button>
            </Form.Item>
          </Input.Group>
        </Form>
      )}
    />
  </Spacing>
)

export const ProductImagesUrlUploader = Form.create({
  name: 'import-images-form',
})(ProductImagesUrlUploaderUnwrapped)
