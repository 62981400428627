import React, { useState } from 'react'
import { request } from 'api/request'
import { Button, InputNumber, Modal } from 'antd'

export const RefundPayment = ({
  saleId,
  paymentId,
  value,
  capturedAmount,
  refundedAmount,
  refresh,
}) => {
  const amountToRefund = capturedAmount - refundedAmount
  const [amount, onChange] = useState(amountToRefund)
  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    Modal.confirm({
      title: 'Czy na pewno chcesz to zrobić?',
      onOk: async () => {
        setLoading(true)
        try {
          await request({
            method: 'post',
            url: `/payments/${saleId}/${paymentId}/vipps/refund`,
            data: {
              data: {
                amount,
              },
            },
          })
        } catch {}
        setLoading(false)
        refresh()
      },
      onCancel() {},
    })
  }

  return (
    <div>
      <InputNumber
        value={amount}
        onChange={onChange}
        min={1}
        max={amountToRefund}
        formatter={value => value.toString().replace(/\B(?=(\d{2}$))/g, ',')}
        parser={value => value.toString().replace(/\$\s?|(,*)/g, '')}
        step={100}
        disabled={amountToRefund === 0}
      />{' '}
      <Button
        loading={loading}
        type="danger"
        onClick={handleSubmit}
        disabled={amountToRefund === 0}
      >
        Zwróć
      </Button>
    </div>
  )
}
