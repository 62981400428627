import { StatusHelpers } from 'utils/status'

export const CREATED = 'CREATED'
export const PACKED = 'PACKED'
export const PARTIALLY_PACKED = 'PARTIALLY_PACKED'
export const LADED = 'LADED'
export const SENT = 'SENT'
export const DELIVERED = 'DELIVERED'

const sequence = [CREATED, PARTIALLY_PACKED, PACKED, LADED, SENT, DELIVERED]

export const parcelStatus = parcel =>
  new StatusHelpers(parcel ? parcel.status : null, sequence)
