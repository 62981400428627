import React, { useState } from 'react'
import { InputNumber } from 'antd'
import { ThunderboltOutlined } from '@ant-design/icons'
import { RequestButton } from 'components'

export const StockTransaction = ({ sku, zoneId, refresh }) => {
  const [value, onChange] = useState(0)
  return (
    <div>
      <InputNumber value={value} onChange={onChange} />
      <RequestButton
        onSuccess={() => {
          onChange(0)
          refresh()
        }}
        url="/stock"
        method="post"
        type="primary"
        content={<ThunderboltOutlined />}
        disabled={!value}
        payload={{
          items: [
            {
              sku,
              zoneId,
              quantity: value,
            },
          ],
        }}
        title={`Czy na pewno chcesz ${
          value > 0 ? 'dodać' : 'zdjąć'
        } ${value} sztuk?`}
      />
    </div>
  )
}
