import React from 'react'
import { pathOr } from 'ramda'
import { Paper, Table } from 'components'
import { ResourceProvider } from 'components/providers/ResourceProvider/ResourceProvider'
import { RequestButton } from 'components/RequestButton/RequestButton'
import { AddFitmentForm } from './AddFitmentForm/AddFitmentForm'

const mapTree = tree => {
  switch (tree) {
    case 'ws':
      return 'Wheel-Size'
    case 'td':
      return 'TecDoc'
    default:
      return '*'
  }
}

const normalizeFitments = fitments =>
  fitments.map(fitment => ({
    id: fitment.id,
    tree: mapTree(fitment.tree),
    classification: pathOr('*', ['classification'], fitment),
    brand: pathOr(
      pathOr('*', ['brandId'], fitment),
      ['brandLabel', 'pl'],
      fitment
    ).toUpperCase(),
    model: pathOr(
      pathOr('*', ['modelId'], fitment),
      ['modelLabel', 'pl'],
      fitment
    ).toUpperCase(),
    type: pathOr(
      pathOr('*', ['typeId'], fitment),
      ['typeLabel', 'pl'],
      fitment
    ).toUpperCase(),
    yearFrom: pathOr('*', ['yearFrom'], fitment),
    yearTo: pathOr('*', ['yearTo'], fitment),
    bodyType: pathOr('*', ['bodyType'], fitment),
  }))

export const Fitments = ({ sku }) => (
  <ResourceProvider
    url={`products/fitments?sku=${encodeURIComponent(sku)}`}
    responseNormalizer={normalizeFitments}
    pagination
    paginationParams={{
      current: 1,
      pageSize: 999999,
      total: null,
    }}
    render={({ data, loading, refresh }) =>
      !loading && (
        <Paper title="Dopasowanie do pojazdów">
          {data.length > 0 && (
            <Table>
              <Table.Body>
                <Table.Tr>
                  <Table.Th>Drzewo</Table.Th>
                  <Table.Th>Klasa</Table.Th>
                  <Table.Th>Marka</Table.Th>
                  <Table.Th>Model</Table.Th>
                  <Table.Th>Typ</Table.Th>
                  <Table.Th>Rok od</Table.Th>
                  <Table.Th>Rok do</Table.Th>
                  <Table.Th>Typ nadwozia</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
                {data.map((fitment, index) => (
                  <Table.Tr key={index}>
                    <Table.Td>{fitment.tree}</Table.Td>
                    <Table.Td>{fitment.classification.toUpperCase()}</Table.Td>
                    <Table.Td>{fitment.brand}</Table.Td>
                    <Table.Td>{fitment.model}</Table.Td>
                    <Table.Td>{fitment.type}</Table.Td>
                    <Table.Td>
                      {fitment.yearFrom.toString().slice(0, 4)}
                    </Table.Td>
                    <Table.Td>{fitment.yearTo.toString().slice(0, 4)}</Table.Td>
                    <Table.Td>{fitment.bodyType}</Table.Td>
                    <Table.Td>
                      <RequestButton
                        method="delete"
                        url={`products/fitments/${fitment.id}`}
                        content="Usuń"
                        onSuccess={refresh}
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Body>
            </Table>
          )}

          <AddFitmentForm sku={sku} refresh={refresh} />
        </Paper>
      )
    }
  />
)
